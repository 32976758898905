// Set to true to get all events logged to console.
window.DEBUG = false

var appURL = '/'
var actURL = '/account.html'
var webURL = '/'

var DEVICE_LIST = $('#table_bulk')

// events
var ACCEPT_INVITATION_EVENT = 'accept_invitation'
var TRANSFER_DEVICE_EVENT = 'transfer_device'

var weaved = new WeavedAPI(weavedCallback)
var rState = new Remot3itState()

var autoRefreshDeviceListEnabled = false

var requiredAcks = 0

var tasksCreated = 0
var tasksCompleted = 0
var tasksFailed = 0
var taskJobID = ''
var jobDone = true

var customerData = {}

var bulkOperationCount = 0
var bulkOperationDone = 0
var bulkPercentDone = 0

var productFeatures = false
var deviceListDisabled = false

var bulkShareAction = ''

// FIXME: Initialize with an empty response so legacy code
// doesn't barf when trying to JSON.parse
var productPlatformTypes = '{ "types": [] }'

var deleteRows = 0
var deletedRows = 0

var savedColModel = ''

var sharedEmailSettings = ''

var devicesBeingUpdated = false

var savedFileList = ''

var scriptHeaders = ''

var selectedRows
// var loginResponse = ''
var invitationGUID = ''
var invitationType = ''
var invitationFrom = ''
var invitationDevices = ''
var contactsData = {}
var listJobs = []
var currentJob = {}
var scriptingStates = ['running', 'done', 'failed', 'new']
var productsData = []

function renderLegacyContactsPage() {
  $('#customers-table').remove()

  $('<table>')
    .attr({
      id: 'customers-table',
      class: 'table table-hover footable',
      'data-sorting': 'true',
      'data-expand-first': 'true',
      'data-filter-dropdown-title': 'Filter in:',
      'data-filter-placeholder': 'Filter',
      'data-filtering': 'true',
      'data-paging': 'true',
      'data-paging-size': '8',
      'data-filter-connectors': 'false',
    })
    .appendTo('[id="customers-table-content"]')

  $('#customers-table').append(
    '<thead>' +
      '<tr>' +
      "<th data-type='text'>Group Name</th>" +
      "<th data-type='text'>Last Name</th>" +
      "<th data-type='text'>First Name</th>" +
      "<th data-type='text'>Email</th>" +
      "<th data-type='text'>Account</th>" +
      "<th data-breakpoints='xs sm md' data-sorted='true' data-direction='DESC' data-sort-initial='true'>Created</th>" +
      "<th data-breakpoints='xs sm md'>Updated</th>" +
      "<th data-type='html' class='right'>Actions</th>" +
      '</tr></thead><tbody></tbody>'
  )

  var obj = contactsData

  //alert("developer customers table:"+message);

  if (!obj.customers || obj.customers.length < 1) {
    $('#customers-table').append('<tr><th>No Contacts</th></tr>')
    $('#customers-table').footable()

    stopSpinner()
    return
  }

  //alert("adding rows:"+obj.customers.length);

  customerData = obj

  for (i = 0; i < obj.customers.length; i++) {
    created_date = new Date(Date.parse(obj.customers[i].created))
    updated_date = new Date(Date.parse(obj.customers[i].updated))

    actions = '<select name="' + obj.customers[i].id + '" id="' + obj.customers[i].id + '">'
    actions = actions + '<option value="none">Select action ...</option>'
    actions =
      actions +
      '<option value="delete" onclick="showDeleteContactModal(\'' +
      obj.customers[i].id +
      "','" +
      obj.customers[i].email +
      '\');" class="btn btn-link px-xs py-none danger">Delete Customer</option>'

    delete_icon =
      '<button onclick="showDeleteContactModal(\'' +
      obj.customers[i].id +
      "','" +
      obj.customers[i].email +
      '\');" class="btn btn-link px-xs py-none danger"><i class="fal fa-trash-alt fa-fw"  title="Delete Customer" align="center"></i></button>'

    account_icon = ''
    if (obj.customers[i].account_created.toLowerCase() != 'yes') {
      account_icon =
        '<button onclick="showCreateAccountModal(\'' +
        obj.customers[i].id +
        "','" +
        obj.customers[i].email +
        '\');" class="btn btn-success btn-xs mr-md" title="Invite user to remote.it"><i class="fal fa-user-plus fa-fw mr-xs" align="center"></i> Invite</button>'

      actions =
        actions +
        '<option value="account" onclick="showCreateAccountModal(\'' +
        obj.customers[i].id +
        "','" +
        obj.customers[i].email +
        '\');"  ">Create Account</option>'
    }

    actions = actions + '</select>'

    $('#customers-table').append(
      '<tr><td class="notranslate">' +
        checkEncodedChar(obj.customers[i].company_name) +
        '</td><td class="notranslate">' +
        checkEncodedChar(obj.customers[i].last_name) +
        '</td><td class="notranslate">' +
        checkEncodedChar(obj.customers[i].first_name) +
        '</td><td class="notranslate">' +
        obj.customers[i].email +
        '</td><td>' +
        obj.customers[i].account_created +
        '</td>' +
        '<td title="' +
        created_date.toUTCString() +
        '" data-sort-value=' +
        created_date.toISOString() +
        '">' +
        created_date.toLocaleString() +
        '</td>' +
        '<td title="' +
        updated_date.toUTCString() +
        '" data-sort-value=' +
        updated_date.toISOString() +
        '">' +
        updated_date.toLocaleString() +
        '</td>' +
        '<td class="right txt-md">' +
        account_icon +
        delete_icon +
        '</td></tr>'
    )

    //$('#customers-table').append('<tr><td>'+obj.customers[i].company_name+'</td><td>'+obj.customers[i].last_name+'</td><td>'+obj.customers[i].first_name+'</td><td>'+obj.customers[i].email+'</td><td>'+obj.customers[i].account_created+'</td><td>'+created_date.toISOString()+'</td><td>'+updated_date.toISOString()+'</td><td>' + actions + '</td></tr>');
  }

  $('#customers-table').footable()

  stopSpinner()
}

function setCookie(cname, cvalue, exMins) {
  var d = new Date()
  d.setTime(d.getTime() + exMins * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=/'
}

function getCookie(cname) {
  var name = cname + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1)
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length)
  }
  return ''
}

function processUserPreferences(obj) {
  advFeaturesSet = false
  prodFeaturesSet = false

  if (obj.status === 'false') {
    console.error('Could not process user preferences', obj)
    return
  }

  for (i = 0; i < obj.preferences.length; i++) {
    if (obj.preferences[i].key == 'colmodel') {
      savedColModel = obj.preferences[i].data
    } else if (obj.preferences[i].key == 'bulkShareEmails') {
      sharedEmailSettings = obj.preferences[i].data
      arr = sharedEmailSettings.split(',')

      var list = $('#bulkshareEmails')
      $.each(arr, function(item, text) {
        list.append(new Option(text, text))
      })
    }
  }
}

function showNoSearchResultsMessage() {
  hideSearchMessages()
  $('#no-search-results-message').show()
}

function showSearchMessages() {
  hideSearchMessages()
  $('#no-devices-message').show()
}

function showGenericSearchError(message) {
  hideSearchMessages()
  var error = $('#device-search-generic-error')
  error.find('.alert-body').html(message)
  error.show()
}

function showTooManyResultsMessage() {
  hideSearchMessages()
  $('#too-many-results-message').show()
}

function hideSearchMessages() {
  $('#no-search-results-message').hide()
  $('#no-devices-message').hide()
  $('#too-many-results-message').hide()
  $('#device-search-generic-error').hide()
  stopSpinner()
}

function weavedCallback(e) {
  event = e.event
  status = e.status
  message = e.message
  tag = e.tag

  // Log all events on localhost to aid in debugging
  if (window.DEBUG || window.location.host.includes('localhost')) {
    try {
      console.log('EVENT:', {
        event: e.event,
        message: JSON.parse(e.message),
        tag: e.tag,
        status: e.status,
      })
    } catch (error) {}
  }

  if (event == weaved.DEVICE_NAME_EVENT) {
    stopSpinner()
  } else if (event == weaved.CONTENT_EVENT) {
    $('#fileTable').remove()

    $('<table>')
      .attr({
        id: 'fileTable',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filter-dropdown-title': 'Filter in:',
        'data-filter-placeholder': 'Filter',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '8',
        'data-filter-connectors': 'false',
      })
      .appendTo('[id="storage_content_div"]')

    $('#fileTable').append(
      '<thead>' +
        '<tr>' +
        '<th data-breakpoints="xs sm md" data-type="html">Name</th>' +
        '<th data-breakpoints="xs sm md" data-type="html">Size</th>' +
        '<th data-breakpoints="xs sm md" data-type="date" data-format-string="YYYY-MM-DD HH:mm:ss" data-sorted="true" data-direction="DESC" data-sort-initial="true">Date Uploaded</th>' +
        '<th data-type="html">Actions</th>' +
        '</tr></thead><tbody></tbody>'
    )

    //$('#fileTable tbody').empty();
    //$('#fileTable tr').remove();

    list = 'fileSelectionList'
    if (tag == 'script') {
      list = 'scriptSelectionList'
    }

    if (status == 'true') {
      //alert("Content:"+list+":"+tag+":"+message);

      if (list == 'fileSelectionList' || list == 'multifileSelectionList') {
        savedFileList = message
      }

      obj = JSON.parse(message)

      if (contentMode == 'display') {
        for (i = 0; i < obj.items.length; i++) {
          new_date_string = '-'

          try {
            //alert(obj.items[i].name + " => " + obj.items[i].last_modified);

            new_date = new Date(Date.parse(obj.items[i].last_modified))
            new_date_string = new_date.toISOString()

            //alert(obj.items[i].name + " => " + obj.items[i].last_modified+" = "+new_date.toISOString());
          } catch (err) {}

          $('#fileTable > tbody:last-child').append(
            '<tr><td><a href="' +
              obj.items[i].url +
              '" >' +
              sanitizeAndDecode(obj.items[i].name) +
              '</a></td><td>' +
              humanFileSize(obj.items[i].size) +
              '</td><td>' +
              new_date_string +
              '</td><td><button class="link danger" onclick="confirmDeleteContent(\'' +
              obj.items[i].key +
              "','" +
              obj.items[i].name +
              '\');"><i class="fal fa-trash-alt"  data-toggle="modal" data-placement="top" title="Delete" align="center"></i></button></td></tr>'
          )
        }

        $('#fileTable').footable()
      } else {
        $('option', '#' + list).remove()

        if (obj.items) {
          for (i = 0; i < obj.items.length; i++) {
            $('#' + list).append(
              $('<option></option>')
                .attr('value', obj.items[i].url)
                .text(obj.items[i].name)
            )
          }
        }
      }
    } else {
      if (contentMode == 'display') {
        $('#fileTable > tbody:last-child').append('<tr><th>Content is not available</th></tr>')
      } else {
        $('#' + list).append(
          $('<option></option>')
            .attr('value', '')
            .text('No files available')
        )
      }
    }
  } else if (event == weaved.BULK_JOB_LIST_EVENT) {
    //$('#jobs-table tbody').empty();
    //$("#jobs-table tbody tr").remove();

    $('#jobs-table').remove()

    $('<table>')
      .attr({
        id: 'jobs-table',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filter-dropdown-title': 'Filter in:',
        'data-filter-placeholder': 'Filter',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '20',
        'data-filter-connectors': 'false',
      })
      .appendTo('[id="jobs-table-content"]')

    $('#jobs-table').append(
      '<thead>' +
        '<tr>' +
        "<th data-type='text'>Script</th>" +
        "<th data-type='html' data-sort-use='text'>Status</th>" +
        "<th data-breakpoints='xs sm md' data-sorted='true' data-direction='DESC' data-sort-initial='true'>Started</th>" +
        "<th data-breakpoints='xs sm md'>Updated</th>" +
        "<th data-type='html' data-sortable='false' class='center'>Actions</th>" +
        "<th data-breakpoints='xs sm md' data-type='number' class='center'>Devices</th>" +
        "<th data-breakpoints='xs sm md' data-type='number' class='center'>Errors</th>" +
        '</tr></thead><tbody></tbody>'
    )

    if (status == 'true') {
      //alert("jobs table:"+message);

      obj = JSON.parse(message)

      if (obj.jobs.length < 1) {
        //alert("jobs:"+obj.jobs.length);
        $('#job-table').append('<tr><th>No Job history</th></tr>')
        $('#jobs-table').footable()

        stopSpinner()

        return
      }

      listJobs = obj.jobs

      for (i = 0; i < obj.jobs.length; i++) {
        link = ''

        new_requested_date = new Date(Date.parse(obj.jobs[i].requested))
        new_updated_date = new Date(Date.parse(obj.jobs[i].updated))

        if (scriptingStates.includes(obj.jobs[i].state)) {
          $('#jobs-table').append(
            '<tr><td>' +
              obj.jobs[i].script +
              '</td><td id="job_' +
              obj.jobs[i].id +
              '">' +
              renderScriptingStatus(obj.jobs[i].id, obj.jobs[i].state, 'primary') +
              '</td>' +
              '<td title="' +
              new_requested_date.toUTCString() +
              '" data-sort-value=' +
              new_requested_date.toISOString() +
              '">' +
              new_requested_date.toLocaleString() +
              '</td>' +
              '<td title="' +
              new_updated_date.toUTCString() +
              '" data-sort-value=' +
              new_updated_date.toISOString() +
              '">' +
              new_updated_date.toLocaleString() +
              '</td>' +
              '<td class="center" id="job_action_' +
              obj.jobs[i].id +
              '">' +
              renderScriptingAction(obj.jobs[i].id, obj.jobs[i].state) +
              '</td><td class="center">' +
              obj.jobs[i].service_count +
              '</td><td class="center">' +
              obj.jobs[i].error_count +
              '</td></tr>'
          )
        } else {
          $('#jobs-table').append(
            '<tr><td>' +
              obj.jobs[i].script +
              '</td><td>' +
              '<span class="fal fa-spinner-third fa-spin fa-fw primary"></span> <span class="gray ml-sm">' +
              obj.jobs[i].state +
              '</span>' +
              '</td>' +
              '<td title="' +
              new_requested_date.toUTCString() +
              '" data-sort-value=' +
              new_requested_date.toISOString() +
              '">' +
              new_requested_date.toLocaleString() +
              '</td>' +
              '<td title="' +
              new_updated_date.toUTCString() +
              '" data-sort-value=' +
              new_updated_date.toISOString() +
              '">' +
              new_updated_date.toLocaleString() +
              '</td>' +
              '<td></td><td class="center">' +
              obj.jobs[i].service_count +
              '</td><td class="center">' +
              obj.jobs[i].error_count +
              '</td></tr>'
          )
        }
      }

      $('#jobs-table').footable()
    } else {
      $('#job-table').append('<tr><th>No Job history</th></tr>')
      $('#jobs-table').footable()
    }

    stopSpinner()
  } else if (event == weaved.BULK_JOB_TASK_LIST_EVENT) {
    //$('#job-tasks-table tbody').empty();
    //$("#job-tasks-table tbody tr").remove();

    $('#job-task-count').html('No tasks listed ')

    $('#job-tasks-table').remove()

    $('<table>')
      .attr({
        id: 'job-tasks-table',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filter-dropdown-title': 'Filter in:',
        'data-filter-placeholder': 'Filter',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '8',
        'data-filter-connectors': 'false',
      })
      .appendTo('[id="job-tasks-content"]')

    $('#job-tasks-table').append(
      '<thead>' +
        '<tr>' +
        "<th data-type='text'>Device Name</th>" +
        "<th data-breakpoints='xs sm md' data-type='html'>Hardware ID</th>" +
        "<th data-breakpoints='xs sm md' data-type='html'>Status</th>" +
        "<th data-breakpoints='xs sm md' data-type='html'>Description</th>" +
        "<th data-breakpoints='xs sm md' data-type='date' data-format-string='YYYY-MM-DD HH:mm:ss' data-sorted='true' data-direction='DESC' data-sort-initial='true'>Created</th>" +
        "<th data-breakpoints='xs sm md' data-type='date' data-format-string='YYYY-MM-DD HH:mm:ss'>Updated</th>" +
        '</tr></thead><tbody></tbody>'
    )

    if (status == 'true') {
      //alert(message);

      jtobj = JSON.parse(message)

      if (jtobj.jobtasks.length < 1) {
        $('#job-tasks-table').append('<tr><th>No Job Task history</th></tr>')
        $('#job-tasks-table').footable()
        stopSpinner()

        return
      }

      $('#job-task-count').html(jtobj.jobtasks.length + ' tasks listed ')

      for (i = 0; i < jtobj.jobtasks.length; i++) {
        task = jtobj.jobtasks[i]

        //alert(JSON.stringify(task));

        new_created_date = new Date(Date.parse(task.created))
        new_updated_date = new Date(Date.parse(task.updated))

        name = task.uid
        device = rState.getDevice(task.uid)
        hwid = 'unknown'
        if (device) {
          //alert(JSON.stringify(device));
          name = device.ServiceName
          hwid = device.HWID
        }

        $('#job-tasks-table').append(
          '<tr><td>' +
            name +
            '</td><td>' +
            hwid +
            '</td><td>' +
            task.state +
            '</td><td>' +
            task.description +
            '</td><td>' +
            new_created_date.toISOString() +
            '</td><td>' +
            new_updated_date.toISOString() +
            '</td></tr>'
        )
      }

      $('#job-tasks-table').footable()
    } else {
      $('#job-tasks-table').append('<tr><th>No Job Task history</th></tr>')

      $('#job-tasks-table').footable()
    }

    stopSpinner()
  } else if (event == weaved.JOB_CANCEL_EVENT || event == weaved.JOB_DELETE_EVENT) {
    stopSpinner()

    if (status == 'true') {
      showJobs()
      $('#Job-Detail-Modal').modal('hide')
    } else {
      $('#job-detail-status').html('The job action could not be completed')
    }
  } else if (event == weaved.BULK_ADD_PROJECT_EVENT) {
    stopSpinner()

    if (status == 'true') {
      // request project list
      weaved.getBulkProjectList(bulkRegistrationID)

      $('#Set-Project-Modal').modal('hide')
      $('#Open-Registration-Settings-Modal').modal('show')
    } else {
      alert('something went wrong')
    }
  } else if (event == weaved.PRODUCT_ADD_PROJECT_EVENT) {
    stopSpinner()

    if (status == 'true') {
      $('#Set-Project-Modal').modal('hide')

      showProductSettingsModal(productID, productName, productLock)
    } else {
      alert('something went wrong')
    }
  } else if (event == weaved.BULK_ADD_PROJECT_EVENT) {
    stopSpinner()

    if (status == 'true') {
      // request project list
      weaved.getBulkProjectList(bulkRegistrationID)

      $('#Set-Project-Modal').modal('hide')
      $('#Open-Registration-Settings-Modal').modal('show')
    } else {
      alert('something went wrong')
    }
  } else if (event == weaved.SET_BULK_SERVICE_CONFIGURATION_EVENT) {
    stopSpinner()

    $('#set-bulk-service-button').removeAttr('disabled')

    if (status == 'true') {
      $('#Set-Bulk-Service-Modal').modal('hide')

      showBulkServicesModal(bulkID, bulkName)
    } else {
      alert('something went wrong')
      //$("#job-detail-status").html("The job action could not be completed");
    }
  } else if (event == weaved.PRODUCT_DELETE_PROJECT_EVENT) {
    stopSpinner()

    //alert("delete project response");
    $('#Project-Delete-Modal').modal('hide')

    if (status == 'true') {
      // request project list
      weaved.getProductProjectList(productID)
    } else {
      alert('something went wrong')
      //$("#job-detail-status").html("The job action could not be completed");
    }
  } else if (event == weaved.BULK_REGISTRATION_OPEN_SETTINGS_EVENT) {
    stopSpinner()

    //alert("add project response");

    if (status == 'true') {
      $('#Open-Registration-Settings-Modal').modal('hide')
      weaved.getBulkRegistrations()
    } else {
      alert('something went wrong')
      //$("#job-detail-status").html("The job action could not be completed");
    }
  } else if (event == weaved.PRODUCT_SETTINGS_EVENT) {
    stopSpinner()

    //alert("add project response");

    if (status == 'true') {
      $('#Product-Settings-Modal').modal('hide')
      weaved.getProducts()
    } else {
      alert('something went wrong')
      //$("#job-detail-status").html("The job action could not be completed");
    }
  } else if (event == weaved.BULK_SHARE_EVENT) {
    //alert("ON:Bulk Share Event:"+message);

    obj = JSON.parse(message)
    //obj = message;

    //alert("ON:Bulk Share Event:"+JSON.stringify(obj.status));

    $('#bulk-share-device-button').removeAttr('disabled')

    //alert("bulk share status:"+JSON.stringify(obj));
    //alert(obj.reason);

    if (obj.status == 'true') {
      $('#Bulk-Share-Device-Modal').modal('hide')

      if (bulkSelection == 'bulkshare') {
        $('#Share-Off-Confirmation-Modal').modal('hide')
        $('#Share-Confirmation-Modal').modal('show')
        bulkSelection = ''
      }
      $('.bulk-share-fail').html('')
    } else {
      $('#share-failure-error').html('Oops, ' + obj.reason)
      $('#Bulk-Share-Device-Modal').modal('hide')
      $('#Share-Failure-Modal').modal('show')
    }
  } else if (event == weaved.DEVELOPER_DEVICE_SHARE_EVENT) {
    stopSpinner()

    if (status == 'true') {
      $('#Remove-Share-Device-Modal').modal('hide')

      if (!deviceListDisabled) {
        // TODO:
        weaved.fetchDevices()
      }

      $('#Share-Off-Confirmation-Modal').modal('show')
    } else {
      if (shareAction == 'remove') {
        $('#remove-share-progress-status').hide()
        $('#remove-share-device-button').removeAttr('disabled')
        $('#remove-share-fail').show()
        $('#remove-share-fail').html('Error:' + message)
      } else {
        $('#share-progress-status').hide()
        $('#share-device-button').removeAttr('disabled')
        $('#share-fail').show()
        $('#share-fail').html('Error:' + message)
      }
    }
  } else if (event == weaved.DEVELOPER_DEVICE_GET_SHARING_EVENT) {
    if (status == 'true') {
      obj = JSON.parse(message)

      $('#shared-device-accounts').remove()

      $('<table>')
        .attr({
          id: 'shared-device-accounts',
          class: 'table table-hover footable',
          'data-sorting': 'true',
          'data-expand-first': 'true',
          'data-filter-dropdown-title': 'Filter in:',
          'data-filter-placeholder': 'Filter',
          'data-filtering': 'true',
          'data-paging': 'true',
          'data-paging-size': '5',
          'data-filter-connectors': 'false',
        })
        .appendTo('[id="shared-users-div"]')

      $('#shared-device-accounts').append(
        '<thead>' + '<tr>' + '<th>Account</th>' + '<th>Scripting</th>' + '</tr></thead><tbody></tbody>'
      )

      if (obj.shares) {
        for (i = 0; i < obj.shares.length; i++) {
          var allowedString = 'Not Allowed'
          if (obj.shares[i].scripting) {
            allowedString = 'Allowed'
          }
          $('#shared-device-accounts').append(
            '<tr>' + '<td>' + obj.shares[i].email.trim() + '</td>' + '<td>' + allowedString + '</td>' + '</tr>'
          )
        }
      }

      $('#shared-device-accounts').footable()
    } else {
      $('#shared-device-accounts').append('<tr><th>No sharing data available</th></tr>')
      $('#shared-device-accounts').footable()
    }

    stopSpinner()
  } else if (event == weaved.BULK_PRODUCT_LIST_EVENT) {
    if (status == 'true') {
      obj = JSON.parse(message)

      list = 'bulkProductSelectionList'

      $('option', '#' + list).remove()

      $('#' + list).append(
        $('<option></option>')
          .attr('value', 'clear')
          .text('Choose a product ...')
      )

      // get subdomain data for product list
      domain = domainHandler.getSubdomainData()

      if (obj.products) {
        for (i = 0; i < obj.products.length; i++) {
          // filter the product list
          if (domain.products.trim().indexOf(obj.products[i].id.trim()) > -1) {
            $('#' + list).append(
              $('<option></option>')
                .attr('value', obj.products[i].descriptor.trim())
                .text(obj.products[i].id.trim())
            )
          }
        }
      }
    } else {
      $('#' + list).append(
        $('<option></option>')
          .attr('value', '')
          .text('No files available')
      )
    }
  } else if (event == weaved.DEVELOPER_PRODUCT_PUBLIC_LIST_EVENT) {
    if (status == 'true') {
      obj = JSON.parse(message)

      list = 'publicProductSelectionList'

      $('option', '#' + list).remove()

      $('#' + list).append(
        $('<option></option>')
          .attr('value', 'clear')
          .text('Choose a public product ...')
      )

      if (obj.products) {
        for (i = 0; i < obj.products.length; i++) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.products[i].id.trim())
              .text(obj.products[i].name.trim())
          )
        }
      }

      list = 'publicProductSelectionList2'

      $('option', '#' + list).remove()

      $('#' + list).append(
        $('<option></option>')
          .attr('value', 'clear')
          .text('Choose a public product ...')
      )

      if (obj.products) {
        for (i = 0; i < obj.products.length; i++) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.products[i].id.trim())
              .text(obj.products[i].name.trim())
          )
        }
      }
    } else {
      list = 'publicProductSelectionList'
      $('#' + list).append(
        $('<option></option>')
          .attr('value', '')
          .text('No products available')
      )

      list = 'publicProductSelectionList2'
      $('#' + list).append(
        $('<option></option>')
          .attr('value', '')
          .text('No products available')
      )
    }
  } else if (event == weaved.DEVELOPER_PRODUCT_PRIVATE_LIST_EVENT) {
    // initialize the lists
    list = 'myProductSelectionList'
    $('option', '#' + list).remove()
    $('#' + list).append(
      $('<option></option>')
        .attr('value', 'clear')
        .text('Choose a private product ...')
    )

    // there are two similar lists
    list = 'myProductSelectionList2'
    $('option', '#' + list).remove()
    $('#' + list).append(
      $('<option></option>')
        .attr('value', 'clear')
        .text('Choose a private product ...')
    )

    if (status == 'true') {
      obj = JSON.parse(message)

      list = 'myProductSelectionList'

      if (obj.products) {
        for (i = 0; i < obj.products.length; i++) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.products[i].id.trim())
              .text(obj.products[i].name.trim())
          )
        }
      }

      // there are two similar lists
      list = 'myProductSelectionList2'

      if (obj.products) {
        for (i = 0; i < obj.products.length; i++) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.products[i].id.trim())
              .text(obj.products[i].name.trim())
          )
        }
      }
    } else {
      list = 'myProductSelectionList'
      $('option', '#' + list).remove()
      $('#' + list).append(
        $('<option></option>')
          .attr('value', '')
          .text('No products available')
      )

      list = 'myProductSelectionList2'
      $('option', '#' + list).remove()
      $('#' + list).append(
        $('<option></option>')
          .attr('value', '')
          .text('No products available')
      )
    }
  } else if (event == weaved.DEVELOPER_PRODUCT_AVAILABLE_OPEN_LIST_EVENT) {
    // initialize the lists
    list = 'myProductSelectionList'
    $('option', '#' + list).remove()
    $('#' + list).append(
      $('<option></option>')
        .attr('value', 'clear')
        .text('Choose a private product ...')
    )

    // there are two similar lists
    list = 'myProductSelectionList2'
    $('option', '#' + list).remove()
    $('#' + list).append(
      $('<option></option>')
        .attr('value', 'clear')
        .text('Choose a private product ...')
    )

    if (status == 'true') {
      obj = JSON.parse(message)

      list = 'myProductSelectionList'

      if (obj.products) {
        for (i = 0; i < obj.products.length; i++) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.products[i].id.trim())
              .text(obj.products[i].name.trim())
          )
        }
      }

      // there are two similar lists
      list = 'myProductSelectionList2'

      if (obj.products) {
        for (i = 0; i < obj.products.length; i++) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.products[i].id.trim())
              .text(obj.products[i].name.trim())
          )
        }
      }
    } else {
      list = 'myProductSelectionList'
      $('option', '#' + list).remove()
      $('#' + list).append(
        $('<option></option>')
          .attr('value', '')
          .text('No products available')
      )

      list = 'myProductSelectionList2'
      $('option', '#' + list).remove()
      $('#' + list).append(
        $('<option></option>')
          .attr('value', '')
          .text('No products available')
      )
    }
  } else if (event == weaved.DEVELOPER_PRODUCT_LIST_EVENT) {
    $('#products-table').remove()

    $('<table>')
      .attr({
        id: 'products-table',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filter-dropdown-title': 'Filter in:',
        'data-filter-placeholder': 'Filter',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '8',
        'data-filter-connectors': 'false',
      })
      .appendTo('[id="products-table-content"]')

    $('#products-table').append(
      '<thead>' +
        '<tr>' +
        "<th data-type='text'>Name</th>" +
        "<th data-type='text'>Platform</th>" +
        "<th data-type='text'>Scope</th>" +
        "<th data-type='html' data-sort-use='text'>Status</th>" +
        "<th data-breakpoints='xs sm md' data-type='date' data-format-string='YYYY-MM-DD HH:mm:ss' data-sorted='true' data-direction='DESC' data-sort-initial='true'>Created</th>" +
        "<th data-breakpoints='xs sm md' data-type='date' data-format-string='YYYY-MM-DD HH:mm:ss'>Updated</th>" +
        "<th data-type='html' data-sortable='false' class='right'>Actions</th>" +
        '</tr></thead><tbody></tbody>'
    )

    if (status == 'true') {
      obj = JSON.parse(message).data

      if (!obj.products || obj.products.length < 1) {
        $('#products-table').append('<tr><th>No Products</th></tr>')
        $('#products-table').footable()

        stopSpinner()
        return
      } else {
        for (k = 0; k < obj.products.length; k++) {
          obj.products[k].name = checkEncodedChar(obj.products[k].name)
        }
      }
      productsData = obj.products
      platforms = JSON.parse(productPlatformTypes).data

      for (i = 0; i < obj.products.length; i++) {
        created_date = new Date(Date.parse(obj.products[i].created))
        updated_date = new Date(Date.parse(obj.products[i].updated))

        // find platform name for readability
        platformName = obj.products[i].platform
        for (j = 0; j < platforms.types.length; j++) {
          if (obj.products[i].platform == platforms.types[j].code) {
            platformName = platforms.types[j].name + ' (' + platforms.types[j].code + ')'
          }
        }

        settings_icon =
          '<button onclick="fetchProductData(\'' +
          obj.products[i].id +
          "','" +
          'settings' +
          '\');" class="btn btn-link px-xs py-none primary"><i class="fal fa-cog fa-fw"  title="Change Product Settings" align="center"></i></button>'

        delete_icon =
          '<button onclick="fetchProductData(\'' +
          obj.products[i].id +
          "','" +
          'delete' +
          '\');"  class="btn btn-link px-xs py-none danger"><i class="fal fa-trash-alt fa-fw"  title="Delete Product" align="center"></i></button>'

        if (obj.products[i].status.toLowerCase() == 'new') {
          $('#products-table').append(
            '<tr><td class="notranslate">' +
              obj.products[i].name +
              '</td><td class="notranslate">' +
              platformName +
              '</td><td>' +
              obj.products[i].scope +
              '</td><td>' +
              obj.products[i].status +
              '</td>' +
              '<td title="' +
              created_date.toUTCString() +
              '" data-sort-value=' +
              created_date.toISOString() +
              '">' +
              created_date.toLocaleString() +
              '</td>' +
              '<td title="' +
              updated_date.toUTCString() +
              '" data-sort-value=' +
              updated_date.toISOString() +
              '">' +
              updated_date.toLocaleString() +
              '</td>' +
              '<td class="right txt-md">' +
              settings_icon +
              delete_icon +
              '</td></tr>'
          )
        } else if (obj.products[i].status.toLowerCase() == 'locked') {
          download_icon =
            '<button onclick="fetchProductData(\'' +
            obj.products[i].id +
            "','" +
            'provision' +
            '\');"  class="btn btn-link primary px-xs py-none"><i class="fal fa-download fa-fw"  data-toggle="modal" data-placement="top" title="Download Provisioning Files" align="center"></i></button>'

          info_icon =
            '<button onclick="fetchProductData(\'' +
            obj.products[i].id +
            "','" +
            'product-usage' +
            '\');"  class="btn btn-link primary px-xs py-none "><i class="fal fa-info-circle fa-fw"  title="Show Product Usage" align="center"></i></button>'

          $('#products-table').append(
            '<tr><td>' +
              obj.products[i].name +
              '</td><td>' +
              platformName +
              '</td><td>' +
              obj.products[i].scope +
              '</td><td>' +
              obj.products[i].status +
              '</td>' +
              '<td title="' +
              created_date.toUTCString() +
              '" data-sort-value=' +
              created_date.toISOString() +
              '">' +
              created_date.toLocaleString() +
              '</td>' +
              '<td title="' +
              updated_date.toUTCString() +
              '" data-sort-value=' +
              updated_date.toISOString() +
              '">' +
              updated_date.toLocaleString() +
              '</td>' +
              '<td class="right txt-md">' +
              download_icon +
              info_icon +
              settings_icon +
              delete_icon +
              '</td></tr>'
          )
        } else {
          $('#products-table').append(
            '<tr><td>' +
              obj.products[i].name +
              '</td><td>' +
              platformName +
              '</td><td>' +
              obj.products[i].scope +
              '</td><td>' +
              obj.products[i].status +
              '</td>' +
              '<td title="' +
              created_date.toUTCString() +
              '" data-sort-value=' +
              created_date.toISOString() +
              '">' +
              created_date.toLocaleString() +
              '</td>' +
              '<td title="' +
              updated_date.toUTCString() +
              '" data-sort-value=' +
              updated_date.toISOString() +
              '">' +
              updated_date.toLocaleString() +
              '</td>' +
              '<td></td></tr>'
          )
        }
      }

      $('#products-table').footable()
      $(window).trigger('resize')
    } else {
      $('#products-table').append('<tr><th>No Products</th></tr>')
      $('#products-table').footable()
    }

    stopSpinner()
  } else if (event == weaved.DEVELOPER_CUSTOMER_LIST_EVENT) {
    // TODO: Replace this with a React component
    if (status === 'true') {
      contactsData = JSON.parse(message)
      renderLegacyContactsPage()
    }
  } else if (event == weaved.PROJECT_SUPPORTED_PROJECT_TYPES_EVENT) {
    if (status == 'true') {
      productPlatformTypes = message

      obj = JSON.parse(message).data

      list = 'openRegistrationPlatformSelectionList'

      $('option', '#' + list).remove()

      $('#' + list).append(
        $('<option></option>')
          .attr('value', 'clear')
          .text('Choose a project type ...')
      )

      if (obj.types) {
        for (i = 0; i < obj.types.length; i++) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.types[i].code.trim())
              .text(obj.types[i].name.trim())
          )
        }
      }

      list = 'createProductPlatformSelectionList'

      $('option', '#' + list).remove()

      $('#' + list).append(
        $('<option></option>')
          .attr('value', 'clear')
          .text('Choose Product Platform ...')
      )

      if (obj.types) {
        for (i = 0; i < obj.types.length; i++) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.types[i].code.trim())
              .text(obj.types[i].name.trim())
          )
        }
      }
    } else {
      list = 'openRegistrationPlatformSelectionList'

      $('#' + list).append(
        $('<option></option>')
          .attr('value', '')
          .text('No project types available')
      )

      list = 'createProductPlatformSelectionList'

      $('#' + list).append(
        $('<option></option>')
          .attr('value', '')
          .text('No project types available')
      )
    }
  } else if (event == weaved.BULK_PROJECT_LIST_EVENT) {
    $('#registration-services-table').remove()

    $('<table>')
      .attr({
        id: 'registration-services-table',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filter-dropdown-title': 'Filter in:',
        'data-filter-placeholder': 'Filter',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '5',
        'data-filter-connectors': 'false',
      })
      .appendTo('[id="registration-services-content"]')

    $('#registration-services-table').append(
      "<thead><tr><th data-type='text'>Name</th><th data-type='text'>Service Type</th><th data-type='text'>Service Port</th><th data-type='text'>Platform</th></tr></thead><tbody></tbody>"
    )

    if (status == 'true') {
      //alert(message);

      obj = JSON.parse(message)

      if (obj.projects.length < 1) {
        $('#registration-services-table').append('<tr><th>No Registration Projects</th></tr>')
        return
      }

      for (i = 0; i < obj.projects.length; i++) {
        $('#registration-services-table').append(
          '<tr><td>' +
            obj.projects[i].projectname +
            '</td><td>' +
            obj.projects[i].contenttype +
            '</td><td>' +
            obj.projects[i].contentport +
            '</td><td>' +
            obj.projects[i].platformtype +
            '</td></tr>'
        )
      }
    } else {
      $('#registration-services-table').append('<tr><th>No Bulk Registration Projects</th></tr>')
    }

    $('#registration-services-table').footable()
  } else if (event == weaved.PRODUCT_PROJECT_LIST_EVENT) {
    $('#product-services-table').remove()

    $('<table>')
      .attr({
        id: 'product-services-table',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filter-dropdown-title': 'Filter in:',
        'data-filter-placeholder': 'Filter',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '5',
        'data-filter-connectors': 'false',
      })
      .appendTo('[id="product-services-content"]')

    $('#product-services-table').append(
      "<thead><tr><th data-type='text'>Service Name</th><th data-type='text'>Service Type</th><th data-type='text'>Service Port</th><th data-type='text'>Product Platform</th><th data-type='text'>Enabled</th><th data-type='html'>Action</th></tr></thead><tbody></tbody>"
    )

    if (status == 'true') {
      //alert(message);

      obj = JSON.parse(message)

      if (obj.projects.length < 1) {
        $('#product-services-table').append('<tr><th>No Product Services</th></tr>')
        return
      }

      for (i = 0; i < obj.projects.length; i++) {
        delete_icon =
          '<button class="link danger" onclick="showProjectDeleteModal(\'' +
          obj.projects[i].projectid +
          '\');"><i class="fal fa-trash-alt"  data-toggle="modal" data-placement="top" title="Delete" align="center"></i></button>'

        if (obj.projects[i].contenttype == '35' || productLock == 'on' || productLock.toLowerCase() == 'locked')
          delete_icon = ''

        enabled = 'yes'
        if (obj.projects[i].enabled && obj.projects[i].enabled == '0') {
          enabled = 'no'
        }

        // find platform name for readability
        platformName = obj.projects[i].platformtype
        for (j = 0; j < platforms.types.length; j++) {
          if (obj.projects[i].platformtype == platforms.types[j].code) {
            //console.log("type:"+platforms.types[j].name);
            platformName = platforms.types[j].name + ' (' + platforms.types[j].code + ')'
          }
        }

        $('#product-services-table').append(
          '<tr><td>' +
            checkEncodedChar(obj.projects[i].projectname) +
            '</td><td>' +
            obj.projects[i].readabletype +
            ' (' +
            obj.projects[i].contenttype +
            ')</td><td>' +
            obj.projects[i].contentport +
            '</td><td>' +
            platformName +
            '</td><td>' +
            enabled +
            '</td><td class="right txt-md">' +
            delete_icon +
            '</td></tr>'
        )
      }
    } else {
      $('#product-services-table').append('<tr><th>No Product Services</th></tr>')
    }

    $('#product-services-table').footable()
  } else if (event == weaved.BULK_SERVICE_CONFIGURATION_EVENT) {
    $('#bulk-services-table').remove()

    $('<table>')
      .attr({
        id: 'bulk-services-table',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filter-dropdown-title': 'Filter in:',
        'data-filter-placeholder': 'Filter',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '5',
        'data-filter-connectors': 'false',
      })
      .appendTo('[id="bulk-services-content"]')

    $('#bulk-services-table').append(
      "<thead><tr><th data-type='text'>Service Name</th><th data-type='text'>Service IP</th><th data-type='text'>Service Port</th><th data-type='text'>Enabled</th><th data-type='html'>Action</th></tr></thead><tbody></tbody>"
    )

    if (status == 'true') {
      //alert(message);

      obj = JSON.parse(message)

      if (obj.projects.length < 1) {
        $('#bulk-services-table').append('<tr><th>No Bulk Service Configuration</th></tr>')
        return
      }

      for (i = 0; i < obj.projects.length; i++) {
        settings_icon =
          '<button class="btn btn-link primary" onclick="showBulkServiceConfigurationSettingsModal(\'' +
          obj.bulk_id +
          "','" +
          obj.projects[i].project_id +
          "','" +
          obj.projects[i].type +
          "','" +
          obj.projects[i].ip +
          "','" +
          obj.projects[i].port +
          "','" +
          obj.projects[i].enabled +
          '\');"><i class="fal fa-cog"  data-toggle="modal" data-placement="top" title="Settings" align="center"></i></button>'

        if (obj.projects[i].type == 'Required Bulk') settings_icon = ''

        enabled = 'yes'
        if (obj.projects[i].enabled && obj.projects[i].enabled == '0') {
          enabled = 'no'
        }

        $('#bulk-services-table').append(
          '<tr><td>' +
            obj.projects[i].project_name +
            '</td><td>' +
            obj.projects[i].ip +
            '</td><td>' +
            obj.projects[i].port +
            '</td><td>' +
            enabled +
            '</td><td>' +
            settings_icon +
            '</td></tr>'
        )
      }
    } else {
      $('#bulk-services-table').append('<tr><th>No Bulk Service Configuration</th></tr>')
    }

    $('#bulk-services-table').footable()
  } else if (event == weaved.BULK_REGISTRATION_PROVISIONING_EVENT) {
    stopSpinner()

    if (status == 'true') {
      //alert(message);

      obj = JSON.parse(message)

      //alert(obj.status);

      window.open(obj.file, '_provisioning')
    } else {
      alert('Unable to download provisioning files')
    }
  } else if (event == weaved.BULK_REGISTRATION_LIST_EVENT) {
    $('#bulk-registration-table').remove()

    $('<table>')
      .attr({
        id: 'bulk-registration-table',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '20',
        'data-filter-connectors': 'false',
        'data-breakpoints': '{ "xs": 480, "sm": 768, "md": 992, "lg": 1200, "xl": 1400 }',
      })
      .appendTo('[id="bulk-registration-content"]')

    $('#bulk-registration-table').append(
      '<thead>' +
        '<tr>' +
        "<th data-type='text'>Name</th>" +
        "<th data-type='html' data-sort-use='text' id='step4'>Type</th>" +
        "<th data-type='html' data-sort-use='text' id='step4'>Status</th>" +
        "<th data-type='text' data-breakpoints='xs sm md'>Product</th>" +
        "<th data-type='date' data-sorted='true' data-direction='DESC' data-sort-initial='true'>Created</th>" +
        "<th data-breakpoints='xs sm md' data-type='date'>Updated</th>" +
        "<th data-type='html' id='step3' data-sortable='false' class='right'>Actions</th>" +
        "<th data-breakpoints='xs sm md' data-type='number' class='center'>Uploaded</th>" +
        "<th data-breakpoints='xs sm md' data-type='number'class='center'>Pre-registered</th>" +
        '</tr></thead><tbody></tbody>'
    )

    if (status == 'true') {
      //alert(message);
      obj = JSON.parse(message).data
      if (obj.uploads.length < 1) {
        $('#bulk-registration-table').append('<tr><th>No Bulk Registration history</th></tr>')
        $('#bulk-registration-table').footable()
        stopSpinner()

        return
      }

      for (i = 0; i < obj.uploads.length; i++) {
        uploaded = ''
        if (obj.uploads[i].uploaded) uploaded = obj.uploads[i].uploaded
        preregistered = ''
        if (obj.uploads[i].preregistered) preregistered = obj.uploads[i].preregistered
        service_failures = ''
        if (obj.uploads[i].service_failures) service_failures = obj.uploads[i].service_failures
        registered = ''
        if (obj.uploads[i].registered) registered = obj.uploads[i].registered

        product = ''
        if (obj.uploads[i].productname) product = obj.uploads[i].productname

        // look for a name
        name = obj.uploads[i].id
        if (obj.uploads[i].name) name = checkEncodedChar(obj.uploads[i].name)

        // look for a type
        type = 'Bulk'
        if (obj.uploads[i].type) {
          type = obj.uploads[i].type
        }
        if (type === 'Open') type = 'Auto'

        id = obj.uploads[i].id

        state = obj.uploads[i].state

        upload_icon = ''
        settings_icon =
          '<button onclick="showBulkRegistrationSettingsModal(\'' +
          id +
          "','" +
          name +
          '\');" class="btn btn-link px-xs py-none primary"><i class="fal fa-sliders-v fa-fw"  data-toggle="modal" data-placement="top" title="Settings" align="center"></i></button>'
        publish_icon =
          '<button onclick="showBulkRegistrationPublishModal(\'' +
          id +
          "','" +
          name +
          '\');" class="btn btn-success btn-xs px-xs py-none mr-md"><i class="fal fa-check fa-fw mr-xs" data-toggle="modal" data-placement="top" title="Publish" align="center"></i> Publish</button>'
        delete_icon =
          '<button onclick="showBulkRegistrationDeleteModal(\'' +
          id +
          "','" +
          name.replace("'", '').replace('"', '') +
          "','" +
          state +
          '\');" class="btn btn-link px-xs py-none danger"><i class="fal fa-trash-alt fa-fw"  data-toggle="modal" data-placement="top" title="Delete" align="center"></i></button>'
        download_icon =
          '<button onclick="doDownloadProvisioning(\'' +
          id +
          "','" +
          name +
          "','" +
          state +
          '\');" class="btn btn-link px-xs py-none primary"><i class="fal fa-download fa-fw"  data-toggle="modal" data-placement="top" title="Download Provisioning" align="center"></i></button>'
        services_icon =
          '<button onclick="showBulkServicesModal(\'' +
          id +
          "','" +
          name.replace("'", '').replace('"', '') +
          '\');" class="btn btn-link px-xs py-none primary"><i class="fal fa-cog fa-fw"  data-toggle="modal" data-placement="top" title="Services" align="center"></i></button>'
        transfer_icon =
          '<button onclick="showBulkRegistrationTransferModal(\'' +
          id +
          "','" +
          name +
          "','" +
          state +
          '\');" class="btn btn-link px-xs py-none primary"><i class="fal fa-share fa-fw"  data-toggle="modal" data-placement="top" title="Transfer" align="center"></i></button>'
        info_icon =
          '<button onclick="showBulkRegistrationProjectIDModal(\'' +
          id +
          "','" +
          name +
          '\');"  class="btn btn-link px-xs py-none primary"><i class="fal fa-info-circle fa-fw"  title="Show Bulk Registration Device Project Code" align="center"></i></button>'
        info_icon = '' // not used now

        // check open registration flags
        allow = 'off'
        lock = 'off'
        if (obj.uploads[i].auto_registration_enabled && obj.uploads[i].auto_registration_enabled == 'on') {
          allow = 'on'
        }
        if (obj.uploads[i].lock_registration_enabled && obj.uploads[i].lock_registration_enabled == 'on') {
          lock = 'on'
        }

        open_settings_icon =
          '<button onclick="showOpenRegistrationSettingsModal(\'' +
          id +
          "','" +
          name.replace("'", '').replace('"', '') +
          "','" +
          type +
          "','" +
          allow +
          "','" +
          lock +
          '\');" class="btn btn-link px-xs py-none primary"><i class="fal fa-sliders-v fa-fw"  data-toggle="modal" data-placement="top" title="Settings" align="center"></i></button>'

        new_created_date = new Date(Date.parse(obj.uploads[i].created))
        new_updated_date = new Date(Date.parse(obj.uploads[i].updated))

        var now = new Date()
        var timeDiff = now - new_updated_date.getTime()
        var diffDays = Math.floor(timeDiff / (1000 * 3600 * 24))

        settings_icon = open_settings_icon

        // change icon state by registration status
        if (obj.uploads[i].state == 'deleted') {
          settings_icon = ''
          publish_icon = ''
          download_icon = ''
          transfer_icon = ''
          info_icon = ''
        } else if (obj.uploads[i].state == 'preregistered') {
          publish_icon = ''
          settings_icon = ''
        } else if (obj.uploads[i].state == 'configured') {
          settings_icon = ''
          transfer_icon = ''
          info_icon = ''
        } else if (obj.uploads[i].state == 'uploading') {
          settings_icon = ''
          publish_icon = ''
          download_icon = ''
          transfer_icon = ''
          info_icon = ''
          if (diffDays < DELETE_TIME_OVERRIDE) delete_icon = ''
        } else if (obj.uploads[i].state == 'uploaded') {
          publish_icon = ''
          download_icon = ''
          transfer_icon = ''
          info_icon = ''
        } else if (obj.uploads[i].state == 'transferring') {
          settings_icon = ''
          publish_icon = ''
          download_icon = ''
          transfer_icon = ''
          info_icon = ''
          if (diffDays < DELETE_TIME_OVERRIDE) delete_icon = ''
        } else if (obj.uploads[i].state == 'publishing') {
          settings_icon = ''
          publish_icon = ''
          download_icon = ''
          transfer_icon = ''
          info_icon = ''
          if (diffDays < DELETE_TIME_OVERRIDE) delete_icon = ''
        } else if (obj.uploads[i].state == 'deleting') {
          settings_icon = ''
          publish_icon = ''
          download_icon = ''
          transfer_icon = ''
          info_icon = ''
          if (diffDays < DELETE_TIME_OVERRIDE) delete_icon = ''
        } else if (obj.uploads[i].state == 'transfer offer') {
          publish_icon = ''
          settings_icon = ''
          download_icon = ''
          info_icon = ''
        } else {
          publish_icon = ''
          download_icon = ''
          transfer_icon = ''
          info_icon = ''
        }

        // look for open registration overrides
        if (type == 'Open' || type == 'Pooled') {
          if (obj.uploads[i].state == 'new') {
            settings_icon = open_settings_icon
            publish_icon = ''
            download_icon = ''
          }
        }

        download_icon = ''

        if (state == 'new') {
          $('#bulk-registration-table').append(
            '<tr><td>' +
              name +
              '</td><td>' +
              type +
              '</td><td>' +
              state +
              '</td><td>' +
              product +
              '</td>' +
              '<td title="' +
              new_created_date.toUTCString() +
              '" data-sort-value=' +
              new_created_date.toISOString() +
              '">' +
              new_created_date.toLocaleString() +
              '</td>' +
              '<td title="' +
              new_updated_date.toUTCString() +
              '" data-sort-value=' +
              new_updated_date.toISOString() +
              '">' +
              new_updated_date.toLocaleString() +
              '</td>' +
              '<td class="right txt-md">' +
              upload_icon +
              settings_icon +
              publish_icon +
              download_icon +
              services_icon +
              transfer_icon +
              info_icon +
              delete_icon +
              '</td><td class="center">' +
              uploaded +
              '</td><td class="center">' +
              preregistered +
              '</td></tr>'
          )
        } else {
          $('#bulk-registration-table').append(
            '<tr><td class="notranslate">' +
              name +
              '</td><td>' +
              type +
              '</td><td><button class="btn btn-link p-none primary" onclick="showRegistrationDevices(\'' +
              id +
              "','" +
              name +
              "','" +
              new_created_date.toISOString() +
              '\');">' +
              state +
              '</button></td><td class="notranslate">' +
              product +
              '</td>' +
              '<td title="' +
              new_created_date.toUTCString() +
              '" data-sort-value=' +
              new_created_date.toISOString() +
              '">' +
              new_created_date.toLocaleString() +
              '</td>' +
              '<td title="' +
              new_updated_date.toUTCString() +
              '" data-sort-value=' +
              new_updated_date.toISOString() +
              '">' +
              new_updated_date.toLocaleString() +
              '</td>' +
              '<td class="right ws-nowrap">' +
              upload_icon +
              settings_icon +
              publish_icon +
              download_icon +
              services_icon +
              transfer_icon +
              info_icon +
              delete_icon +
              '</td><td class="center">' +
              uploaded +
              '</td><td class="center">' +
              preregistered +
              '</td></tr>'
          )
        }

        //$('#bulk-registration-table > tbody:last-child').append('<tr><td>'+obj.uploads[i].id+'</td><td>'+obj.uploads[i].state+'</td><td>'+obj.uploads[i].created+'</td><td>'+obj.uploads[i].updated+'</td><td>'+obj.uploads[i].count+'</td><td>'+obj.uploads[i].preregistered+'</td><td>'+obj.uploads[i].service_failures+'</td></tr>');
      }

      $('#bulk-registration-table').footable()
      $(window).trigger('resize')
    } else {
      $('#bulk-registration-table').append('<tr><th>No Bulk Registration history</th></tr>')
    }

    stopSpinner()
  } else if (event == weaved.PRODUCT_USAGE_LIST_EVENT) {
    $('#product-usage-table').remove()

    $('<table>')
      .attr({
        id: 'product-usage-table',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filter-dropdown-title': 'Filter in:',
        'data-filter-placeholder': 'Filter',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '8',
        'data-filter-connectors': 'false',
      })
      .appendTo('[id="product-usage-content"]')

    $('#product-usage-table').append(
      '<thead>' +
        '<tr>' +
        "<th data-type='text'>User Name</th>" +
        "<th data-type='text'>Registration Information</th>" +
        "<th data-type='text'>Device Count</th>" +
        "<th data-breakpoints='xs sm md' data-type='date' data-format-string='YYYY-MM-DD HH:mm:ss Z'>Updated</th>" +
        '</tr></thead><tbody></tbody>'
    )

    if (status == 'true') {
      //alert(message);

      obj = JSON.parse(message)

      if (obj.usage.length < 1) {
        $('#product-usage-table').append('<tr><th>No Product Usage available</th></tr>')
        return
      }

      for (i = 0; i < obj.usage.length; i++) {
        // look for a name
        id = obj.usage[i].id
        name = obj.usage[i].name
        count = obj.usage[i].count
        email = obj.usage[i].email
        type = obj.usage[i].type
        new_updated_date = new Date(Date.parse(obj.usage[i].updated))

        $('#product-usage-table').append(
          '<tr><td>' +
            email +
            '</td><td>' +
            name +
            ' (' +
            type +
            ')' +
            '</td><td>' +
            count +
            '</td><td>' +
            new_updated_date.toISOString() +
            '</td></tr>'
        )
      }
    } else {
      $('#product-usage-table').append('<tr><th>No product usage</th></tr>')
    }

    stopSpinner()

    $('#product-usage-table').footable()
  } else if (event == weaved.BULK_PROJECT_ID_EVENT) {
    stopSpinner()

    if (status == 'true') {
      //alert(message);

      obj = JSON.parse(message)

      $('#bulk-identification-code').html(obj.project_id)
    } else {
      alert('Something went wrong fetching the Bulk Registration Identification.')
    }
  } else if (event == weaved.BULK_REGISTRATION_DEVICES_EVENT) {
    //$('#registration-devices-table tbody').empty();
    //$("#registration-devices-table tbody tr").remove();

    $('#registration-devices-table').remove()

    $('<table>')
      .attr({
        id: 'registration-devices-table',
        class: 'table table-hover footable',
        'data-sorting': 'true',
        'data-expand-first': 'true',
        'data-filter-dropdown-title': 'Filter in:',
        'data-filter-placeholder': 'Filter',
        'data-filtering': 'true',
        'data-paging': 'true',
        'data-paging-size': '8',
        'data-filter-connectors': 'false',
      })
      .appendTo('[id="registration-devices-content"]')

    $('#registration-devices-table').append(
      '<thead>' +
        '<tr>' +
        "<th data-type='text'>Device Name</th>" +
        "<th data-type='text'>Status</th>" +
        "<th data-type='text' data-sorted='true' data-direction='DESC' data-sort-initial='true'>Hardware ID</th>" +
        "<th data-type='text'>Registration Key</th>" +
        "<th data-breakpoints='xs sm md' data-type='date' data-format-string='YYYY-MM-DD HH:mm:ss Z'>Created</th>" +
        "<th data-breakpoints='xs sm md' data-type='date' data-format-string='YYYY-MM-DD HH:mm:ss Z'>Updated</th>" +
        '</tr></thead><tbody></tbody>'
    )

    if (status == 'true') {
      //alert(message);

      obj = JSON.parse(message)

      if (obj.uploads.length < 1) {
        $('#registration-devices-table').append('<tr><th>No Bulk Registration history</th></tr>')
        return
      }

      for (i = 0; i < obj.uploads.length; i++) {
        // look for a name
        name = obj.uploads[i].id
        if (obj.uploads[i].device_name) name = obj.uploads[i].device_name

        id = obj.uploads[i].id
        state = obj.uploads[i].state

        delete_icon = '<i class="fal fa-trash-alt"  title="unavailable" align="center"></i></a>'

        /*
                $('#registration-devices-table > tbody:last-child').append('<tr><td>'+name+'</td><td>'+obj.uploads[i].state+'</td><td>'+obj.uploads[i].hardware_id+'</td><td>'+obj.uploads[i].registration_key+'</td><td>'+obj.uploads[i].category_a+'</td><td>'+obj.uploads[i].category_b+'</td><td>'+obj.uploads[i].category_c+'</td><td>'+obj.uploads[i].category_d+'</td><td>'+obj.uploads[i].category_e+'</td><td>'+obj.uploads[i].created+'</td><td>'+obj.uploads[i].updated+'</td><td>'+ delete_icon + '</td></tr>');
                 */

        new_created_date = new Date(Date.parse(obj.uploads[i].created))
        new_updated_date = new Date(Date.parse(obj.uploads[i].updated))

        $('#registration-devices-table').append(
          '<tr><td>' +
            name +
            '</td><td>' +
            obj.uploads[i].state +
            '</td><td>' +
            obj.uploads[i].hardware_id +
            '</td><td>' +
            obj.uploads[i].registration_key +
            '</td><td>' +
            new_created_date.toISOString() +
            '</td><td>' +
            new_updated_date.toISOString() +
            '</td></tr>'
        )
      }
    } else {
      $('#registration-devices-table').append('<tr><th>No Bulk Registration history</th></tr>')
    }

    stopSpinner()

    $('#registration-devices-table').footable()
  } else if (event == weaved.SSH_CONNECT_EVENT) {
    //alert("ssh:"+message);
    $('#device-connecting-status').hide()

    if (deviceServiceAction == 'launch') {
      $('#device-services-selection-status').html('')
      $('#device-services-confirm').attr('disabled', 'disabled')

      $('#Device-Services-Modal').modal('hide')

      message = message.substring(message.indexOf(':') + 3)
      host = message.substring(0, message.indexOf(':'))
      port = message.substring(message.indexOf(':') + 1)

      $('#sshHost_1').val(host)
      $('#sshPort_1').val(port)

      $('#ssh_pi').val('ssh -l pi ' + host + ' -p ' + port)
      $('#ssh_root').val('ssh -l root ' + host + ' -p ' + port)
      $('#ssh_other').val('ssh -l LOGIN ' + host + ' -p ' + port)

      $('#ssh-connection-name').html(launchDeviceServiceName)
      //window.location.href = 'ssh://' + host + ':' + port
      $('#SSH-Connection-Modal').modal('show')
    }

    //stopSpinner();
    if (status == 'true') {
      updateConnectedService(message, tag)
    } else {
      alert('Sorry, we were not able to connnect to your device.')
    }
  } else if (event == weaved.TCP_CONNECT_EVENT) {
    //alert("TCP:"+message);

    $('#device-connecting-status').modal('hide')

    if (deviceServiceAction == 'launch') {
      $('#device-services-selection-status').html('')
      $('#device-services-confirm').attr('disabled', 'disabled')

      $('#Device-Services-Modal').modal('hide')

      // If a RDP service, we need to parse the host and port
      // from the legacy Java URL that looks like:
      // http://proxy70.remot3.it/rdp/rdp.php?port=31904
      if (e.type === 5 || e.type === 21) {
        // Create a temporary element to make parsing the URL easier.
        // If this code had access to npm we should use a library for this
        // instead, but hey...
        var element = document.createElement('a')
        element.href = message
        host = element.hostname
        port = e.type === 5 ? element.port : element.search.replace('?', '').split('=')[1]
        message = host + ':' + port
      } else {
        message = message.substring(message.indexOf(':') + 3)
        host = message.substring(0, message.indexOf(':'))
        port = message.substring(message.indexOf(':') + 1)
        /*if (e.type === 37) {
          window.location.href =
            'nx-vms://nxvms.com/client/' + message + '?auth'
        }*/
      }

      $('#tcp_host').val(host)
      $('#tcp_port').val(port)
      $('#tcp_url').val(message)
      $('#tcp-connection-name').html(launchDeviceServiceName)
      $('#TCP-Connection-Modal').modal('show')
    }

    //stopSpinner();
    if (status == 'true') {
      updateConnectedService(message, tag)
    } else {
      alert('Sorry, we were not able to connnect to your device.')
    }
  } else if (event == weaved.HTTP_CONNECT_EVENT) {
    $('#device-connecting-status').hide()
    //handle vnc connections differently
    if (/^vnc?:\/\//g.test(message)) {
      server = message.substring(message.lastIndexOf('//') + 2, message.lastIndexOf(':'))
      port = message.substring(message.lastIndexOf(':') + 1, message.length + 1)
      $('#device-services-selection-status').html(launchName)
      $('#device-services-confirm').attr('disabled', 'disabled')
      $('#Device-Services-Modal').modal('hide')
      $('#vnc_url').val(message)
      $('#vnc_url_no_protocol').val(server + ':' + port)
      $('#vnc-connection-name').html(launchDeviceServiceName)
      $('#vnc_host').val(server)
      $('#vnc_port').val(port)
      $('#VNC-Connection-Modal').modal('show')
    } else if (deviceServiceAction == 'launch') {
      $('#device-services-selection-status').html(launchName)
      $('#device-services-confirm').attr('disabled', 'disabled')
      $('#Device-Services-Modal').modal('hide')
      $('#web_url').val(message)
      $('#HTTP-Connection-Modal').modal('show')
      $('#web-connection-name').html(launchDeviceServiceName)
      if (tag == 'blocked') {
        $('#blocked-popup').show()
        $('#alternate-open').hide()
      } else {
        $('#blocked-popup').hide()
        $('#alternate-open').show()
      }
    }

    //stopSpinner();
    if (status == 'true') {
      updateConnectedService(message, launchDeviceServiceAddress)
    } else {
      alert('Sorry, we were not able to connnect to your device.')
    }
  } else if (event == weaved.SEND_COMMAND_EVENT) {
    stopSpinner()
    if (status == 'true') {
      //alert("command ok to "+ tag);
      //updateConnectedService(message,tag);
    } else {
      alert('Sorry, we were not able to send the command to device ' + tag)
    }
  } else if (event == weaved.BULK_SEND_EVENT) {
    stopSpinner()
    if (status == 'true') {
      //alert("bulk send ok to "+ tag);
      // Update service list with bulk status - STARTED
    } else {
      alert('Sorry, we were not able to send the bulk commands to device ' + tag)
    }
  } else if (event == weaved.DISCONNECT_EVENT) {
    //alert("disconnect event" + tag);

    stopSpinner()

    if (status == 'true') {
      //updateDisconnectedService(tag);
    } else {
      alert('Sorry, we were not able to disconnnect to your device.')
    }
  } else if (event == weaved.GET_SCRIPT_REPORT_EVENT) {
    stopSpinner()

    if (status == 'true') {
      stepDescription = 'Select from the list'
      title = 'List Select'

      if (listSelectMultiple) {
        list = 'itemMultipleSelectionList'
        stepDescription = 'Select one or more items from the list'
      } else {
        list = 'itemSelectionList'
      }

      obj = JSON.parse(message)
      items = obj.report.split(':')

      if (items.length > 0) {
        var jData = []

        report = JSON.parse(Base64.decode(items[2]))
        colFilters = items[3].split(',')
        if (colFilters.length > 2) {
          tableName = colFilters[1]

          if (colFilters[2].indexOf('(') > -1) {
            s = colFilters[2].indexOf('(')
            rowFilterColumnName = colFilters[2].substring(0, s).trim()
          } else {
            rowFilterColumnName = colFilters[2]
          }

          jData = report[tableName]

          var fData = []
          for (i = 0; i < jData.length; i++) {
            if (jData[i][rowFilterColumnName].toLowerCase() != 'no data available') {
              fData.push(jData[i][rowFilterColumnName])
            }
          }
        }
      }

      for (i = 0; i < fData.length; i++) {
        $('#' + list).append(
          $('<option></option>')
            .attr('value', fData[i])
            .text(fData[i])
        )
      }

      $('#wizardSteps').html(
        'Step ' + executeStep + ' of ' + (executeHeaderSteps.length - 1) + ': ' + title + ' - ' + stepDescription
      )

      if (executeStep >= executeHeaderSteps.length - 1) {
        $('#wizardNextBtn')
          .text('Finish')
          .data('state', 'finish')
      }
    } else {
      $('#wizardNexthBtn').button('disable')
      $('#bulk-execute-status').html('No script report available for ' + tag)
    }
  } else if (event == weaved.UPLOAD_EVENT) {
    //alert("upload:"+status);

    //stopSpinner();

    $(window).unbind('beforeunload', null)
    window.onbeforeunload = null

    $('#_submit').prop('disabled', false)

    if (status === 'true') {
      $('#uploads').val('')
      $('#upload-status').html('Upload succeeded')
      $('#upload-progress-status').hide()

      weaved.bulkScriptHeaders()
    } else {
      $('#upload-status').html(tag)
      $('#upload-progress-status').hide()
    }
  } else if (event == weaved.BULK_UPLOAD_EVENT) {
    //stopSpinner();

    $(window).unbind('beforeunload', null)
    window.onbeforeunload = null

    if (status == 'true') {
      $('#bulkuploads').val('')
      $('#bulk-upload-status').html('Upload succeeded')
      $('#bulkupload-progress-status').hide()
      $('#bulkUploadStatusDiv').hide()

      $('#bulk-upload-status').html('')
      $('#_bulksubmit').removeAttr('disabled')
      $('#bulkupload').modal('hide')
      $('#Open-Registration-Create-Modal').modal('hide')

      weaved.getBulkRegistrations()
    } else {
      $('#bulk-upload-status').html('Error: ' + message)
      $('#bulkupload-progress-status').hide()
    }
  } else if (event == weaved.BULK_TASK_DONE_EVENT) {
    obj = JSON.parse(message)
    if ($('#job_' + obj.jobid) && $('#job_action_' + obj.jobid)) {
      $('#job_' + obj.jobid).html(renderScriptingStatus(obj.jobid, 'done', 'primary'))
      $('#job_action_' + obj.jobid).html(renderScriptingAction(obj.jobid, 'done'))
    }
    if (status == 'true' && !jobDone) {
      tasksCompleted = tasksCompleted + 1

      $('#job_activity_icon').show()
    }
  } else if (event == weaved.JOB_DONE_EVENT) {
    if (status == 'true') {
      weaved.getBulkJobs()
      $('#job_activity_icon').hide()
    }
  } else if (event == weaved.JOB_STARTED_EVENT) {
    if (status == 'true') {
      weaved.getBulkJobs()
      $('#job_activity_icon').show()
    }
  } else if (event == weaved.JOB_FAILED_EVENT) {
    if (status == 'true') {
      weaved.getBulkJobs()
      $('#job_activity_icon').hide()
    }
  } else if (event == weaved.BULK_TASK_FAILED_EVENT) {
    if (status == 'true' && !jobDone) {
      tasksFailed = tasksFailed + 1

      $('#job_activity_icon').show()
    }
  } else if (event == weaved.BULK_SERVICE_STATUS_A_EVENT) {
    if (status == 'true') {
      //alert("A:"+message);
      obj = JSON.parse(message)
      updateServiceStatusA(obj.uid, obj.description)
    }
  } else if (event == weaved.BULK_SERVICE_STATUS_B_EVENT) {
    if (status == 'true') {
      //alert("B:"+message);
      obj = JSON.parse(message)
      updateServiceStatusB(obj.uid, obj.description)
    }
  } else if (event == weaved.BULK_SERVICE_STATUS_C_EVENT) {
    if (status == 'true') {
      //alert("C:"+message);
      obj = JSON.parse(message)
      updateServiceStatusC(obj.uid, obj.description)
    }
  } else if (event == weaved.BULK_SERVICE_STATUS_D_EVENT) {
    if (status == 'true') {
      //alert("D:"+message);
      obj = JSON.parse(message)
      updateServiceStatusD(obj.uid, obj.description)
    }
  } else if (event == weaved.BULK_SERVICE_STATUS_E_EVENT) {
    if (status == 'true') {
      //alert("E:"+message);
      obj = JSON.parse(message)
      updateServiceStatusE(obj.uid, obj.description)
    }
  } else if (event == weaved.DEVELOPER_DEVICES_UNREGISTER_EVENT) {
    if (status == 'true') {
      window.weaved.fetchDevices(true)
    }
  } else if (event == weaved.DEVELOPER_DEVICE_UNREGISTER_EVENT) {
    if (status == 'true') {
      DEVICE_LIST.jqGrid('delRowData', tag.toUpperCase())
    } else {
      $('#device-delete-status').html('Delete error: ' + message)
      $('#device-delete-button').removeAttr('disabled')
      $('#delete-device-progress').hide()

      return
    }

    // look for last deleted row to clean up UI
    deletedRows = deletedRows + 1
    if (deleteRows == deletedRows) {
      if (!deviceListDisabled) {
        $('#device-delete-button').removeAttr('disabled')
        $('#delete-device-progress').hide()
        $('#Delete-Device-Modal').modal('hide')
      }
    }
  } else if (event == weaved.BULK_REGISTRATION_PUBLISH_EVENT) {
    if (status == 'true') {
      showRegistrations()
      $('#bulkRegistrationPublishButton').removeAttr('disabled')
      $('#Bulk-Registration-Publish-Modal').modal('hide')
    } else {
      alert('Bulk registration completed with some errors.')
    }
  } else if (event == weaved.BULK_REGISTRATION_FINAL_DELETE_EVENT) {
    if (status == 'true') {
      weaved.getBulkRegistrations()
      $('#Bulk-Registration-Delete-Modal').modal('hide')
    } else {
      alert('Bulk registration delete completed with some errors.' + message)
    }
  } else if (event == weaved.BULK_REGISTRATION_TRANSFER_TASK_EVENT) {
    if (status == 'true') {
      weaved.getBulkRegistrations().then(function() {
        weaved.fetchDevices()
      })
    } else {
      alert('Bulk registration transfer completed with some errors.')
    }
  } else if (
    event == weaved.BULK_REGISTRATION_PUBLISHED_TASK_EVENT ||
    event == weaved.BULK_REGISTRATION_DELETED_TASK_EVENT
  ) {
    if (status == 'true') {
      weaved.getBulkRegistrations().then(function() {
        weaved.fetchDevices()
      })
    } else {
      alert('Bulk registration completed with some errors.')
    }
  } else if (event == weaved.DEVICE_SHARE_INVITATION_EVENT) {
    if (status == 'true') {
      obj = JSON.parse(message)

      invitationGUID = obj.invite
      invitationType = 'sharing'
      invitationFrom = obj.from
      invitationDevices = obj.devices

      $('#invitation-type').html('Sharing')
      $('#invitation-from').html(obj.from)
      $('#invitation-details').html(obj.devices + ' devices shared to you')

      //alert("You have received a Sharing Invitation from "+obj.from);
      toastr.info(
        '<span id="share-received"><h3>Share Invitation Received</h3> ' +
          obj.from +
          ' is sharing ' +
          obj.devices +
          ' device(s) with you. Click here for more information. </span>'
      )
    } else {
      alert('Device sharing invitation received with some errors.')
    }
  } else if (event == weaved.DEVICE_TRANSFER_INVITATION_EVENT) {
    if (status == 'true') {
      obj = JSON.parse(message)

      invitationGUID = obj.invite
      invitationType = 'transferring'
      invitationFrom = obj.from
      invitationDevices = obj.devices

      $('#invitation-type').html('Device Transfer')
      $('#invitation-from').html(obj.from)
      $('#invitation-details').html(obj.devices + ' devices transferred to you')

      //alert("You have received a Sharing Invitation from "+obj.from);
      toastr.info(
        '<span id="transfer-received"><h3>Transfer Invitation Received</h3> ' +
          obj.from +
          ' is transferring ' +
          obj.devices +
          ' device(s) with you. Click here for more information. </span>'
      )
    } else {
      alert('Device transfer invitation received with some errors.')
    }
  } else if (event == weaved.BULK_TRANSFER_INVITATION_EVENT) {
    if (status == 'true') {
      obj = JSON.parse(message)

      invitationGUID = obj.invite
      invitationType = 'transferring'
      invitationFrom = obj.from
      invitationDevices = obj.devices

      $('#invitation-type').html('Bulk Transfer')
      $('#invitation-from').html(obj.from)
      $('#invitation-details').html(obj.devices + ' devices transferred to you')

      //alert("You have received a Sharing Invitation from "+obj.from);
      toastr.info(
        '<span id="bulk_transfer-received"><h3>Bulk Registration Transfer Invitation Received</h3> ' +
          obj.from +
          ' is transferring ' +
          obj.devices +
          ' bulk registered device(s) with you. Click here for more information. </span>'
      )
    } else {
      alert('Device transfer invitation received with some errors.')
    }
  } else if (event == ACCEPT_INVITATION_EVENT) {
    //alert("accepted invitation:"+message);

    if (status == 'true') {
      $('#Invitation-Modal').modal('hide')
    } else {
      alert('Accept invitation received with some errors.')
    }
  } else if (event == weaved.DELETE_PRODUCT_EVENT) {
    if (status == 'true') {
      weaved.getProducts()
      //alert("Bulk registration delete has completed.");
      $('#Product-Delete-Modal').modal('hide')
    } else {
      alert('Product delete completed with some errors.')
    }
  } else if (event == weaved.BULK_REGISTRATION_TRANSFER_EVENT) {
    if (status == 'true') {
      weaved.getBulkRegistrations()
      //alert("Bulk registration delete has completed.");
      $('#Bulk-Registration-Transfer-Modal').modal('hide')
    } else {
      alert('Bulk registration transfer failed.')
    }
    //clear modal selections
  } else if (event == weaved.BULK_REGISTRATION_DELETE_EVENT) {
    if (status == 'true') {
      weaved.getBulkRegistrations()
      //alert("Bulk registration delete has completed.");
      $('#Bulk-Registration-Delete-Modal').modal('hide')
    } else {
      alert('Bulk registration delete completed with some errors.')
    }
  } else if (event == weaved.BULK_REGISTRATION_CREATE_EVENT) {
    $('#openRegistrationSubmit').removeAttr('disabled')

    if (status == 'true') {
      $('#openRegistrationName').val('')
      weaved.getBulkRegistrations()
      //alert("Bulk registration delete has completed.");
      $('#Open-Registration-Create-Modal').modal('hide')
    } else {
      alert('Open registration create completed with some errors.')
    }
  } else if (event == weaved.BULK_PRODUCT_CREATE_EVENT) {
    $('#createProductSubmit').removeAttr('disabled')

    if (status == 'true') {
      $('#createProductName').val('')
      weaved.getProducts()
      $('#Product-Create-Modal').modal('hide')
    } else {
      alert('Product create completed with some errors.')
    }
  } else if (event == weaved.DEVELOPER_CUSTOMER_CREATE_EVENT) {
    $('#createCustomerSubmit').removeAttr('disabled')

    if (status == 'true') {
      $('#createCustomerFirstName').val('')
      $('#createCustomerLastName').val('')
      $('#createCustomerEmail').val('')
      $('#Customer-Create-Modal').modal('hide')
      weaved.getContacts()
    } else {
      alert('Customer create completed with some errors.')
    }
  } else if (event == weaved.CUSTOMER_CREATED_EVENT) {
    if (status == 'true') {
      toastr.info(
        '<span id="customer_created"><h3>Customer Created Confirmation</h3> ' +
          obj.customer_email +
          ' confirmed their new account and can accept shares and transfers now. </span>'
      )
      weaved.getContacts()
    } else {
      alert('Customer create completed with some errors.')
    }
  } else if (event == weaved.VERIFY_EMAIL_EVENT) {
    stopSpinner()
    if (status == 'true') {
      $('#Create-Account-Modal').modal('hide')
      $('#Verify-Account-Modal').modal('show')
    } else {
      obj = JSON.parse(message)
      if (obj.reason && obj.reason == 'email already registered') {
        $('#verify-fail').html('This email address is already registered')
      } else {
        $('#verify-fail').html('Email verification failed - please check your email address')
      }
    }
  } else if (event == weaved.VERIFIED_EMAIL_EVENT) {
    stopSpinner()
    if (status == 'true') {
      $('#Create-Account-Modal').modal('hide')
      $('#Verify-Account-Modal').modal('hide')

      $('#inputEmail').val($('#verifyEmail').val())
      $('#inputPassword').val('')
      $('#signup-fail').html('')
      $('#sign-in-modal').modal('show')
    } else {
      obj = JSON.parse(message)
      if (obj.reason && obj.reason == 'verification code expired or wrong') {
        $('#signup-fail').html('This verification code is expired or wrong')
      } else {
        $('#signup-fail').html('Email verification failed - please try again')
      }
    }
  } else if (event == weaved.GET_SCRIPT_HEADERS_EVENT) {
    if (status == 'true') {
      scriptHeaders = message
    }
  }

  // look for completed job
  if (tasksCreated > 0 && tasksCreated == tasksCompleted + tasksFailed) {
    taskJobID = ''
    tasksCompleted = 0
    tasksCreated = 0
    tasksFailed = 0
    jobDone = true

    if (selectedRows && selectedRows.length > 0) {
      for (i = 0, count = selectedRows.length; i < count; i++) {
        DEVICE_LIST.jqGrid('setSelection', selectedRows[i], false)
      }
    }
  }
}

function humanFileSize(bytes, si) {
  var thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  var units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  var u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + ' ' + units[u]
}

function capitalize(s) {
  try {
    return s[0].toUpperCase() + s.slice(1)
  } catch (err) {}
  return s
}

function updateConnectedService(message, address) {
  server = message.substring(message.lastIndexOf('//') + 2, message.lastIndexOf(':'))
  port = message.substring(message.lastIndexOf(':') + 1, message.length + 1)

  // update in memory state
  service = rState.getService(address)
  service.Status = 'connected'
  service.ConnectIP = server
  service.Portid = port
  rState.setService(address, service)
}

function updateServiceStatusA(address, status) {
  var rows = DEVICE_LIST.getDataIDs()
  for (a = 0; a < rows.length; a++) {
    var row = DEVICE_LIST.getRowData(rows[a])
    if (row.Mac == address) {
      // update in memory state
      device = rState.getDevice(row.Mac)
      device.StatusA = status
      rState.setDevice(row.Mac, device)

      DEVICE_LIST.jqGrid('setRowData', rows[a], {
        StatusA: status,
      })
      DEVICE_LIST.jqGrid('saveRow', rows[a], false)
    }
  }
  DEVICE_LIST.trigger('reloadGrid')
}

function updateServiceStatusB(address, status) {
  var rows = DEVICE_LIST.getDataIDs()
  for (a = 0; a < rows.length; a++) {
    var row = DEVICE_LIST.getRowData(rows[a])
    if (row.Mac == address) {
      // update in memory state
      device = rState.getDevice(row.Mac)
      device.StatusB = status
      rState.setDevice(row.Mac, device)

      DEVICE_LIST.jqGrid('setRowData', rows[a], {
        StatusB: status,
      })
      DEVICE_LIST.jqGrid('saveRow', rows[a], false)
    }
  }
  DEVICE_LIST.trigger('reloadGrid')
}

function updateServiceStatusC(address, status) {
  var rows = DEVICE_LIST.getDataIDs()
  for (a = 0; a < rows.length; a++) {
    var row = DEVICE_LIST.getRowData(rows[a])
    if (row.Mac == address) {
      // update in memory state
      device = rState.getDevice(row.Mac)
      device.StatusC = status
      rState.setDevice(row.Mac, device)

      DEVICE_LIST.jqGrid('setRowData', rows[a], {
        StatusC: status,
      })
      DEVICE_LIST.jqGrid('saveRow', rows[a], false)
    }
  }
  DEVICE_LIST.trigger('reloadGrid')
}

function updateServiceStatusD(address, status) {
  var rows = DEVICE_LIST.getDataIDs()
  for (a = 0; a < rows.length; a++) {
    var row = DEVICE_LIST.getRowData(rows[a])
    if (row.Mac == address) {
      // update in memory state
      device = rState.getDevice(row.Mac)
      device.StatusD = status
      rState.setDevice(row.Mac, device)

      DEVICE_LIST.jqGrid('setRowData', rows[a], {
        StatusD: status,
      })
      DEVICE_LIST.jqGrid('saveRow', rows[a], false)
    }
  }
  DEVICE_LIST.trigger('reloadGrid')
}

function updateServiceStatusE(address, status) {
  var rows = DEVICE_LIST.getDataIDs()
  for (a = 0; a < rows.length; a++) {
    var row = DEVICE_LIST.getRowData(rows[a])
    if (row.Mac == address) {
      // update in memory state
      device = rState.getDevice(row.Mac)
      device.StatusE = status
      rState.setDevice(row.Mac, device)

      DEVICE_LIST.jqGrid('setRowData', rows[a], {
        StatusE: status,
      })
      DEVICE_LIST.jqGrid('saveRow', rows[a], false)
    }
  }
  DEVICE_LIST.trigger('reloadGrid')
}

function disconnectIt(status) {
  $('#DisconnectWarning').modal('hide')
  //$('.modal-backdrop').hide();

  startSpinner()

  if (status == 'true') {
    weaved.disconnect(disconnectAddress)
  } else {
    disconnectAddress = ''
  }
}

function countSelectedDevices() {
  return getSelectedRows().length
}

function getSelectedDevices() {
  var items = []
  DEVICE_LIST.find('input[type=checkbox]').each(function() {
    var deviceID = $(this)
      .parents('tr:last')
      .attr('id')
    if ($(this).is(':checked')) {
      items.push(rState.getDevice(deviceID))
    }
  })

  return items
}

function getSelectedRows() {
  var items = []
  DEVICE_LIST.find('input[type=checkbox]').each(function() {
    var rowid = $(this)
      .parents('tr:last')
      .attr('id')
    if ($(this).is(':checked')) {
      row = DEVICE_LIST.getRowData(rowid)
      items.push(row)
    }
  })

  return items
}
function unselectSelectedRows() {
  DEVICE_LIST.find('tbody tr').removeClass('success')
  DEVICE_LIST.jqGrid('resetSelection')
}
function removeSelectedRows() {
  DEVICE_LIST.find('input[type=checkbox]:checked').each(function() {
    $(this)
      .closest('tr')
      .remove()
  })
}
function getSelectedRowsIDs() {
  items = []
  DEVICE_LIST.find('input[type=checkbox]').each(function() {
    var rowid = $(this)
      .parents('tr:last')
      .attr('id')
    if ($(this).is(':checked')) {
      items.push(rowid)
    }
  })

  return items
}

function getRowIDs() {
  items = []
  DEVICE_LIST.find('input[type=checkbox]').each(function() {
    var rowid = $(this)
      .parents('tr:last')
      .attr('id')
    items.push(rowid)
  })

  return items
}

function sendBulkCommand(rawcommand, script, file, expiration, post_func, options, allowUpdates, servicesList) {
  $('#Send-Command-Modal').hide()
  $('#Script-Execution-Modal').hide()
  unselectSelectedRows()
  var counter = 0
  tasksCreated = 0
  tasksCompleted = 0
  tasksFailed = 0
  taskJobID = ''
  jobDone = false
  services = ''

  if (servicesList) {
    // Service List Provided
    servicesList.forEach(function(service) {
      services = services + '"' + service + '",'
      counter = counter + 1
    })
    services = services.substring(0, services.length - 1)
  } else {
    // No Service List Provided, check rows
    DEVICE_LIST.find('input[type=checkbox]').each(function() {
      var rowid = $(this)
        .parents('tr:last')
        .attr('id')
      if ($(this).is(':checked')) {
        row = DEVICE_LIST.getRowData(rowid)
        services = services + '"' + row.Mac + '",'
        counter = counter + 1
      }
    })
    services = services.substring(0, services.length - 1)
  }

  // submit the job
  if (counter === 0) {
    alert('No devices are selected for bulk execution')
    stopSpinner()
    return
  } else {
    window.remot3it.r3.jobs
      .send(script, file, rawcommand, services, expiration, post_func, options, allowUpdates)
      .then(function(item) {
        if (item.reason && item.status == 'false') {
          alert('Sorry, unable to get a job ID. ' + item.reason)
        } else if (item.jobid && item.jobid.length > 0) {
          tasksCreated = counter
          tasksCompleted = 0
          tasksFailed = 0
          taskJobID = item.jobid

          // show job activity
          $('#job_activity_icon').show()
        } else {
          alert('Sorry, unable to get a job ID. ')
        }

        $('#bulkCommand').val('')
      })
  }
}

function bulkSelectionDone() {
  scriptSelected = $('#scriptSelectionList').val()
  fileSelected = $('#fileSelectionList').val()
  scriptSelectedName = $('#scriptSelectionList').val()
  fileSelectedName = $('#fileSelectionList').val()
  scriptSelectedPath = $('#scriptSelectionList').val()
  fileSelectedPath = $('#fileSelectionList').val()
  optionalParam = $('#scriptParameter').val()
  executeParam = $('#executeParam').val()

  try {
    scriptSelectedName = scriptSelected.substring(scriptSelected.lastIndexOf('/') + 1)
    scriptSelectedPath = scriptSelected.substring(0, scriptSelected.lastIndexOf('/'))
  } catch (err) {}

  try {
    fileSelectedName = fileSelected.substring(fileSelected.lastIndexOf('/') + 1)
    fileSelectedPath = fileSelected.substring(0, fileSelected.lastIndexOf('/'))
  } catch (err) {}

  if (!fileSelected) {
    fileSelected = ''
    fileSelectedName = ''
  }

  startSpinner()
  $('#Script-Execution-Modal').modal('hide')
  //$('.modal-backdrop').hide();

  selectedRows = getSelectedRowsIDs()

  // check if the user is waiting
  if ($('#waitForScript').prop('checked')) {
    $('#Bulk-Wait-Modal').show()
  }

  xferCommand = Base64.decode('d2dldA==')

  if (bulkSelection == 'transferfile') {
    window.remot3it.r3.utilities.tinyUrl(fileSelected).then(function(tinyURL) {
      if (tinyURL != false) {
        fileSelected = tinyURL
        fileSelected = tinyURL.substring(tinyURL.lastIndexOf('/'))
      }

      command = 'cd /tmp;' + xferCommand + ' https://$3/t' + fileSelected + ' <noc> -O ' + fileSelectedName + '&'

      sendBulkCommand(command, '', fileSelectedName, '', '', DEFAULT_OPTIONS, 'no')
    })
  } else if (bulkSelection == 'transferscript') {
    window.remot3it.r3.utilities.tinyUrl(scriptSelected).then(function(tinyURL) {
      if (tinyURL != false) {
        scriptSelected = tinyURL
        scriptSelected = tinyURL.substring(tinyURL.lastIndexOf('/'))
      }

      command =
        'cd /tmp;' +
        xferCommand +
        ' https://$3/t' +
        scriptSelected +
        ' <noc> -O ' +
        scriptSelectedName +
        ';chmod +x /tmp/' +
        scriptSelectedName +
        '&'
      sendBulkCommand(command, scriptSelectedName, '', '', '', DEFAULT_OPTIONS, 'no')
    })
  } else if (bulkSelection == 'executescript') {
    window.remot3it.r3.utilities.tinyUrl(scriptSelected).then(function(tinyURL) {
      if (tinyURL != false) {
        scriptSelected = tinyURL
        scriptSelected = tinyURL.substring(tinyURL.lastIndexOf('/'))
      }

      if (fileSelected.length > 0 && executeWithFile) {
        window.remot3it.r3.utilities.tinyUrl(fileSelected).then(function(tinyURL) {
          if (tinyURL != false) {
            fileSelected = tinyURL
            fileSelected = tinyURL.substring(tinyURL.lastIndexOf('/'))
          }
          if (executeParam.length > 0 && executeWithParam) {
            window.remot3it.r3.utilities.tinyUrl(executeParam).then(function(tinyURL) {
              if (tinyURL != false) {
                executeParam = tinyURL
                executeParam = tinyURL.substring(tinyURL.lastIndexOf('/') + 1)
              }
              executeScript()
            })
          } else {
            executeScript()
          }
        })
      } else if (executeParam.length > 0 && executeWithParam) {
        window.remot3it.r3.utilities.tinyUrl(executeParam).then(function(tinyURL) {
          if (tinyURL != false) {
            executeParam = tinyURL
            executeParam = tinyURL.substring(tinyURL.lastIndexOf('/') + 1)
          }
          executeScript()
        })
      }
    })
  }

  //alert("item selected:"+fileSelected);
}

function setName(event) {
  if (event) event.preventDefault()

  startSpinner()

  if (nameChange !== 'device') return

  var name = $('#setDeviceName').val()

  $('#set-name_button').attr('disabled', 'true')

  return weaved.setName(nameChangeAddress, name).then(function() {
    return weaved.fetchDevices(true).then(function() {
      $('#Set-Name-Modal').modal('hide')
      showServicesModal()
      stopSpinner()
      $('#setDeviceName').val('')
    })
  })
}

function bulkRegistrationSettingsDone() {
  newName = $('#bulkRegistrationName').val()
  product = $('#bulkProductSelectionList option:selected').val()
  productid = $('#bulkProductSelectionList option:selected').text()

  // check the selected product
  //alert(product);

  if (!product || product == 'clear') {
    $('#bulk-settings-status').html('You must select a product for this bulk registration')
    return
  }

  if (validateName(newName)) {
    window.remot3it.r3.registrations.setName(bulkRegistrationID, newName).then(function(item) {
      if (item.status === 'true') {
        window.remot3it.r3.registrations.setProduct(bulkRegistrationID, product, productid).then(function(item) {
          if (item.status === 'true') {
            weaved.getBulkRegistrations()

            $('#bulkRegistrationName').val('')
            $('#bulkProductSelectionList').val('')
            $('#Bulk-Registration-Settings-Modal').modal('hide')
          } else {
            $('#bulk-settings-status').html('Unexpected error setting the registration product descriptor')
          }
        })
      } else {
        $('#bulk-settings-status').html('Unexpected error setting the registration name')
      }
    })
  } else {
    $('#bulk-settings-status').html('Invalid characters in the registration name')
  }
}

function bulkRegistrationPublishDone() {
  if ($('#confirmRegistrationPubllish').prop('checked') == false) {
    $('#bulk-preregister-status').html('You must select the checkbox to confirm you want to publish')
  } else {
    startSpinner()
    $('#bulk-preregister-status').html('Publishing may take a few minutes to complete')

    $('#bulkRegistrationPublishButton').attr('disabled', 'disabled')

    weaved.publishBulkRegistration(bulkRegistrationID)
  }
}

function bulkRegistrationDeleteDone() {
  if ($('#confirmRegistrationDelete').prop('checked') == false) {
    $('#bulk-delete-status').html('You must select the checkbox to confirm you want to delete')
  } else {
    //alert("final Delete:"+bulkRegistrationID+":"+bulkRegistrationState);
    //return;

    startSpinner()

    if (bulkRegistrationState == 'preregistered' || bulkRegistrationState == 'invalid') {
      $('#bulk-delete-status').html('Deleting may take a few minutes to complete')

      $('#bulkRegistrationDeleteButton').attr('disabled', 'true')
      weaved.deleteBulkRegistration(bulkRegistrationID)
    } else {
      $('#bulk-delete-status').html('Deleting may take a few minutes to complete')
      $('#bulkRegistrationDeleteButton').attr('disabled', 'true')
      weaved.finalDeleteBulkRegistration(bulkRegistrationID)
    }
  }
}

function bulkRegistrationTransferDone() {
  if (window.remot3it.store.getState().contacts.selected.length === 0) {
    $('#bulk-transfer-status').html(
      "<div class='df ai-center py-sm px-md bg-danger my-md'><span class='txt-lg white'><span class='fal fa-exclamation-triangle mr-md'></span></span><span class='f-1 white'>Please select a contact to continue</span></div>"
    )
  } else if ($('#confirmRegistrationTransfer').prop('checked') == false) {
    $('#bulk-transfer-status').html(
      "<div class='df ai-center py-sm px-md bg-danger my-md'><span class='txt-lg white'><span class='fal fa-exclamation-triangle mr-md'></span></span><span class='f-1 white'>You must select the checkbox to confirm you want to transfer</span></div>"
    )
  } else {
    newOwner = window.remot3it.store.getState().contacts.selected.value
    contacts = window.remot3it.store.getState().contacts.all

    startSpinner()
    $('#bulk-transfer-status').html(
      "<div class='df ai-center py-sm px-md bg-info my-md'><span class='txt-lg white'><span class='fal fa-exclamation-triangle mr-md'></span></span><span class='f-1 white'>Transferring may take a few minutes to complete</span></div>"
    )
    $('#bulkRegistrationTransferButton').attr('disabled', 'disabled')
    weaved.transferBulkRegistration(bulkRegistrationID, newOwner)
  }
}

function productDeleteDone() {
  if ($('#confirmProductDelete').prop('checked') == false) {
    $('#product-delete-status').html('You must select the checkbox to confirm you want to delete')
  } else {
    startSpinner()

    $('#product-delete-status').html('Deleting may take a few minutes to complete')

    $('#productDeleteButton').attr('disabled', 'disabled')
    weaved.deleteProduct(productID)
  }
}

function projectDeleteDone() {
  if ($('#confirmProjectDelete').prop('checked') == false) {
    $('#project-delete-status').html('You must select the checkbox to confirm you want to delete')
  } else {
    weaved.deleteProject(projectID)
  }
}

function bulkRegistrationDeviceDeleteDone() {
  if ($('#confirmRegistrationDeviceDelete').prop('checked') == false) {
    $('#bulk-device-delete-status').html('You must select the checkbox to confirm you want to delete')
  } else {
    //alert("final Delete:"+bulkRegistrationID+":"+bulkRegistrationState);
    //return;

    //startSpinner();

    if (bulkRegistrationDeviceState != 'preregistered') {
      //alert("do early device un-registration");

      weaved.finalDeleteBulkRegistration(bulkRegistrationID)
    } else {
      $('#bulk-device-delete-status').html('Deleting may take a few minutes to complete')
      $('#bulkRegistrationPublishButton').attr('disabled', 'disabled')

      //alert("do full device un-registration");

      weaved.deleteBulkRegistration(bulkRegistrationID)
    }
  }
}

function doOpenRegistrationSettingsDone() {
  // did anything change
  //alert("save Open reg setting:"+bulkRegistrationID);

  allow = $('#allowAutoRegistration').is(':checked') ? 'on' : 'off'
  locked = $('#lockOpenRegistration').is(':checked') ? 'on' : 'off'

  if ($('#showChangeProductCheckbox').is(':checked')) {
    mine = $('#myProductSelectionList2 option:selected').html()
    //product = (mine.indexOf("private product") == -1) ? $('#myProductSelectionList2').val() : "";

    public = $('#publicProductSelectionList2 option:selected').html()
    mine = $('#myProductSelectionList2 option:selected').html()
    code = $('#privateProductCode2').val()

    product = public.indexOf('public product') == -1 ? $('#publicProductSelectionList2').val() : ''
    product = mine.indexOf('private product') == -1 ? $('#myProductSelectionList2').val() : product
    product = code.length > 0 ? code : product

    pub_selected = public.indexOf('public product') == -1 ? 1 : 0
    min_selected = mine.indexOf('private product') == -1 ? 1 : 0
    code_entered = code.length > 0 ? 1 : 0

    total = pub_selected + min_selected + code_entered
  } else {
    product = '' // use already assigned product
    total = 1
  }

  if (total != 1) {
    $('#open-registration-settings-status').html('You must select a single Product to continue with Bulk Registration')
  } else {
    $('#open-registration-settings-submit-button').attr('disabled', 'true')

    weaved.updateOpenRegistrationSettings(bulkRegistrationID, allow, locked, product)
  }
}

function doProductSettingsDone() {
  // did anything change
  //alert("save Open reg setting:"+bulkRegistrationID);

  locked = $('#lockProduct').is(':checked') ? 'on' : 'off'

  //alert(locked);

  weaved.updateProductSettings(productID, locked)
}

function doAcceptInvitation() {
  startSpinner()

  $('#invitation-accept-button').attr('disabled', 'disabled')

  return weaved.xdr(
    '/user/invitation/accept/',
    'POST',
    {
      id: invitationGUID,
    },
    function() {
      stopSpinner()
      $('#Invitation-Modal').modal('hide')
    },
    function() {
      stopSpinner()
      alert('Accept invitation received with some errors.')
    }
  )
}

function doBulkDeviceTransfer() {
  devices = rState.selectedServiceList.join()

  if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) {
    ok = true
  } else {
    alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    return
  }
  if (window.remot3it.store.getState().contacts.selected.length === 0) ok = false

  if (ok == false) {
    $('#bulk-transfer-device-status').html(
      "<div class='df ai-center py-sm px-md bg-danger my-md'><span class='txt-lg white'><span class='fal fa-exclamation-triangle mr-md'></span></span><span class='f-1 white'>Please select a contact to continue</span></div>"
    )
  } else {
    if ($('#confirmDeviceTransfer').prop('checked') == false) {
      $('#bulk-transfer-device-status').html(
        "<div class='df ai-center py-sm px-md bg-danger my-md'><span class='txt-lg white'><span class='fal fa-exclamation-triangle mr-md'></span></span><span class='f-1 white'>You must select the checkbox to confirm you want to transfer</span></div>"
      )
      return
    }

    $('#bulk-transfer-device-button').attr('disabled', 'disabled')

    devices = rState.selectedServiceList.join()

    newOwner = window.remot3it.store.getState().contacts.selected.value
  }

  //weaved.setUserPreference("bulkShareEmails",sharedEmailSettings);

  var options = {
    devices: devices,
    newuser: newOwner,
    emails: newOwner,
  }

  startSpinner()

  return weaved.xdr(
    '/developer/devices/transfer/start/',
    'POST',
    {
      queue_name: window.TASK_QUEUE_NAME || 'WeavedTaskQueue',
      label: 'transferring',
      options: JSON.stringify(options),
    },
    function() {
      stopSpinner()
      $('#bulk-transfer-device-button').removeAttr('disabled')

      $('#Bulk-Transfer-Device-Modal').hide()
      $('#Transfer-Confirmation-Modal').modal('show')

      $('#bulk-transfer-fail').hide()
      $('#bulk-transfer-fail').html('')
      unselectSelectedRows()
    },
    function(xhr) {
      var message = JSON.parse(xhr)
      var error = message.reason
      console.error(error)
      var regex = /^no user:/

      if (regex.test(error)) {
        $('#transfer-failure-error').html(
          'The selected contact has not confirmed their account. You may only transfer to contacts which have a confirmed account.'
        )
      } else {
        $('#transfer-failure-error').html('Oops, ' + error)
      }

      stopSpinner()
      $('#bulk-transfer-device-button').removeAttr('disabled')
      $('#Bulk-Transfer-Device-Modal').hide()
      $('#Transfer-Failure-Modal').modal('show')
    }
  )
}

function doDeviceDelete() {
  if ($('#confirmDeviceDelete').prop('checked') == false) {
    $('#device-delete-status').html('You must select the checkbox to confirm you want to delete')
  } else {
    ok = true

    var currentDeviceId = window.remot3it.store.getState().devices.currentDeviceId
    if (
      (countSelectedDevices() > 0 && countSelectedDevices() < MAX_DELETE + 1) ||
      typeof currentDeviceId !== 'undefined'
    ) {
      ok = true
    } else {
      alert('Select 1 to ' + MAX_DELETE + ' devices to continue.')
      return
    }

    // check for deleting shared devices
    var shareCheck = DEVICE_LIST.find('input[type=checkbox]').each(function() {
      var rowid = $(this)
        .parents('tr:last')
        .attr('id')
      if ($(this).is(':checked') || rowid === currentDeviceId) {
        row = DEVICE_LIST.getRowData(rowid)

        // check device state
        device = rState.getDevice(row.Mac)

        rowString = JSON.stringify(row)

        shared = rowString.indexOf('share-from')
        if (shared > -1) {
          // delete the developer device
          //console.log("unregister developer device:"+row.Mac.toLowerCase());
          $('#device-delete-status').html('You can not delete shared devices from other users.')

          $('#confirmDeviceDelete').prop('checked', false)
          $('#delete-device-progress').hide()
          $('#device-delete-button').removeAttr('disabled')

          ok = false
        } else if (device.Status != 'inactive') {
          // delete the developer device
          //console.log("unregister developer device:"+row.Mac.toLowerCase());
          $('#device-delete-status').html('You can not delete active devices.')

          $('#confirmDeviceDelete').prop('checked', false)
          $('#delete-device-progress').hide()
          $('#device-delete-button').removeAttr('disabled')

          ok = false
        }
      }
    })

    if (shareCheck == false || ok == false) return

    if (!deviceListDisabled) {
      $('#device-delete-button').removeAttr('disabled')
      $('#delete-device-progress').hide()
      $('#Delete-Device-Modal').modal('hide')
    }

    deleteRows = countSelectedDevices()
    deletedRows = 0

    // loop through selected devices
    body = new Map()
    devices = new Array()
    selected = DEVICE_LIST.find('input[type=checkbox]')

    selected.each(function() {
      var rowid = $(this)
        .parents('tr:last')
        .attr('id')
      if ($(this).is(':checked') || rowid === currentDeviceId) {
        row = DEVICE_LIST.getRowData(rowid)
        // console.log('List length' + deleteRows)
        if (deleteRows <= 5) {
          weaved.unregisterDeveloperDevice(row.Mac.toLowerCase())
          DEVICE_LIST.jqGrid('delRowData', row.Mac.toUpperCase())
        } else {
          devices.push(row.Mac.toLowerCase())
        }

        if (rowid === currentDeviceId) deleteRows = 1

        window.remot3it.helpers.track('deviceRemoved', {
          deviceID: rowid,
          deviceName: row.Service,
        })
      }
    })
    if (deleteRows > 5) {
      body['devices'] = devices
      weaved.unregisterDeveloperDevices(body)
    }
  }
}

// TODO:
function doBulkDeviceShare() {
  ok = true

  if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SHARE + 1) {
    ok = true
  } else {
    alert('Select 1 to ' + MAX_SHARE + ' devices to continue.')
    return
  }

  if (window.remot3it.store.getState().contacts.selected.length === 0) ok = false

  if (ok == false) {
    $('.bulk-share-fail').html(
      "<div class='df ai-center py-sm px-md bg-danger my-md'><span class='txt-lg white'><span class='fal fa-exclamation-triangle mr-md'></span></span><span class='f-1 white'>Please select a contact to continue</span></div>"
    )
  } else {
    // TODO:
    $('#bulk-share-device-button').attr('disabled', 'disabled')

    state = bulkShareAction
    //check for newShares contacts
    newShares = window.remot3it.store.getState().contacts.selected.map(function(selection) {
      return selection.value
    })

    scripting = state === 'on' ? window.remot3it.store.getState().shares.selectedScripting : false

    sharedEmailSettings = newShares.toString()

    weaved.setUserPreference('bulkShareEmails', sharedEmailSettings)

    devices = rState.selectedServiceList.join()

    weaved.shareDeveloperDevices(devices, sharedEmailSettings, state, scripting)
  }
}

function doDeviceShare() {
  $('#share-fail').html('')
  $('#share-progress-status').show()
  $('#share-device-button').attr('disabled', 'disabled')

  address = shareDeviceAddress

  shareActionCount =
    $('#shareEmails')
      .val()
      .split(',').length + $('#delete-share-users option:selected').length

  $('#delete-share-users option:selected').each(function() {
    var $this = $(this)
    if ($this.length) {
      var selText = $this.text()
      weaved.shareDeveloperDevice(address, selText, 'off', true)
    }
  })

  newShares = $('#shareEmails')
    .val()
    .split(',')
}

function doRemoveDeviceShare() {
  if ($('#confirmRemoveShare').prop('checked') == false) {
    $('#remove-share-fail').html('You must select the checkbox to confirm you want to remove sharing')
  } else {
    $('#remove-share-progress-status').show()
    $('#remove-share-device-button').attr('disabled', 'disabled')
    $('#remove-share-fail').hide()
    $('#remove-share-fail').html('')

    address = shareDeviceAddress

    weaved.shareDeveloperDevice(address, getCookie('remoteit.email'), 'off', true)
    console.log('remove share developer device:' + address + ' state:off')
  }
}

function setCategories() {
  if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) {
    ok = true
  } else {
    alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    return
  }

  startSpinner()

  bulkOperationCount = countSelectedDevices()
  bulkOperationDone = 0

  $('#set-category-progress-bar')
    .css('width', bulkOperationDone + '%')
    .attr('aria-valuemin', bulkOperationDone)
  $('#set-category-progress-bar')
    .css('width', bulkOperationDone + '%')
    .attr('aria-valuemax', bulkOperationCount)

  $('#set-category-progress-div').show()
  $('#set-category-button').attr('disabled', 'disabled')

  // loop through selected devices
  DEVICE_LIST.find('input[type=checkbox]').each(function() {
    var rowid = $(this)
      .parents('tr:last')
      .attr('id')
    if ($(this).is(':checked')) {
      row = DEVICE_LIST.getRowData(rowid)

      var isCategoryAValid = true
      var isCategoryBValid = true
      var isCategoryCValid = true
      categoryA = '-'
      if ($('#useCategoryA').prop('checked')) {
        categoryA = checkEncodedChar($('#categoryA').val())
        if (!isValidName(categoryA)) {
          alert(
            'Please enter a valid Category A. Category A names must only contain alphanumeric, -, _ and space characters'
          )
          isCategoryAValid = false
        } else {
          isCategoryAValid = true
        }
      } else if (row.CategoryA.length > 0) {
        categoryA = row.CategoryA
      }
      categoryB = '-'
      if ($('#useCategoryB').prop('checked')) {
        categoryB = checkEncodedChar($('#categoryB').val())
        if (!isValidName(categoryB)) {
          alert(
            'Please enter a valid Category B. Category B names must only contain alphanumeric, -, _ and space characters'
          )
          isCategoryBValid = false
        } else {
          isCategoryBValid = true
        }
      } else if (row.CategoryB.length > 0) {
        categoryB = row.CategoryB
      }
      categoryC = '-'
      if ($('#useCategoryC').prop('checked')) {
        categoryC = checkEncodedChar($('#categoryC').val())
        if (!isValidName(categoryC)) {
          alert(
            'Please enter a valid Category C. Category C names must only contain alphanumeric, -, _ and space characters'
          )
          isCategoryCValid = false
        } else {
          isCategoryCValid = true
        }
      } else if (row.CategoryC.length > 0) {
        categoryC = row.CategoryC
      }
      categoryD = '-'
      categoryE = '-'

      if (isCategoryAValid && isCategoryBValid && isCategoryCValid) {
        weaved.setCategory(row.Mac, categoryA, categoryB, categoryC, categoryD, categoryE)
        device = rState.getDevice(row.Mac)
        device.CategoryA = categoryA
        device.CategoryB = categoryB
        device.CategoryC = categoryC
        device.CategoryD = categoryD
        device.CategoryE = categoryE
        rState.setDevice(row.Mac, device)

        // update the row
        DEVICE_LIST.jqGrid('setRowData', rowid, {
          CategoryA: checkEncodedChar(categoryA),
          CategoryB: checkEncodedChar(categoryB),
          CategoryC: checkEncodedChar(categoryC),
          CategoryD: checkEncodedChar(categoryD),
          CategoryE: checkEncodedChar(categoryE),
        })

        DEVICE_LIST.jqGrid('saveRow', rowid, false)

        // update progress
        bulkOperationDone = bulkOperationDone + 1
        bulkPercentDone = Math.floor((bulkOperationDone / bulkOperationCount) * 100)

        $('#set-category-progress-bar')
          .css('width', bulkPercentDone + '%')
          .attr('aria-valuenow', bulkPercentDone)
        $('#set-category-progress-bar').html(bulkPercentDone + '%')

        $('#Set-Category-Modal').hide()
      }
    }
  })
  DEVICE_LIST.trigger('reloadGrid')
  $('#categoryA').val('')
  $('#categoryB').val('')
  $('#categoryC').val('')

  $('#set-category-button').removeAttr('disabled')
  $('#set-category-progress-div').hide()

  stopSpinner()

  return true
}

function clearStatusColumns() {
  if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) {
    ok = true
  } else {
    alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    return
  }

  startSpinner()

  bulkOperationCount = countSelectedDevices()
  bulkOperationDone = 0

  $('#clear-status-progress-bar')
    .css('width', bulkOperationDone + '%')
    .attr('aria-valuemin', bulkOperationDone)
  $('#clear-status-progress-bar')
    .css('width', bulkOperationDone + '%')
    .attr('aria-valuemax', bulkOperationCount)

  $('#clear-status-progress-div').show()
  $('#clear-status-button').attr('disabled', 'disabled')

  // loop through selected devices
  DEVICE_LIST.find('input[type=checkbox]').each(function() {
    var rowid = $(this)
      .parents('tr:last')
      .attr('id')
    if ($(this).is(':checked')) {
      row = DEVICE_LIST.getRowData(rowid)

      if ($('#clearStatusA').prop('checked')) {
        statusA = ' '
      } else {
        statusA = row.StatusA
      }
      if ($('#clearStatusB').prop('checked')) {
        statusB = ' '
      } else {
        statusB = row.StatusB
      }
      if ($('#clearStatusC').prop('checked')) {
        statusC = ' '
      } else {
        statusC = row.StatusC
      }
      if ($('#clearStatusD').prop('checked')) {
        statusD = ' '
      } else {
        statusD = row.StatusD
      }
      if ($('#clearStatusE').prop('checked')) {
        statusE = ' '
      } else {
        statusE = row.StatusE
      }

      if (weaved.setDeviceStatus(row.Mac, statusA, statusB, statusC, statusD, statusE)) {
        device = rState.getDevice(row.Mac)
        device.StatusA = statusA
        device.StatusB = statusB
        device.statusC = statusC
        device.StatusD = statusD
        device.StatusE = statusE

        rState.setDevice(row.Mac, device)

        // update the row
        DEVICE_LIST.jqGrid('setRowData', rowid, {
          StatusA: statusA,
          StatusB: statusB,
          StatusC: statusC,
          StatusD: statusD,
          StatusE: statusE,
        })

        DEVICE_LIST.jqGrid('saveRow', rowid, false)

        // update progress
        bulkOperationDone = bulkOperationDone + 1
        bulkPercentDone = Math.floor((bulkOperationDone / bulkOperationCount) * 100)

        $('#clear-status-progress-bar')
          .css('width', bulkPercentDone + '%')
          .attr('aria-valuenow', bulkPercentDone)
        $('#clear-status-progress-bar').html(bulkPercentDone + '%')
      }
    }
  })

  $('#clear-status-button').removeAttr('disabled')
  $('#clear-status-progress-div').hide()
  $('#Clear-Status-Modal').hide()

  DEVICE_LIST.trigger('reloadGrid')

  stopSpinner()

  return true
}

function renderScriptingStatus(jobId, status, primaryClass) {
  currentJob = listJobs.find(function search(job) {
    return job.id === jobId
  })
  if (currentJob !== undefined) {
    new_requested_date = new Date(Date.parse(currentJob.requested))
    var spanStatus = ''

    obj = JSON.parse(message)
    var succeeded = true
    var currentState = ''

    if (obj !== undefined && obj.jobs !== undefined) {
      var jobState = obj.jobs.find(function(job) {
        return job.id === jobId && job.state !== 'done'
      })

      if (jobState) {
        succeeded = false
        currentState = jobState.state
      }

      if (succeeded === false) status = currentState

      switch (status) {
        case 'done': {
          spanStatus = '<span class="fal fa-check fa-fw success"></span> <span class="gray ml-sm">Succeeded</span>'
          break
        }
        case 'running': {
          spanStatus =
            '<span class="fal fa-spinner-third fa-spin fa-fw primary"></span> <span class="gray ml-sm">Running</span>'
          break
        }
        case 'new': {
          spanStatus =
            '<span class="fal fa-spinner-third fa-spin fa-fw primary"></span> <span class="gray ml-sm">Running</span>'
          break
        }
        default: {
          spanStatus = '<span class="fal fa-times fa-fw danger"></span> <span class="gray ml-sm">Failed</span>'
          break
        }
      }

      return (
        '<button class="btn btn-link p-none ' +
        primaryClass +
        '" title="Click for more info" onclick="showJobTasks(\'' +
        jobId +
        "','" +
        currentJob.script +
        "','" +
        new_requested_date.toISOString() +
        '\');">' +
        spanStatus +
        '</button>'
      )
    }
  }
}

function renderScriptingAction(jobId, status) {
  currentJob = listJobs.find(function search(job) {
    return job.id === jobId
  })
  new_requested_date = new Date(Date.parse(currentJob.requested))
  switch (status) {
    case 'done': {
      return (
        '<button onclick="showJobActionModal(\'' +
        jobId +
        "','" +
        currentJob.script +
        "','" +
        currentJob.requested +
        '\',\'Delete\');" class="btn btn-link px-xs py-none danger c-pointer"><i class="fal fa-trash-alt fa-fw" title="Delete" align="center"></i></button>'
      )
    }
    case 'running': {
      return (
        '<button onclick="showJobActionModal(\'' +
        jobId +
        "','" +
        currentJob.script +
        "','" +
        new_requested_date.toISOString() +
        '\',\'Cancel\');"  class="btn btn-link danger px-xs py-none c-pointer"><i class="fal fa-times-octagon fa-fw"  title="Cancel" align="center"></i></button>'
      )
    }
    case 'new': {
      return (
        '<button onclick="showJobActionModal(\'' +
        jobId +
        "','" +
        currentJob.script +
        "','" +
        new_requested_date.toISOString() +
        '\',\'Cancel\');" class="btn btn-link px-xs py-none danger c-pointer"><i class="fal fa-times-octagon fa-fw"  title="Cancel" align="center"></i></button>'
      )
    }
    default: {
      return (
        '<button onclick="showJobActionModal(\'' +
        jobId +
        "','" +
        currentJob.script +
        "','" +
        currentJob.requested +
        '\',\'Delete\');" class="btn btn-link px-xs py-none danger c-pointer"><i class="fal fa-trash-alt fa-fw" title="Delete" align="center"></i></button>'
      )
    }
  }
}

function closeModalDeviceDelete() {
  $('#Delete-Device-Modal').modal('hide')
  var currentDeviceId = window.remot3it.store.getState().devices.currentDeviceId

  if (currentDeviceId) {
    DEVICE_LIST.find('tbody tr').each(function() {
      if ($(this).attr('id') === currentDeviceId) {
        $(this).removeClass('success')
      }
    })
  }

  window.remot3it.store.dispatch.devices.setCurrentDeviceId(undefined)
}

function closeModalOnlineDeviceDelete() {
  $('#Delete-Online-Device-Modal').modal('hide')
  var currentDeviceId = window.remot3it.store.getState().devices.currentDeviceId

  if (currentDeviceId) {
    DEVICE_LIST.find('tbody tr').each(function() {
      if ($(this).attr('id') === currentDeviceId) {
        $(this).removeClass('success')
      }
    })
  }

  window.remot3it.store.dispatch.devices.setCurrentDeviceId(undefined)
}

function fetchProductData(id, tag) {
  if (id.length) {
    var filter = productsData.filter(function(i) {
      return i.id === id
    })

    if (filter.length) {
      if (tag === 'delete') showDeleteProductModal(filter[0].id, filter[0].name)
      if (tag === 'settings') showProductSettingsModal(filter[0].id, filter[0].name, filter[0].status)
      if (tag === 'provision') doDownloadProductProvisioning(filter[0].id, filter[0].name, filter[0].status)
      if (tag === 'product-usage') showProductUsageModal(filter[0].id, filter[0].name)
    }
  }
}
