var base_data = {
  title: 'remote.it',
  welcome: 'Welcome to remote.it',
  home_banner_1: 'Remote Manage Networked<br> Devices Anywhere',
  home_banner_2: 'Remote Device Access and Bulk Management',
  devices: 'devices',
  commerce: 'yes',
  products: 'ARM Standard Kit,CAP 2.0',
  links: [
    {
      title: 'Read Getting Started',
      url: 'https://docs.remote.it/getting-started',
    },
    {
      title: 'Learn how to run a script on your device',
      url:
        'https://docs.remote.it/guides/device-scripting-running-scripts-on-your-devices',
    },
    {
      title: 'Debugging and support',
      url: 'http://support.remote.it',
    },
  ],
}

var cap_data = {
  title: 'cap.remote.it',
  welcome: 'Welcome to CAP',
  home_banner_1: 'Remote Manage Networked<br> CAPs Anywhere',
  home_banner_2: 'Remote CAP Access and Bulk Management',
  devices: 'Content Access Point',
  commerce: 'yes',
  products: 'CAP 2.0',
  links: [
    {
      title: 'Read product information about your CAP',
      url:
        'http://www.intel.com/content/www/us/en/education/products/content-access-point.html',
    },
    {
      title: 'How to bulk register CAP devices',
      url:
        'http://forum.weaved.com/t/remot3-it-register-devices-a-k-a-bulk-registration/1131',
    },
  ],
}

var s_data = {
  title: 's.remote.it',
  welcome: 'Sofutobanku e yōkoso',
  home_banner_1: 'リモート管理ネットワーク<br> どこからでもデバイス',
  home_banner_2: 'リモートデバイスアクセスと一括管理',
  main_logo:
    'https://s3.eu-central-1.amazonaws.com/remot3.it-logos/softbank_logo.jpg',
  devices: 'Mobile Devices',
  commerce: 'no',
  products: 'ARM Standard Kit,CAP 2.0',
  links: [
    {
      title: 'Read Getting Started',
      url: 'https://docs.remote.it/getting-started',
    },
    {
      title: 'Learn how to run a script on your device',
      url:
        'https://docs.remote.it/guides/device-scripting-running-scripts-on-your-devices',
    },
    {
      title: 'Debugging and support',
      url: 'http://support.remote.it',
    },
  ],
}

var data = { 'remot3.it': base_data, www: base_data, cap: cap_data, s: s_data }

var defaultDomain = 'www'

function R3Subdomains() {
  if (typeof jQuery === 'undefined') {
    throw new Error('Weaved JavaScript requires jQuery')
  }

  this.getSubdomain = function() {
    try {
      // get the subdomain from the URL
      subdomain = window.location.hostname.split('.')[0]

      if (subdomain.length > 0) {
        return subdomain
      }
    } catch (err) {}

    return defaultDomain
  }

  this.getSubdomainData = function() {
    try {
      // get the subdomain from the URL
      subdomain = window.location.hostname.split('.')[0]
      domain = data[subdomain]

      // return domain data
      if (domain.title) {
        return data[subdomain]
      }
    } catch (err) {
      //alert(err);
    }

    return data[defaultDomain]
  }
}
