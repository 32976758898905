function r3_device(
  id,
  name,
  address,
  owner,
  shared,
  scripting,
  internalIP,
  deviceType,
  externalIP,
  serviceTitle,
  state,
  lastDate,
  lastTime,
  createDate,
  createTime,
  catA,
  catB,
  catC,
  catD,
  catE,
  statusA,
  statusB,
  statusC,
  statusD,
  statusE,
  version,
  hw_id,
  license
) {
  this.id = id
  this.service = name
  this.ServiceName = name
  this.Address = address
  this.IP = internalIP
  this.ServiceType = deviceType
  this.IPext = externalIP
  this.Type = serviceTitle
  this.Status = state
  this.Owner = owner
  this.Shared = shared
  this.Scripting = scripting
  ;(this.Version = version), (this.HWID = hw_id), (this.CatA = catA)
  this.CatB = catB
  this.CatC = catC
  this.Cat4 = catD
  this.Cat5 = catE
  this.StatusA = statusA
  this.StatusB = statusB
  this.StatusC = statusC
  this.StatusD = statusD
  this.StatusE = statusE
  this.Connected = '0'
  this.settings = '1'
  this.ConnectIP = ''
  this.Portid = ''
  this.LastOnlineDate = lastDate
  this.LastOnlineTime = lastTime
  this.CreateDate = createDate
  this.CreateTime = createTime
  this.license = license
}

function r3_service(
  id,
  name,
  address,
  internalIP,
  deviceType,
  externalIP,
  serviceTitle,
  state,
  lastDate,
  lastTime,
  createDate,
  createTime
) {
  this.id = id
  this.service = name
  this.ServiceName = name
  this.Address = address
  this.IP = internalIP
  this.ServiceType = deviceType
  this.IPext = externalIP
  this.Type = serviceTitle
  this.Status = state
  this.Connected = '0'
  this.settings = '1'
  this.ConnectIP = ''
  this.Portid = ''
  this.LastOnlineDate = lastDate
  this.LastOnlineTime = lastTime
  this.CreateDate = createDate
  this.CreateTime = createTime
}

function Remot3itState() {
  if (typeof jQuery === 'undefined') {
    throw new Error('Remot3.it JavaScript requires jQuery')
  }

  this.deviceList = new rHashTable()
  this.serviceList = new rHashTable()
  this.connectionList = new rHashTable()
  this.virtualDeviceList = new rHashTable()

  this.resetDeviceList = function() {
    this.deviceList = new rHashTable()
  }

  this.resetServiceList = function() {
    this.serviceList = new rHashTable()
  }

  this.resetConnectionList = function() {
    this.connectionList = new rHashTable()
  }

  this.resetVirtualDeviceList = function() {
    this.virtualDeviceList = new rHashTable()
  }

  this.setDevice = function(id, item) {
    try {
      this.deviceList.setItem(id, item)
    } catch (err) {}
    return true
  }

  this.setService = function(id, item) {
    try {
      this.serviceList.setItem(id, item)
    } catch (err) {
      return false
    }
    return true
  }

  this.setVirtualDevice = function(id, item) {
    try {
      this.virtualDeviceList.setItem(id, item)
    } catch (err) {
      return false
    }
    return true
  }

  this.getService = function(id) {
    try {
      //console.log("service list: " + JSON.stringify(this.serviceList));
      return this.serviceList.getItem(id.toUpperCase())
    } catch (err) {
      return false
    }
  }

  this.getDevice = function(id) {
    try {
      return this.deviceList.getItem(id.toUpperCase())
    } catch (err) {
      return false
    }
  }

  this.getVirtualDevice = function(id) {
    try {
      return this.virtualDeviceList.getItem(id)
    } catch (err) {
      return false
    }
  }

  this.getServiceIDlist = function() {
    try {
      return this.serviceList.keys()
    } catch (err) {
      return false
    }
  }

  this.getDeviceIDList = function() {
    try {
      return this.deviceList.keys()
    } catch (err) {
      return false
    }
  }

  this.getVirtualDeviceIDList = function() {
    try {
      return this.virtualDeviceList.keys()
    } catch (err) {
      return false
    }
  }
}

function rHashTable() {
  this.length = 0
  this.items = {}

  this.setItem = function(key, value) {
    var previous = undefined
    if (this.hasItem(key)) {
      previous = this.items[key]
    } else {
      this.length++
    }
    this.items[key] = value
    return previous
  }

  this.getItem = function(key) {
    return this.hasItem(key) ? this.items[key] : {}
  }

  this.hasItem = function(key) {
    return this.items.hasOwnProperty(key)
  }

  this.removeItem = function(key) {
    if (this.hasItem(key)) {
      previous = this.items[key]
      this.length--
      delete this.items[key]
      return previous
    } else {
      return undefined
    }
  }

  this.keys = function() {
    var keys = []
    for (var k in this.items) {
      if (this.hasItem(k)) {
        keys.push(k)
      }
    }
    return keys
  }

  this.values = function() {
    var values = []
    for (var k in this.items) {
      if (this.hasItem(k)) {
        values.push(this.items[k])
      }
    }
    return values
  }

  this.each = function(fn) {
    for (var k in this.items) {
      if (this.hasItem(k)) {
        fn(k, this.items[k])
      }
    }
  }

  this.clear = function() {
    this.items = {}
    this.length = 0
  }
}
