var TASK_QUEUE_NAME = window.TASK_QUEUE_NAME
var DEFAULT_OPTIONS = '{}'
var BASE_URL = window.API_URL

function WeavedAPI(callback) {
  if (typeof jQuery === 'undefined') {
    throw new Error('remote.it JavaScript requires jQuery')
  }

  this.callback = callback
  this.LOGOUT_EVENT = 'logout'
  this.CONNECT_EVENT = 'connect'
  this.SSH_CONNECT_EVENT = 'ssh_connect'
  this.HTTP_CONNECT_EVENT = 'http_connect'
  this.TCP_CONNECT_EVENT = 'tcp_connect'
  this.DISCONNECT_EVENT = 'disconnect'
  this.UPLOAD_EVENT = 'upload'
  this.SEND_COMMAND_EVENT = 'sendcommand'
  this.GET_SCRIPT_HEADERS_EVENT = 'scriptheaders'
  this.LOAD_SCRIPT_EXAMPLES_EVENT = 'scriptexamples'

  this.BULK_TASK_UPDATE_EVENT = 'task_update'
  this.BULK_TASK_DONE_EVENT = 'task_done'
  this.BULK_TASK_FAILED_EVENT = 'task_failed'

  this.BULK_UPLOAD_EVENT = 'bulk_upload'

  this.JOB_CANCEL_EVENT = 'job_cancel'
  this.JOB_DELETE_EVENT = 'job_delete'
  this.JOB_DONE_EVENT = 'job_done'
  this.JOB_STARTED_EVENT = 'job_started'
  this.JOB_FAILED_EVENT = 'job_failed'

  this.DEVICE_NAME_EVENT = 'name_device'
  this.DEVICE_DELETE_EVENT = 'delete_device'
  this.DEVELOPER_DEVICE_DELETE_EVENT = 'delete_developer_device'
  this.DEVELOPER_DEVICE_UNREGISTER_EVENT = 'unregister_developer_device'
  this.DEVELOPER_DEVICES_UNREGISTER_EVENT = 'unregister_developer_devices'

  this.SET_PREFERENCE_EVENT = 'set_preference'

  this.GET_SCRIPT_REPORT_EVENT = 'get_script_report'

  this.BULK_SERVICE_STATUS_A_EVENT = 'device_status_a'
  this.BULK_SERVICE_STATUS_B_EVENT = 'device_status_b'
  this.BULK_SERVICE_STATUS_C_EVENT = 'device_status_c'
  this.BULK_SERVICE_STATUS_D_EVENT = 'device_status_d'
  this.BULK_SERVICE_STATUS_E_EVENT = 'device_status_e'

  this.SET_CATEGORY_EVENT = 'setcategory'
  this.SET_STATUS_EVENT = 'setstatus'

  this.ACKNOWLEDGE_ANNOUNCEMENT_EVENT = 'ack_announcement'

  this.PROJECT_SUPPORTED_PROJECT_TYPES_EVENT = 'supported_project_types'

  this.BULK_JOB_LIST_EVENT = 'bulk_jobs'
  this.BULK_JOB_TASK_LIST_EVENT = 'bulk_job_tasks'
  this.BULK_REGISTRATION_LIST_EVENT = 'bulk_registrations'
  this.BULK_REGISTRATION_DEVICES_EVENT = 'bulk_registration_devices'
  this.BULK_PRODUCT_LIST_EVENT = 'bulk_products'
  this.BULK_REGISTRATION_CREATE_EVENT = 'bulk_registration_create'
  this.BULK_REGISTRATION_PUBLISH_EVENT = 'bulk_registration_publish'
  this.BULK_REGISTRATION_DELETE_EVENT = 'bulk_registration_set_delete'
  this.BULK_REGISTRATION_TRANSFER_EVENT = 'bulk_registration_set_transfer'
  this.BULK_REGISTRATION_FINAL_DELETE_EVENT = 'bulk_registration_final_delete'
  this.BULK_REGISTRATION_PROVISIONING_EVENT = 'bulk_registration_provisioning'

  this.BULK_SERVICE_CONFIGURATION_EVENT = 'bulk_service_configuration'
  this.SET_BULK_SERVICE_CONFIGURATION_EVENT = 'set_bulk_service_configuration'

  this.BULK_REGISTRATION_DELETED_TASK_EVENT = 'bulk_registration_deleted_task'
  this.BULK_REGISTRATION_PUBLISHED_TASK_EVENT =
    'bulk_registration_published_task'
  this.BULK_REGISTRATION_TRANSFER_TASK_EVENT = 'bulk_registration_transfer_task'

  this.BULK_PROJECT_LIST_EVENT = 'bulk_project_list'
  this.BULK_ADD_PROJECT_EVENT = 'bulk_add_project'
  this.BULK_REGISTRATION_OPEN_SETTINGS_EVENT = 'bulk_open_settings'
  this.BULK_PROJECT_ID_EVENT = 'bulk_project_id'

  this.DEVELOPER_PRODUCT_LIST_EVENT = 'developer_products'
  this.BULK_PRODUCT_CREATE_EVENT = 'create_product'
  this.DELETE_PRODUCT_EVENT = 'delete_product'
  this.PRODUCT_PROJECT_LIST_EVENT = 'product_project_list'
  this.PRODUCT_SETTINGS_EVENT = 'product_settings'
  this.PRODUCT_ADD_PROJECT_EVENT = 'product_add_project'
  this.PRODUCT_DELETE_PROJECT_EVENT = 'product_delete_project'
  this.PRODUCT_USAGE_LIST_EVENT = 'product_usage_list'

  this.DEVELOPER_PRODUCT_PRIVATE_LIST_EVENT = 'private_product_list'
  this.DEVELOPER_PRODUCT_PUBLIC_LIST_EVENT = 'public_product_list'
  this.DEVELOPER_PRODUCT_AVAILABLE_OPEN_LIST_EVENT =
    'available_open_product_list'

  this.VERIFY_EMAIL_EVENT = 'verify_email'
  this.VERIFIED_EMAIL_EVENT = 'verified_email'

  this.BULK_SHARE_EVENT = 'bulk_share'

  this.DEVELOPER_DEVICE_SHARE_EVENT = 'share_device'
  this.DEVELOPER_DEVICE_GET_SHARING_EVENT = 'get_developer_sharing_device'

  this.DEVICE_SHARE_INVITATION_EVENT = 'invitation_sharing'
  this.BULK_TRANSFER_INVITATION_EVENT = 'invitation_bulk_transfer'
  this.DEVICE_TRANSFER_INVITATION_EVENT = 'invitation_device_transfer'

  this.CUSTOMER_CREATED_EVENT = 'customer_created_notification'

  this.DEVELOPER_CUSTOMER_CREATE_EVENT = 'create_customer'
  this.DEVELOPER_CUSTOMER_ACCOUNT_CREATE_EVENT = 'create_customer_account'
  this.DEVELOPER_CUSTOMER_LIST_EVENT = 'customer_list'

  this.FILE_CONTENT = 'file'
  this.SCRIPT_CONTENT = 'script'

  var authHash = ''

  this.getAuthHash = function() {
    return authHash
  }

  this.startup = function() {
    var self = this

    return window.remot3it.store.dispatch.auth
      .checkSignIn()
      .then(
        function(user) {
          Promise.all([
            self.fetchDevices(),
            self.getContacts(),
            self.getBulkJobs(),
            self.getBulkRegistrations(),
            self.bulkScriptHeaders(),
            // FIXME: Must get project types before getting products
            // because of a stupid legacy dependency.
            // self.getSupportedProjectTypes().then(function() {
            //   return self.getProducts()
            // }),
            self.getSupportedProjectTypes(),
            self.getProducts(),
          ])

          // TODO: ugh, globals...
          window.remot3it.store.dispatch.auth
            .getUserData(true)
            .then(function(user) {
              window.accessKey = user.accessKey
              window.loginToken = user.token
              window.token = user.token
              window.email = user.email
              authHash = user.authHash
              window.username = user.username
              window.awsIdentity = user.awsIdentity
              window.user_guid = user.id
              window.pubsubChannel = user.subscription
              window.userid = user.username
              exampleCookie = getCookie('remoteit.examples.' + user.email)
              if (exampleCookie.length == 0) {
                self.loadScriptExamples()

                setCookie('remoteit.examples.' + user.email, 'yes', 365)
              }

              // Startup the router now that everything has loaded.
              if (window.remot3it && window.remot3it.router)
                window.remot3it.router.initialize()

              // Hide the loading screen.
              document.getElementById('initialize').remove()
            })
        },
        function(error) {
          console.error('user not signed in', error)
          window.location.href = '/auth/'
        }
      )
      .catch(function(error) {
        console.error('startup error:', error)
        try {
          airbrake.notify(error)
        } catch (e) {
          console.error('could not notify Airbrake:', e)
        }
      })
  }

  this.logout = function() {
    return window.remot3it.store.dispatch.auth.logout()
  }

  this.setUserPreference = function(name, preference) {
    window.remot3it.store.dispatch.auth.setPreference({
      key: name,
      value: preference,
    })
    return this.xdr('/user/preference', 'POST', { key: name, data: preference })
  }

  this.userCreateVerify = function(email) {
    var parameters = ''
    URL = BASE_URL + '/user/create/verify/'

    theRequest = { email: email }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.VERIFY_EMAIL_EVENT,
          status: 'true',
          message: 'ok',
          tag: email,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.VERIFY_EMAIL_EVENT,
          status: 'false',
          message: xhr,
          tag: email,
        }
        me.callback(e)
      }
    )

    return false
  }

  this.userCreateVerified = function(email, code, password) {
    var parameters = ''
    URL = BASE_URL + '/user/create/verified/'

    theRequest = { email: email, shortcode: code, password: password }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.VERIFIED_EMAIL_EVENT,
          status: 'true',
          message: 'ok',
          tag: email,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.VERIFIED_EMAIL_EVENT,
          status: 'false',
          message: xhr,
          tag: email,
        }
        me.callback(e)
      }
    )

    return false
  }

  this.disconnect = function(address) {
    this.stop(address)
  }

  this.connect = function(deviceaddress, type, devicealias) {
    var me = this

    var devicetype = parseInt(type.substring(3, 5), 16)

    return new Promise(function(resolve, reject) {
      $('#connection-error').remove()
      var parameters = ''
      URL = BASE_URL + '/device/connect/'
      loginRequest = {
        deviceaddress: deviceaddress,
        devicetype: devicetype,
        hostip: '255.255.255.255',
        wait: 'true',
      }

      // Find the disable IP checkbox to see what type of connection
      // ther user wants to establish.
      var disableIPRestriction = $('#disable-ip-restriction').is(':checked')
      if (disableIPRestriction) loginRequest.hostip = '0.0.0.0'

      // Isolate proxy connections. API will correctly return the proxy connections for the domain (i.e. RC-733 if the domain is not in the proxy_overrides, it will return the default proxies)
      var fullHostName = window.location.hostname
      loginRequest.isolate = 'domain=' + fullHostName

      parameters = JSON.stringify(loginRequest)

      me.xdr(
        URL,
        'POST',
        parameters,
        function(data) {
          //parse the JSON response
          var myData = JSON.parse(data, function(key, value) {
            return value
          })
          //Need to hardcode the handling of servicetypes (8,10,33) to go https
          if ([8, 10, 33].includes(devicetype)) {
            myData.connection.proxy = myData.connection.proxy.replace(
              'http:',
              'https:'
            )
            myData.connection.status = myData.connection.status.replace(
              'http:',
              'https:'
            )
          }
          if (devicetype === 4) {
            var l = document.createElement('a')
            l.href = myData.connection.proxy
            newUrl =
              'vnc://' +
              l.hostname +
              ':' +
              l.search
                .slice(1)
                .split('&')[0]
                .split('=')[1]
            myData.connection.proxy = newUrl
            myData.connection.status = newUrl
          }

          if (me.isWebContent(devicetype)) {
            if (
              devicetype !== 4 &&
              !window.open(myData.connection.proxy, deviceaddress)
            ) {
              e = {
                event: me.HTTP_CONNECT_EVENT,
                status: 'true',
                message: myData.connection.proxy,
                tag: 'blocked',
              }
              me.callback(e)

              //alert("You are now connected to your " + devicealias + " HTTP service at " + myData.connection.proxy + ". Your browser is blocking popups so please open a new windows & copy the URL to connect to this content." );
            } else {
              e = {
                event: me.HTTP_CONNECT_EVENT,
                status: 'true',
                message: myData.connection.proxy,
                tag: 'ok',
              }
              me.callback(e)
            }
          } else if (me.isSSHContent(devicetype)) {
            e = {
              event: me.SSH_CONNECT_EVENT,
              status: 'true',
              message: myData.connection.proxy,
              tag: deviceaddress,
            }
            me.callback(e)

            //alert("You are now connected to your " + devicealias + " SSH service at " + myData.connection.proxy + ". Use your SSH client to connect to this address." );
          } else {
            e = {
              event: me.TCP_CONNECT_EVENT,
              status: 'true',
              message: myData.connection.proxy,
              tag: deviceaddress,
              type: devicetype,
            }
            me.callback(e)

            //alert("You are now connected to your " + devicealias + " TCP service at " + myData.connection.proxy + ". Use your client to connect to this address." );
          }
          resolve()
        },
        function(xhr, error) {
          e = {
            event: me.CONNECT_EVENT,
            status: 'false',
            message: 'Connection failed',
            tag: deviceaddress,
          }

          //Check for p2p only error
          response = JSON.parse(xhr)
          if(response.code == 'P2P_ONLY') {
            window.location.href = 'remoteit://connect/' + deviceaddress
            $('#Device-Services-Modal').modal('hide')
            $('#device-connecting-status').hide()
            showAWSModal()
          }

          // Handle various error types
          var errorMessage = 'Connection failed'
          if(error) {
            if (error instanceof Error) errorMessage = error.message
            if (typeof error === 'string') errorMessage = error
          }

          window.remot3it.helpers.track('connectionFailed', {
            serviceID: deviceaddress,
            serviceName: devicealias,
            serviceType: type,
            error: errorMessage,
            connectionType: 'Proxy',
          })
          $('#Device-Services-Modal .modal-header').append(
            "<div id='connection-error' class='bg-danger p-md m-t'><div class='fw-bold mb-sm'><i class='fal fa-info-circle mr-sm' ></i >Failed to connect to the service " +
              devicealias +
              '.</div> If the device was not shutdown, an offline device or service may show as online for up to 15 minutes while our system determines it is not experiencing network issues.</div>'
          )
          $('#device-connecting-status').hide()
          weaved.fetchDevices(true)
          me.callback(e)
          reject(error)
        }
      )
    })
  }

  this.stop = function(deviceaddress) {
    var parameters = ''
    URL = BASE_URL + '/device/connect/stop'

    theRequest = {
      deviceaddress: deviceaddress,
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })
        e = {
          event: me.DISCONNECT_EVENT,
          status: 'true',
          message: 'Disconnect ok',
          tag: deviceaddress,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.DISCONNECT_EVENT,
          status: 'false',
          message: 'Disconnect failed',
          tag: deviceaddress,
        }
        me.callback(e)
      }
    )

    return false
  }

  this.getScriptReport = function(script) {
    var me = this
    this.xdr(
      BASE_URL + '/bulk/script/report/' + script,
      'GET',
      '',
      function(data) {
        e = {
          event: me.GET_SCRIPT_REPORT_EVENT,
          status: 'true',
          message: data,
          tag: script,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.GET_SCRIPT_REPORT_EVENT,
          status: 'false',
          message: 'Script report not available',
          tag: script,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.fetchDevices = function(refreshCache) {
    return window.remot3it.helpers.deviceSearch(refreshCache)
  }

  this.getContent = function(which) {
    var me = this
    this.xdr(
      BASE_URL + '/bulk/' + which + '/list/',
      'GET',
      '',
      function(data) {
        e = {
          event: me.CONTENT_EVENT,
          status: 'true',
          message: data,
          tag: which,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.CONTENT_EVENT,
          status: 'false',
          message: 'Content not available',
          tag: which,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.uploadContent = function(which, parameters, server_url) {
    URL = BASE_URL + '/bulk/' + which + '/upload'
    if (server_url.length > 0) {
      URL = BASE_URL + '/bulk/' + which + '/upload'
    }

    //alert("upload url:"+URL);

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.UPLOAD_EVENT,
          status: 'true',
          message: 'Upload done',
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        //alert("upload:"+JSON.stringify(xhr));
        //alert("upload:"+JSON.stringify(error));

        var myData = JSON.parse(xhr, function(key, value) {
          return value
        })

        e = {
          event: me.UPLOAD_EVENT,
          status: 'false',
          message: 'Upload failed',
          tag: myData.reason,
        }
        me.callback(e)
      }
    )

    return false
  }

  this.uploadBulkRegistration = function(parameters) {
    URL = BASE_URL + '/bulk/registration/upload'

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        // file upload worked but check for validation errors
        message = 'Upload done'
        if (myData.status == 'false') message = myData.reason

        e = {
          event: me.BULK_UPLOAD_EVENT,
          status: myData.status,
          message: message,
          tag: '',
        }
        me.callback(e)
      },
      function(error) {
        error = JSON.parse(error)
        e = {
          event: me.BULK_UPLOAD_EVENT,
          status: 'false',
          message: error.reason,
          tag: '',
        }
        me.callback(e)
      }
    )

    return false
  }

  this.sendCommand = function(deviceaddress, command, sc) {
    var parameters = ''
    URL = BASE_URL + '/device/send/'

    if (sc) {
      theRequest = {
        deviceaddress: deviceaddress,
        command: command,
        sc: 'server_channel',
      }
    } else {
      theRequest = {
        deviceaddress: deviceaddress,
        command: command,
      }
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.SEND_COMMAND_EVENT,
          status: 'true',
          message: 'ok,' + command,
          tag: deviceaddress,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.SEND_COMMAND_EVENT,
          status: 'false',
          message: 'failed,' + command,
          tag: deviceaddress,
        }
        me.callback(e)
      }
    )

    return false
  }

  this.bulkScriptHeaders = function() {
    var parameters = ''
    URL = BASE_URL + '/bulk/script/headers/'

    parameters = ''

    var me = this
    this.xdr(
      URL,
      'GET',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.GET_SCRIPT_HEADERS_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.GET_SCRIPT_HEADERS_EVENT,
          status: 'false',
          message: 'failed',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.bulkJobCancel = function(jobid) {
    var parameters = ''
    URL = BASE_URL + '/bulk/job/cancel/'

    theRequest = {
      queue_name: window.JOB_QUEUE_NAME,
      jobid: jobid,
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.JOB_CANCEL_EVENT,
          status: 'true',
          message: myData.status,
          tag: jobid,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.JOB_CANCEL_EVENT,
          status: 'false',
          message: 'failed',
          tag: jobid,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.bulkJobDelete = function(jobid) {
    var parameters = ''
    URL = BASE_URL + '/bulk/job/delete/'

    theRequest = {
      jobid: jobid,
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.JOB_DELETE_EVENT,
          status: 'true',
          message: myData.status,
          tag: jobid,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.JOB_DELETE_EVENT,
          status: 'false',
          message: 'failed',
          tag: jobid,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getBulkJobs = function() {
    var me = this
    this.xdr(
      BASE_URL + '/bulk/job/list/?cache=false',
      'GET',
      '',
      function(data) {
        e = {
          event: me.BULK_JOB_LIST_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_JOB_LIST_EVENT,
          status: 'false',
          message: 'Jobs not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getBulkJobTasks = function(id) {
    var me = this
    this.xdr(
      BASE_URL + '/bulk/job/task/list/job/' + id,
      'GET',
      '',
      function(data) {
        e = {
          event: me.BULK_JOB_TASK_LIST_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_JOB_TASK_LIST_EVENT,
          status: 'false',
          message: 'Job tasks not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getBulkRegistrations = function() {
    var self = this
    return this.request(
      '/bulk/registration/uploads/list/',
      'GET',
      '',
      function(data) {
        self.callback({
          event: self.BULK_REGISTRATION_LIST_EVENT,
          status: 'true',
          message: data,
          tag: '',
        })
      },
      function() {
        self.callback({
          event: self.BULK_REGISTRATION_LIST_EVENT,
          status: 'false',
          message: 'Registrations not available',
          tag: '',
        })
      }
    )
  }

  this.getBulkRegistrationDevices = function(bulkid) {
    var me = this
    this.xdr(
      BASE_URL + '/bulk/registration/list/' + bulkid,
      'GET',
      '',
      function(data) {
        e = {
          event: me.BULK_REGISTRATION_DEVICES_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_REGISTRATION_DEVICES_EVENT,
          status: 'false',
          message: 'Registrations not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getBulkRegistrationProvisioning = function(bulkid) {
    var me = this
    this.xdr(
      BASE_URL + '/bulk/register/provisioning/zip/' + bulkid,
      'GET',
      '',
      function(data) {
        e = {
          event: me.BULK_REGISTRATION_PROVISIONING_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_REGISTRATION_PROVISIONING_EVENT,
          status: 'false',
          message: 'Provisioning not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getContacts = function() {
    startSpinner()
    window.remot3it.store.dispatch.contacts.fetchStarted()

    var me = this
    this.xdr(
      BASE_URL + '/developer/customer/list/',
      'GET',
      '',
      function(data) {
        //stopSpinner()
        try {
          window.remot3it.store.dispatch.contacts.setFromLegacy(
            JSON.parse(data).contacts
          )
          window.remot3it.store.dispatch.contacts.fetchFinished()
        } catch (error) {
          console.error(error)
        }
        e = {
          event: me.DEVELOPER_CUSTOMER_LIST_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        //stopSpinner()
        window.remot3it.store.dispatch.contacts.fetchFinished()
        e = {
          event: me.DEVELOPER_CUSTOMER_LIST_EVENT,
          status: 'false',
          message: 'Customers not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getProductProvisioning = function(id) {
    var me = this
    this.xdr(
      BASE_URL + '/developer/product/provisioning/zip/' + id,
      'GET',
      '',
      function(data) {
        e = {
          event: me.BULK_REGISTRATION_PROVISIONING_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_REGISTRATION_PROVISIONING_EVENT,
          status: 'false',
          message: 'Provisioning not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getDeveloperProductUsage = function(productid) {
    var me = this
    this.xdr(
      BASE_URL + '/developer/product/usage/' + productid,
      'GET',
      '',
      function(data) {
        e = {
          event: me.PRODUCT_USAGE_LIST_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.PRODUCT_USAGE_LIST_EVENT,
          status: 'false',
          message: 'Product usage not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getProductBulkProjectID = function(productid) {
    var me = this
    this.xdr(
      BASE_URL + '/developer/product/project/identification/' + productid,
      'GET',
      '',
      function(data) {
        e = {
          event: me.BULK_PROJECT_ID_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_PROJECT_ID_EVENT,
          status: 'false',
          message: 'Bulk project identification not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getProducts = function() {
    var self = this
    return this.request(
      '/developer/product/list/mine/',
      'GET',
      '',
      function(data) {
        self.callback({
          event: self.DEVELOPER_PRODUCT_LIST_EVENT,
          status: 'true',
          message: data,
          tag: '',
        })
      },
      function() {
        self.callback({
          event: self.DEVELOPER_PRODUCT_LIST_EVENT,
          status: 'false',
          message: 'Products not available',
          tag: '',
        })
      }
    )
  }

  this.getPublicProducts = function() {
    var me = this
    this.xdr(
      BASE_URL + '/developer/product/list/public/',
      'GET',
      '',
      function(data) {
        e = {
          event: me.DEVELOPER_PRODUCT_PUBLIC_LIST_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.DEVELOPER_PRODUCT_PUBLIC_LIST_EVENT,
          status: 'false',
          message: 'Products not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getPrivateProducts = function() {
    var me = this
    this.xdr(
      BASE_URL + '/developer/product/list/private/unassigned/',
      'GET',
      '',
      function(data) {
        e = {
          event: me.DEVELOPER_PRODUCT_PRIVATE_LIST_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.DEVELOPER_PRODUCT_PRIVATE_LIST_EVENT,
          status: 'false',
          message: 'Products not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getOpenAvailableProducts = function() {
    var me = this
    this.xdr(
      BASE_URL + '/developer/product/list/private/available/open/',
      'GET',
      '',
      function(data) {
        e = {
          event: me.DEVELOPER_PRODUCT_AVAILABLE_OPEN_LIST_EVENT,
          status: 'true',
          message: data,
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.DEVELOPER_PRODUCT_AVAILABLE_OPEN_LIST_EVENT,
          status: 'false',
          message: 'Products not available',
          tag: '',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.loadScriptExamples = function() {
    var parameters = ''
    URL = BASE_URL + '/bulk/script/load/examples/'

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.LOAD_SCRIPT_EXAMPLES_EVENT,
          status: 'true',
          message: 'Disconnect ok',
          tag: '',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.LOAD_SCRIPT_EXAMPLES_EVENT,
          status: 'false',
          message: 'Disconnect failed',
          tag: '',
        }
        me.callback(e)
      }
    )

    return false
  }

  this.setCategory = function(deviceaddress, catA, catB, catC, catD, catE) {
    //alert("a:"+catA+" b:"+catB+" c:"+catC + " d:"+catD + " e:"+catE);

    var parameters = ''
    URL = BASE_URL + '/device/category/' + deviceaddress

    theRequest = {
      categoryA: catA,
      categoryB: catB,
      categoryC: catC,
      categoryD: catD,
      categoryE: catE,
    }
    parameters = JSON.stringify(theRequest)

    //alert(URL + " " + parameters);

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.SET_CATEGORY_EVENT,
          status: 'true',
          message: myData.id,
          tag: deviceaddress,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.SET_CATEGORY_EVENT,
          status: 'false',
          message: 'failed',
          tag: deviceaddress,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.unregisterDeveloperDevice = function(deviceaddress) {
    var parameters = ''
    URL = BASE_URL + '/developer/device/delete/registered/' + deviceaddress

    theRequest = {}
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.DEVELOPER_DEVICE_UNREGISTER_EVENT,
          status: 'true',
          message: myData.id,
          tag: deviceaddress,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.DEVELOPER_DEVICE_UNREGISTER_EVENT,
          status: 'false',
          message: 'failed',
          tag: deviceaddress,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.unregisterDeveloperDevices = function(body) {
    var parameters = ''
    URL = BASE_URL + '/developer/device/delete/registered/'

    parameters = JSON.stringify(body)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })
        e = {
          event: me.DEVELOPER_DEVICES_UNREGISTER_EVENT,
          status: 'true',
          message: myData.id,
          tag: myData.devices,
        }
        me.callback(e)
      },
      function(xhr, error) {
      }
    )
    return false
  }

  this.shareDeveloperDevice = function(deviceaddress, user, state, notify) {
    if (notify) {
      //alert("notify on");
    }

    URL =
      BASE_URL + '/developer/device/share/' + deviceaddress + '/' + user + '/'

    theRequest = {
      state: state,
      notify: notify,
      legacyEmail: window.LEGACY_EMAIL,
    }
    var parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //alert("share resp:"+data);

        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        //alert("share resp:"+JSON.stringify(myData));
        //alert("share resp:"+myData.status);

        if (myData.status == 'true') {
          e = {
            event: me.DEVELOPER_DEVICE_SHARE_EVENT,
            status: 'true',
            message: myData.id,
            tag: deviceaddress,
          }
          me.callback(e)
        } else {
          e = {
            event: me.DEVELOPER_DEVICE_SHARE_EVENT,
            status: 'false',
            message: myData.reason,
            tag: deviceaddress,
          }
          me.callback(e)
        }
      },
      function(xhr, error) {
        e = {
          event: me.DEVELOPER_DEVICE_SHARE_EVENT,
          status: 'false',
          message: 'failed',
          tag: deviceaddress,
        }
        me.callback(e)
      }
    )

    return false
  }

  this.getDeveloperDeviceSharing = function(deviceaddress) {
    var me = this
    this.xdr(
      BASE_URL + '/developer/device/share/list/' + deviceaddress,
      'GET',
      '',
      function(data) {
        e = {
          event: me.DEVELOPER_DEVICE_GET_SHARING_EVENT,
          status: 'true',
          message: data,
          tag: deviceaddress,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.DEVELOPER_DEVICE_GET_SHARING_EVENT,
          status: 'false',
          message: 'Sharing not available',
          tag: deviceaddress,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.setDeviceStatus = function(
    deviceaddress,
    statusA,
    statusB,
    statusC,
    statusD,
    statusE
  ) {
    var parameters = ''
    URL = BASE_URL + '/device/status/' + deviceaddress

    theRequest = {
      statusA: statusA,
      statusB: statusB,
      statusC: statusC,
      statusD: statusD,
      statusE: statusE,
    }
    parameters = JSON.stringify(theRequest)

    //alert("SetStatusColumns:"+URL + " " + parameters);

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.SET_STATUS_EVENT,
          status: 'true',
          message: myData.id,
          tag: deviceaddress,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.SET_STATUS_EVENT,
          status: 'false',
          message: 'failed',
          tag: deviceaddress,
        }
        me.callback(e)
      }
    )
    return true
  }

  this.shareDeveloperDevices = function(devices, emails, state, scripting) {
    var request = {
      function: 'share_devices',
      label: 'sharing',
      options: {
        devices: devices,
        emails: emails,
        state: state,
        legacyEmail: window.LEGACY_EMAIL,
        scripting: scripting,
      },
      authhash: authHash,
      actionurl:
        window.location.origin + '/invite/accept?type=shareDevices&id=',
    }
    if (TASK_QUEUE_NAME) request.queue_name = TASK_QUEUE_NAME

    var me = this
    return this.xdr(
      '/developer/devices/share/',
      'POST',
      request,
      function(data) {
        me.callback({
          event: me.BULK_SHARE_EVENT,
          status: 'true',
          message: data,
          tag: '',
        })
      },
      function(xhr, error) {
        console.error('Sharing Error:', error)
        me.callback({
          event: me.BULK_SHARE_EVENT,
          status: 'false',
          message: error,
          tag: '',
        })
      }
    )
  }

  this.setName = function(deviceaddress, name, sync) {
    var self = this
    return this.xdr(
      '/device/name/',
      'POST',
      {
        deviceaddress: deviceaddress,
        devicealias: name,
      },
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        self.callback({
          event: self.DEVICE_NAME_EVENT,
          status: 'true',
          message: myData.status,
          tag: deviceaddress,
        })
      },
      function() {
        self.callback({
          event: self.DEVICE_NAME_EVENT,
          status: 'false',
          message: 'failed',
          tag: deviceaddress,
        })
      }
    )
  }

  this.createRegistrationType = function(name, product, type) {
    var parameters = ''
    URL = BASE_URL + '/bulk/registration/create/'

    theRequest = {
      type: type,
      product: product,
      name: name,
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.BULK_REGISTRATION_CREATE_EVENT,
          status: 'true',
          message: myData.status,
          tag: name,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_REGISTRATION_CREATE_EVENT,
          status: 'false',
          message: 'failed',
          tag: name,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.createProduct = function(name, platform, scope) {
    var parameters = ''
    URL = BASE_URL + '/developer/product/'

    theRequest = {
      scope: scope,
      platform: platform,
      name: name,
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.BULK_PRODUCT_CREATE_EVENT,
          status: 'true',
          message: myData.status,
          tag: name,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_PRODUCT_CREATE_EVENT,
          status: 'false',
          message: 'failed',
          tag: name,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.createCustomer = function(
    companyName,
    firstName,
    lastName,
    email,
    language
  ) {
    var parameters = ''
    URL = BASE_URL + '/developer/customer/'

    theRequest = {
      companyname: companyName,
      firstname: firstName,
      lastname: lastName,
      email: email,
      language: language,
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        window.remot3it.store.dispatch.contacts.addFromLegacy(myData)

        e = {
          event: me.DEVELOPER_CUSTOMER_CREATE_EVENT,
          status: 'true',
          message: myData.status,
          tag: name,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.DEVELOPER_CUSTOMER_CREATE_EVENT,
          status: 'false',
          message: 'failed',
          tag: name,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.deleteProject = function(id) {
    var parameters = ''
    URL = BASE_URL + '/developer/product/project/' + id

    var me = this
    this.xdr(
      URL,
      'DELETE',
      '',
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.PRODUCT_DELETE_PROJECT_EVENT,
          status: 'true',
          message: myData.status,
          tag: id,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.PRODUCT_DELETE_PROJECT_EVENT,
          status: 'false',
          message: 'failed',
          tag: id,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.addProductProject = function(
    productid,
    type,
    name,
    localport,
    version,
    enabled
  ) {
    var parameters = ''
    URL = BASE_URL + '/developer/product/add/project/'

    theRequest = {
      product_id: productid,
      type: type,
      name: name,
      localport: localport,
      version: version,
      enabled: enabled,
    }
    parameters = JSON.stringify(theRequest)

    //alert(parameters);
    //return;

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.PRODUCT_ADD_PROJECT_EVENT,
          status: 'true',
          message: myData.status,
          tag: productid,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.PRODUCT_ADD_PROJECT_EVENT,
          status: 'false',
          message: 'failed',
          tag: productid,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.setBulkServiceConfiguration = function(
    bulkid,
    projectid,
    ip,
    port,
    enabled,
    sync
  ) {
    var parameters = ''
    URL = BASE_URL + '/bulk/registration/service/configuration/'

    theRequest = {
      bulk_id: bulkid,
      project_id: projectid,
      ip: ip,
      port: port,
      enabled: enabled,
    }
    parameters = JSON.stringify(theRequest)

    //alert(parameters);
    //return;

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.SET_BULK_SERVICE_CONFIGURATION_EVENT,
          status: 'true',
          message: myData.status,
          tag: projectid,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.SET_BULK_SERVICE_CONFIGURATION_EVENT,
          status: 'false',
          message: 'failed',
          tag: projectid,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getSupportedProjectTypes = function() {
    var self = this
    return this.request(
      '/project/supported/types/',
      'GET',
      '',
      function(data) {
        self.callback({
          event: self.PROJECT_SUPPORTED_PROJECT_TYPES_EVENT,
          status: 'true',
          message: data,
          tag: 'none',
        })
      },
      function() {
        self.callback({
          event: self.PROJECT_SUPPORTED_PROJECT_TYPES_EVENT,
          status: 'false',
          message: 'Project types not available',
          tag: 'none',
        })
      }
    )
  }

  this.getBulkProjectList = function(bulkid) {
    var me = this
    this.xdr(
      BASE_URL + '/bulk/register/projects/' + bulkid,
      'GET',
      '',
      function(data) {
        e = {
          event: me.BULK_PROJECT_LIST_EVENT,
          status: 'true',
          message: data,
          tag: 'none',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_PROJECT_LIST_EVENT,
          status: 'false',
          message: 'Project types not available',
          tag: 'none',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getProductProjectList = function(id) {
    var me = this
    this.xdr(
      BASE_URL + '/developer/product/projects/' + id,
      'GET',
      '',
      function(data) {
        e = {
          event: me.PRODUCT_PROJECT_LIST_EVENT,
          status: 'true',
          message: data,
          tag: 'none',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.PRODUCT_PROJECT_LIST_EVENT,
          status: 'false',
          message: 'Product projects not available',
          tag: 'none',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.getBulkServicesConfiguration = function(id) {
    var me = this
    this.xdr(
      BASE_URL + '/bulk/registration/service/configuration/list/' + id,
      'GET',
      '',
      function(data) {
        e = {
          event: me.BULK_SERVICE_CONFIGURATION_EVENT,
          status: 'true',
          message: data,
          tag: 'none',
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_SERVICE_CONFIGURATION_EVENT,
          status: 'false',
          message: 'Bulk Service confguration not available',
          tag: 'none',
        }
        me.callback(e)
      }
    )
    return false
  }

  this.deleteProduct = function(productid) {
    var parameters = ''
    URL = BASE_URL + '/developer/product/'

    theRequest = {
      productid: productid,
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'DELETE',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.DELETE_PRODUCT_EVENT,
          status: 'true',
          message: myData.status,
          tag: productid,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.DELETE_PRODUCT_EVENT,
          status: 'false',
          message: 'failed',
          tag: productid,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.publishBulkRegistration = function(bulk_id) {

    var parameters = ''
    URL = BASE_URL + '/bulk/registration/preregister'

    var theRequest = {
      'bulk_id': bulk_id
    }

    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.BULK_REGISTRATION_PUBLISH_EVENT,
          status: 'true',
          message: myData.status,
          tag: bulk_id,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_REGISTRATION_PUBLISH_EVENT,
          status: 'false',
          message: 'failed',
          tag: bulk_id,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.transferBulkRegistration = function(bulk_id, email) {
    var parameters = ''
    URL = BASE_URL + '/bulk/registration/transfer/'

    var options = { email: email }

    theRequest = {
      bulkid: bulk_id,
      options: JSON.stringify(options),
    }
    if (TASK_QUEUE_NAME) theRequest.queue_name = TASK_QUEUE_NAME

    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.BULK_REGISTRATION_TRANSFER_EVENT,
          status: 'true',
          message: myData.status,
          tag: bulk_id,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_REGISTRATION_TRANSFER_EVENT,
          status: 'false',
          message: 'failed',
          tag: bulk_id,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.deleteBulkRegistration = function(bulk_id) {
    var parameters = ''
    URL = BASE_URL + '/bulk/registration/unregister'

    theRequest = {
      'bulk_id': bulk_id
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.BULK_REGISTRATION_DELETE_EVENT,
          status: 'true',
          message: myData.status,
          tag: bulk_id,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_REGISTRATION_DELETE_EVENT,
          status: 'false',
          message: 'failed',
          tag: bulk_id,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.finalDeleteBulkRegistration = function(bulk_id) {
    var parameters = ''
    URL = BASE_URL + '/bulk/registration/delete/'

    theRequest = {
      bulk_id: bulk_id,
      permenant: 'true',
    }
    parameters = JSON.stringify(theRequest)

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.BULK_REGISTRATION_FINAL_DELETE_EVENT,
          status: 'true',
          message: myData.status,
          tag: bulk_id,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_REGISTRATION_FINAL_DELETE_EVENT,
          status: 'false',
          message: 'failed',
          tag: bulk_id,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.updateOpenRegistrationSettings = function(
    bulk_id,
    allow,
    locked,
    product
  ) {
    var parameters = ''
    URL = BASE_URL + '/bulk/registration/open/settings/'

    theRequest = {
      bulk_id: bulk_id,
      product: product,
      allow: allow,
      lock: locked,
    }
    parameters = JSON.stringify(theRequest)

    //alert(parameters);
    //return;

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.BULK_REGISTRATION_OPEN_SETTINGS_EVENT,
          status: 'true',
          message: myData.status,
          tag: bulk_id,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.BULK_REGISTRATION_OPEN_SETTINGS_EVENT,
          status: 'false',
          message: 'failed',
          tag: bulk_id,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.updateProductSettings = function(product_id, locked) {
    var parameters = ''
    URL = BASE_URL + '/developer/product/settings/'

    theRequest = {
      product_id: product_id,
      lock: locked,
    }
    parameters = JSON.stringify(theRequest)

    //alert(parameters);
    //return;

    var me = this
    this.xdr(
      URL,
      'POST',
      parameters,
      function(data) {
        //parse the JSON response
        var myData = JSON.parse(data, function(key, value) {
          return value
        })

        e = {
          event: me.PRODUCT_SETTINGS_EVENT,
          status: 'true',
          message: myData.status,
          tag: product_id,
        }
        me.callback(e)
      },
      function(xhr, error) {
        e = {
          event: me.PRODUCT_SETTINGS_EVENT,
          status: 'false',
          message: 'failed',
          tag: product_id,
        }
        me.callback(e)
      }
    )
    return false
  }

  this.isWebContent = function(devicetype) {
    if (
      devicetype == '0' ||
      devicetype == '4' ||
      devicetype == '7' ||
      devicetype == '8' ||
      devicetype == '10' ||
      devicetype == '12' ||
      devicetype == '17' ||
      devicetype == '18' ||
      devicetype == '19' ||
      devicetype == '24' ||
      devicetype == '30' ||
      devicetype == '33' ||
      devicetype == '42'
    ) {
      return true
    }
    return false
  }

  this.isSSHContent = function(devicetype) {
    if (devicetype == '28') {
      return true
    }
    return false
  }

  function setCookie(cname, cvalue, exMins) {
    var d = new Date()
    d.setTime(d.getTime() + exMins * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=/'
  }

  function getCookie(cname) {
    var name = cname + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1)
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length)
    }
    return ''
  }

  this.xdr = function(url, method, data, callback, errback) {
    var req

    // Make sure to JSON encode data if not encoded by default.
    // Ignore if the data is a string or FormData
    var isFormData = data instanceof FormData
    var isString = typeof data === 'string'
    if (!isFormData && !isString) data = JSON.stringify(data)

    // Make sure the BASE_URL is prefixed on the URL in case it wasn't provided
    url = url.startsWith(BASE_URL) ? url : BASE_URL + url

    return new Promise(function(resolve, reject) {
      if (XMLHttpRequest) {
        req = new XMLHttpRequest()

        if ('withCredentials' in req) {
          req.open(method, url, true)
          req.setRequestHeader('developerKey', window.DEVELOPER_KEY)

          window.authService.currentCognitoSession()
            .then(function(currentSession) {
              req.setRequestHeader(
                'Authorization',
                'Bearer ' + currentSession.getAccessToken().getJwtToken()
              )
              req.onerror = function(e) {
                errback(e)
                reject(e)
              }
              req.onreadystatechange = function() {
                if (req.readyState === 4) {
                  if (req.status === 401) {
                    window.remot3it.store.dispatch.auth.signOut()
                  }
                  if (req.status >= 200 && req.status < 400) {
                    if (callback) callback(req.responseText)
                    resolve(req.responseText)
                  } else {
                    if (errback) {
                      errback(req.responseText)
                    } else {
                      reject(new Error(req.responseText))
                    }
                  }
                }
              }
              req.send(data)
            })
            .catch(function(error) {
              console.error(error)
              reject(error)
              //alert('ERROR: ' + error.message)
            })
        }
      } else if (XDomainRequest) {
        req = new XDomainRequest()
        req.open(method, url)
        req.onerror = function(e) {
          console.log('REQUEST ERROR:', e)
          reject(new Error(e))
        }
        req.onload = function() {
          callback(req.responseText)
          resolve(req.responseText)
        }
        req.send(data)
      } else {
        errback(new Error('CORS not supported'))
        reject(new Error('CORS not supported'))
      }
    })
  }

  this.processRequestData = function(data) {
    if (data instanceof FormData) return data
    if (typeof data === 'string') return data
    return JSON.stringify(data)
  }

  // xdr compatible replacement using Fetch.
  this.request = function(url, method, data, callback, errback) {
    self = this
    window.authService.currentCognitoSession()
      .then(function(currentSession) {
        method = method.toLowerCase()

        // Make sure to JSON encode data if not encoded by default.
        data = self.processRequestData(data)

        // Make sure the BASE_URL is prefixed on the URL in case it wasn't provided
        url = url.startsWith(BASE_URL) ? url : BASE_URL + url

        var config = {
          url: url,
          method: method,
          headers: {
            'Content-Type': 'application/json',
            developerKey: window.DEVELOPER_KEY,
            Authorization: 'Bearer ' + currentSession.getAccessToken().getJwtToken(),
          },
        }

        // Only attach data when it exists and
        // the method is not GET (which is invalid).
        if (data && method !== 'get') config.data = data

        return window.remot3it.helpers
          .axios(config)
          .then(function(data) {
            // Stringifying because legacy code expects it as a string.
            // FIXME: Change legacy code to not call JSON.parse
            if (callback) callback(JSON.stringify(data))
            return data
          })
          .catch(function(error) {
            if (errback) return errback(error)
            //throw error
          })
      })
      .catch(function(error) {
        console.error(error)
        if (errback) return errback(error)
        //throw error
        //alert('ERROR: ' + error.message)
      })
  }
}
