//--------------------------------------------------------------------
// Shared values
//--------------------------------------------------------------------

// $.jgrid.defaults.width = 780;
$.jgrid.defaults.responsive = true
$.jgrid.defaults.styleUI = 'Bootstrap'
var legaldocsfolder = '/legal/'
var newfairuse = false
var newprivacy = false
var newtos = false
var fairusechecked = false
var privacychecked = false
var touchecked = false

//--------------------------------------------------------------------
// Functions values
//--------------------------------------------------------------------

function agreechecked(element) {
  if (element.checked) {
    fairusechecked = true
  } else {
    fairusechecked = false
  }
  activatePolicyButton()
}

function activatePolicyButton() {
  var selected = []
  $('#announcements-div input:checked').each(function() {
    selected.push($(this).attr('name'))
  })

  if (selected.length >= requiredAcks) {
    document.getElementById('policy_agree_btn').disabled = false
  } else {
    document.getElementById('policy_agree_btn').disabled = true
  }
}

function cancelPolicyChanges() {
  //alert(JSON.stringify(loginResponse.announcements));

  if (requiredAcks > 0) {
    startSpinner()
    weaved.logout()
  } else {
    $('#policy_update_Modal').modal('hide')
  }
}

function show_policy_modal(url) {
  $('#include_policy_html').load(legaldocsfolder + url)
  $('#policyModal').modal('show')
  $('#policy_update_Modal').modal({
    backdrop: 'static',
    keyboard: false,
  })
}

//--------------------------------------------------------------------
// Document ready events
//--------------------------------------------------------------------

$(function() {
  // $('.i-checks').iCheck({
  //   checkboxClass: 'icheckbox_square-green',
  //   radioClass: 'iradio_square-green',
  // })

  $('#adv_feature_learn_btn').click(function() {
    window.location.href = 'http://support.remote.it'
  })

  $('.scroll_content').slimscroll({ height: '200px' })

  if (localStorage.chkbx && localStorage.chkbx != '') {
    $('#remember_me').attr('checked', 'checked')
    $('#inputEmail').val(localStorage.usrname)
  } else {
    $('#remember_me').removeAttr('checked')
    $('#inputEmail').val('')
  }

  $('#remember_me').click(function() {
    if ($('#remember_me').is(':checked')) {
      localStorage.usrname = $('#inputEmail').val()
      localStorage.chkbx = $('#remember_me').val()
    } else {
      localStorage.usrname = ''
      localStorage.chkbx = ''
    }
  })

  $('#jobs-table').trigger('footable_redraw')

  $('#shared-device-accounts').footable()

  $(document).on('change', '#allowAutoRegistration', function() {
    if ($(this).is(':checked')) {
      $('#lockOpenRegistration').popover('destroy')

      $('#allowAutoRegistration').popover({
        trigger: 'manual',
        title: 'Auto Registration Notice',
        content:
          'You are responsible for an unlimited number of devices possibly activated using this bulk provisioning',
        placement: 'top',
      })
      $('#allowAutoRegistration').popover('show')

      setTimeout(function() {
        $('#allowAutoRegistration').popover('hide')
      }, 5000)

      //alert( "Allowing auto registration requires your account to be responsible for all devices activated using these provisioning files." );
    } else {
      $('#allowAutoRegistration').popover('destroy')
    }
  })

  $(document).on('change', '#lockOpenRegistration', function() {
    if ($(this).is(':checked')) {
      $('#allowAutoRegistration').popover('destroy')

      $('#lockOpenRegistration').popover({
        trigger: 'manual',
        title: 'Registration Lock Notice',
        content:
          'You are responsible for putting the Bulk Identification Code on the device before distributing',
        placement: 'top',
      })
      $('#lockOpenRegistration').popover('show')

      setTimeout(function() {
        $('#lockOpenRegistration').popover('hide')
      }, 5000)

      //alert( "Allowing auto registration requires your account to be responsible for all devices activated using these provisioning files." );
    } else {
      $('#lockOpenRegistration').popover('destroy')
    }

    //if ($(this).is(':checked')) alert( "Locking this Registration will prevent future changes.  You must then put the provisioning files on all devices to be registered." );
  })

  $(document).on('change', '#lockProduct', function() {
    if ($(this).is(':checked')) {
      $('#lockProduct').popover({
        trigger: 'manual',
        title: 'Product Lock Notice',
        content:
          'You are responsible for ensuring the Bulk Identification Code is on the device before distributing',
        placement: 'top',
      })
      $('#lockProduct').popover('show')

      setTimeout(function() {
        $('#lockProduct').popover('hide')
      }, 5000)

      //alert( "Allowing auto registration requires your account to be responsible for all devices activated using these provisioning files." );
    } else {
      $('#lockProduct').popover('destroy')
    }

    //if ($(this).is(':checked')) alert( "Locking this Product will prevent future changes.  You must then put the provisioning files on all devices to be registered." );
  })

  $(document).on('change', '#showChangeProductCheckbox', function() {
    $('#lockProduct').popover('destroy')

    if ($(this).is(':checked')) {
      $(this)
        .siblings('label')
        .html('Uncheck to keep current product definition')
      $('#changeProductDiv').show()
    } else {
      $(this)
        .siblings('label')
        .html('Check to change your current product definition')
      $('#changeProductDiv').hide()
    }
  })

  $(document).on('change', '#showPublicProductsCheckbox', function() {
    if ($(this).is(':checked')) {
      $(this)
        .siblings('label')
        .html('Uncheck to select from private remote.it products')

      $('#privateProductDiv').hide()
      $('#publicProductDiv').show()
      //$("#primaryRegistrationFileDiv").show();
      //$("#secondaryRegistrationFileDiv").hide();
      $('#privateProductCodeDiv').show()
    } else {
      $(this)
        .siblings('label')
        .html('Check to select from public or unlisted remote.it products')

      $('#privateProductDiv').show()
      $('#publicProductDiv').hide()
      //$("#primaryRegistrationFileDiv").show();
      //$("#secondaryRegistrationFileDiv").hide();
      $('#privateProductCodeDiv').hide()
    }
  })

  $(document).on('change', '#showPublicProductsCheckbox2', function() {
    if ($(this).is(':checked')) {
      $(this)
        .siblings('label')
        .html('Uncheck to select from private remote.it products')

      $('#privateProductDiv2').hide()
      $('#publicProductDiv2').show()
      $('#privateProductCodeDiv2').show()
    } else {
      $(this)
        .siblings('label')
        .html('Check to select from public or unlisted remote.it products')

      $('#privateProductDiv2').show()
      $('#publicProductDiv2').hide()
      $('#privateProductCodeDiv2').hide()
    }
  })

  window.ATL_JQ_PAGE_PROPS = $.extend(window.ATL_JQ_PAGE_PROPS, {
    // ==== custom trigger function ====
    triggerFunction: function(showIssueCollector) {
      //alert("show collector");
    },
    // ==== Special field config for environment ====
    environment: function() {
      var env_info = {}

      return env_info
    },
    // ==== default field values ====
    fieldValues: function() {
      var values = {}

      // record error message from the page context rather than asking the user to enter it
      values['description'] =
        'Add issue detail here, plus scroll down to provide more detail with attachments.'
      values['email'] = getCookie('remoteit.email')
      values['environment'] =
        'URL:' +
        location.href +
        ', API:' +
        BASE_URL +
        ', Screen:' +
        window.screen.availWidth +
        'x' +
        window.screen.availHeight +
        ', Agent:' +
        navigator.userAgent
      values['components'] = [JIRA_COMPONENT]
      values['versions'] = [JIRA_VERSION]
      return values
    },
  })

  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: false,
    preventDuplicates: true,
    positionClass: 'toast-top-right',
    onclick: function() {
      showAcceptInvitationModal()
    },
    showDuration: '10000',
    hideDuration: '10000',
    timeOut: '30000',
    extendedTimeOut: '0',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    tapToDismiss: false,
    hideMethod: 'fadeOut',
  }

  $('#createCustomerEmail').change(function() {
    //alert($(this).val());
    var email = $(this).val()

    $('#customerLanguage > option').each(function() {
      //alert(this.value+ ":"+email.indexOf(this.value));
      if (email.indexOf(this.value) > -1) {
        //alert("found:"+this.value);
        $("#customerLanguage option[value='" + this.value + "']").prop(
          'selected',
          true
        )
      }
    })
  })
})
