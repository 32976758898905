var JIRA_COMPONENT = '10205'
var JIRA_VERSION = '10200'

var MAX_SELECTED = 1000
var MAX_ALLOW_UPDATES = 12
var MAX_DELETE = 400
var MAX_SHARE = 100
var MAX_UPLOAD = 2048000000
var MAX_SCRIPTNAME_LENGTH = 25
var MAX_UPLOAD_DEVICES = 20001
var MAX_UPLOAD_REGKEYS = 1000
var MAX_CSV_UPLOAD_FILE_SIZE = 2500 * 1024
var DELETE_TIME_OVERRIDE = 1
var globalActiveDeviceID = ''

var token = ''
var authhash = ''
var username = ''
var userid = ''
var email = ''
var mydata = []
var bulkdata = []

var domainHandler = new R3Subdomains()
var subdomain = ''
var subdomainData = {}

var launchRow = ''
var launchAddress = ''
var launchType = ''
var launchName = ''

var contentMode = ''

var searchWarning = true

var bulkSelection = ''

var pickerEnabled = false

var disconnectAddress = ''

var ignoreLeavePage = false

var fileSelected = ''

var statusBlob = ''
var statusBlobFilter = ''

var contactPickerModal = ''
var contactPickerField = ''

var executeWithFile = false
var executeWithMultipleFiles = false
var executeWithMultipleFileSelect = false
var executeWithParam = false
var executeStep = 0
var scriptSelected = false
var scriptExpiration = ''
var tallyOption = ''

var listSelectMultiple = false

var bulkID = ''
var bulkName = ''
var productID = ''
var projectID = ''

var productName = ''
var productLock = ''

var customerID = ''
var customerEmail = ''

var executeHeaderSteps = []
var excuteCommandString = ''

var executeResponses = []

var devicesRendered = true
var servicesRendered = false

var shareDeviceAddress = ''
var shareDeviceName = ''
var shareActionCount = 0
var shareAction = ''

var jobidForAction = ''
var jobAction = ''

var launchDeviceServiceAddress = ''
var launchDeviceServiceType = ''
var launchDeviceServiceName = ''

var bulkRegistrationID = ''
var bulkRegistrationName = ''
var bulkRegistrationState = ''
var bulkRegistrationType = ''

var newBulkRegistrationMode = ''

var bulkRegistrationdDeviceID = ''
var bulkRegistrationDeviceName = ''
var bulkRegistrationDeviceState = ''

var nameChange = ''
var nameChangeAddress = ''

var upload_server_url = ''

var deleteContentKey = ''
var deleteContentName = ''
var whichContent = ''

var spinner
var target
var gridSpinner

var opts = {
  lines: 12, // The number of lines to draw
  length: 13, // The length of each line
  width: 6, // The line thickness
  radius: 20, // The radius of the inner circle
  scale: 0.5, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  color: '#000', // #rgb or #rrggbb or array of colors
  opacity: 0.25, // Opacity of the lines
  rotate: 0, // The rotation offset
  direction: 1, // 1: clockwise, -1: counterclockwise
  speed: 0.8, // Rounds per second
  trail: 60, // Afterglow percentage
  fps: 20, // Frames per second when using setTimeout() as a fallback for CSS
  zIndex: 2e3, // The z-index (defaults to 2000000000)
  className: 'spinner', // The CSS class to assign to the spinner
  top: '50%', // Top position relative to parent
  left: '50%', // Left position relative to parent
  shadow: false, // Whether to render a shadow
  hwaccel: false, // Whether to use hardware acceleration
  position: 'absolute', // Element positioning
}
var awsCode = 1185

target = document.getElementById('thebody')
spinner = new Spinner(opts).spin(target)

var fqhn = window.location.host
var fqhn_parts = fqhn.split('.')

function startSpinner() {
  if (spinner) {
    spinner.spin(target)
    setTimeout(function() {
      stopSpinner()
    }, 30000)
  }
}

function stopSpinner() {
  spinner.stop()
}

function closeConfirmationRefreshDeviceList(modal_id) {
  $('#' + modal_id).modal('hide')
  weaved.fetchDevices()
}

function showDevices() {
  $('.page').hide()
  $('#wrapper').show()
  $('#tab-bulk').show()
  $('#wrapper-splash').hide()

  if (!devicesRendered) {
    devicesRendered = true
    renderDevices()
  }

  $(window).trigger('resize')
}

function showScreen() {
  $('.page').hide()
  $('#wrapper').hide()
  $('#wrapper-splash').show()
  $('#tab-splashscreen').show()
  $(window).trigger('resize')
}

function showUpload() {
  $('#uploads').val('')
  $('#upload-status').html('')
  $('#_submit').prop('disabled', false)
  $('#upload-progress-status').hide()

  window.remot3it.r3.utilities.serverUrl().then(function(url) {
    upload_server_url = url
    $('#upload').modal('show')
  })
}

function showBulkUpload() {
  newBulkRegistrationMode = 'bulk'

  $('#showPublicProductsCheckbox').removeAttr('checked')
  $('#showPublicProductsCheckbox')
    .siblings('label')
    .html('Check to select from public or unlisted remote.it products')
  $('#showPublicProductsCheckbox2').removeAttr('checked')
  $('#showPublicProductsCheckbox2')
    .siblings('label')
    .html('Check to select from public or unlisted remote.it products')
  $('#newBulkRegistration').val($('#newBulkRegistration option:first').val())

  $('#openRegistrationName').val('')

  $('#bulkuploads').val('')
  $('#bulkuploads_secondary').val('')
  $('#bulk-upload-status').html('')
  $('#_bulksubmit').removeAttr('disabled')

  //$('#bulkupload').modal('show');

  $('#registrationTitle').html('New Bulk Registration')

  $('#openRegistrationInstructionsDiv').hide()
  $('#bulkRegistrationInstructionsDiv').show()
  $('#pooledRegistrationInstructionsDiv').hide()

  $('#showPrivateProductsDiv').show()

  $('#bulkUploadStatusDiv').hide()

  $('#privateProductDiv').show()
  $('#publicProductDiv').hide()
  $('#primaryRegistrationFileDiv').show()
  $('#secondaryRegistrationFileDiv').hide()
  $('#privateProductCodeDiv').hide()

  $('#file-limits-div').show()

  weaved.getPublicProducts()
  weaved.getOpenAvailableProducts()

  $('#Open-Registration-Create-Modal').modal('show')
}

function showCreateOpenRegistration() {
  newBulkRegistrationMode = 'open'

  $('#openRegistrationName').val('')

  $('#showPublicProductsCheckbox').removeAttr('checked')

  $('#registrationTitle').html('New Auto Registration')

  $('#openRegistrationInstructionsDiv').show()
  $('#bulkRegistrationInstructionsDiv').hide()
  $('#pooledRegistrationInstructionsDiv').hide()

  $('#bulkUploadStatusDiv').hide()
  $('#upload-error-div').hide()

  $('#showPrivateProductsDiv').hide()

  $('#privateProductDiv').show()
  $('#publicProductDiv').hide()
  $('#primaryRegistrationFileDiv').hide()
  $('#secondaryRegistrationFileDiv').hide()
  $('#privateProductCodeDiv').hide()

  //weaved.getPublicProducts();
  weaved.getPrivateProducts()

  $('#Open-Registration-Create-Modal').modal('show')
}

function showJobs() {
  $('#jobs-table tbody tr').remove()
  $('.page').hide()
  $('#tab-jobs').show()
  $('#job_activity_icon').hide()
  $('#wrapper-splash').hide()

  spinner.spin(target)
  weaved.getBulkJobs()
}

function showProducts() {
  $('#products-table tbody tr').remove()
  $('.page').hide()
  $('#tab-products').show()
  $('#wrapper-splash').hide()

  spinner.spin(target)
  weaved.getProducts()
}

function showDeployments() {
  $('#deployments-table tbody tr').remove()
  $('.page').hide()
  $('#tab-deployments').show()
}

function showJobTasks(id, name, when) {
  $('#job-modal-info').html(name + ' on ' + when)

  $('#job-tasks-table tbody tr').remove()
  $('#job-tasks-modal')
    .modal('show')
    .trigger('footable_redraw')

  spinner.spin(target)
  weaved.getBulkJobTasks(id)
}

function showRegistrations() {
  $('#bulk-registration-table tbody tr').remove()

  $('#newBulkRegistration').val($('#newBulkRegistration option:first').val())

  $('.page').hide()
  $('#tab-bulk-registrations').show()
  $('#wrapper-splash').hide()

  spinner.spin(target)
  weaved.getBulkRegistrations()
}

function showRegistrationDevices(id, name, when) {
  $('#registration-devices-modal-info').html(name + ' on ' + when)
  $('#registration-devices-table').remove()
  $('#registration-devices-modal').modal('show')

  spinner.spin(target)
  weaved.getBulkRegistrationDevices(id)
}

function showJobActionModal(id, script, timestamp, action) {
  jobidForAction = id
  jobAction = action

  $('#job-detail-title').html(action)
  $('#job-detail-confirmation').html(action)

  $('#confirmCancelJob').removeAttr('checked')

  $('#job-detail-status').html('')

  $('#Job-Detail-Modal').modal('show')
}

function showDeviceServiceLaunchModal(serviceadddress, servicename, servicetype) {
  launchDeviceServiceAddress = serviceadddress
  launchDeviceServiceType = servicetype
  launchDeviceServiceName = servicename

  $('#Device-Services-Modal').modal('hide')
  $('#Device-Service-Launch-Modal').modal('show')
}

function showJobModal() {
  $('#jobs-table tbody tr').remove()
  $('#tab-jobs').show()

  spinner.spin(target)
  weaved.getBulkJobs()
}

function showBulkRegistrationSettingsModal(bulk_id, name) {
  bulkRegistrationID = bulk_id
  bulkRegistrationName = name

  $('#bulkProductSelectionList').text(bulkRegistrationID)
  $('#bulkRegistrationName').val(bulkRegistrationName)

  $('#bulk-settings-status').html('')

  weaved.getBulkProducts()

  $('#Bulk-Registration-Settings-Modal').modal('show')
}

function showBulkRegistrationPublishModal(bulk_id, name) {
  $('#bulk-preregister-status').html('')
  $('#confirmRegistrationPubllish').removeAttr('checked')
  $('#bulkRegistrationPublishButton').removeAttr('disabled')

  bulkRegistrationID = bulk_id
  bulkRegistrationName = name

  $('#Bulk-Registration-Publish-Modal').modal('show')
}

function showBulkRegistrationDeleteModal(bulk_id, name, state) {
  $('#bulk-delete-status').html('')
  $('#bulkRegistrationDeleteButton').removeAttr('disabled')
  $('#confirmRegistrationDelete').removeAttr('checked')

  bulkRegistrationID = bulk_id
  bulkRegistrationName = name
  bulkRegistrationState = state

  $('#Bulk-Registration-Delete-Modal').modal('show')
}

function showBulkRegistrationTransferModal(bulk_id, name, state) {
  $('#bulk-transfer-status').html('')
  $('#bulkRegistrationTransferButton').removeAttr('disabled')
  $('#confirmRegistrationTransfer').removeAttr('checked')

  bulkRegistrationID = bulk_id
  bulkRegistrationName = name
  bulkRegistrationState = state
  window.remot3it.store.dispatch.contacts.select([])
  window.remot3it.store.dispatch.contacts.setContactOptions(window.remot3it.store.getState().contacts.all)
  $('#Bulk-Registration-Transfer-Modal').modal('show')
}

function showProjectDeleteModal(id) {
  $('#project-delete-status').html('')
  $('#projectDeleteButton').removeAttr('disabled')
  $('#confirmProjectDelete').removeAttr('checked')

  projectID = id

  //alert ("project:"+projectID);

  $('#Project-Delete-Modal').modal('show')
}

function projectDeleteDone() {
  $('#project-delete-status').html('')
  $('#projectDeleteButton').removeAttr('disabled')
  $('#confirmProjectDelete').removeAttr('checked')

  $('#Project-Delete-Modal').modal('hide')

  setTimeout(function() {
    result = weaved.deleteProject(projectID)
  }, 500)
}

/*
function confirmProjectDeleteAction() {
    
    result = weaved.deleteProject(productID);
    if (result == "true") {
        
        $("#file-confirmation-message").html("");
        deleteContentKey = "";
        deleteContentName = "";
        
        // refresh the content modal
        showContentModal(whichContent);
        
        setTimeout(function() {
            result = weaved.deleteProject(productID);
        }, 500);

        
    } else {
        $("#file-confirmation-message").html("There was an unexpected problem delting the file. Please try again.");
    }
}
 */

function showDeleteProductModal(id, name) {
  productID = id
  productName = name

  $('#product-delete-status').html('')
  $('#productDeleteButton').removeAttr('disabled')
  $('#confirmProductDelete').removeAttr('checked')

  $('#product-delete-name').html(name)
  $('#Product-Delete-Modal').modal('show')
}

function showDeleteContactModal(id, email) {
  customerID = id
  customerEmail = email

  $('#contact-delete-name').html(email)
  $('#contact-delete-modal').modal('show')
}

function deleteContact() {
  var btn = $('#contact-delete-button')
  var message = $('#contact-delete-message')

  // Update form to show we are deleting the contact.
  btn.attr('disabled', 'disabled')
  btn.html('Deleting...')

  // customerID is a global, ugh.
  var id = customerID
  return window.remot3it.store.dispatch.contacts
    .destroy(id)
    .then(function() {
      // Close the modal
      closeDeleteContactModal()

      // Reload the contacts list
      weaved.getContacts()
    })
    .catch(function(error) {
      message.html('Something went wrong, please try again later!')
      console.error('Customer delete error:', error)
    })
}

function closeDeleteContactModal() {
  customerID = ''
  customerEmail = ''

  $('#contact-delete-message').html('')
  $('#contact-delete-button')
    .html('Delete')
    .removeAttr('disabled')
  $('#contact-delete-name').html('')
  $('#contact-delete-modal').modal('hide')
}

function showProductUsageModal(id, name) {
  weaved.getDeveloperProductUsage(id)

  weaved.getProductBulkProjectID(id)

  $('#bulk-identification-code').html('loading ...')

  $('#product-usage-modal-info').html(name)
  $('#product-code').html(id)
  $('#product-usage-modal').modal('show')
}

function showCreateProductModal() {
  $('#createProductSubmit').removeAttr('disabled')

  $('#Product-Create-Modal').modal('show')
}

function showCreateCustomerModal() {
  $('#createCustomerSubmit').removeAttr('disabled')

  $('#Customer-Create-Modal').modal('show')
}

function showCreateAccountModal(customer, email) {
  customerID = customer
  customerEmail = email

  $('#accountEmail').html(email)

  $('#confirmAccountCreate').prop('checked', false)

  $('#accountCreateButton').removeAttr('disabled')

  $('#Account-Create-Modal').modal('show')
}

function showBulkServicesModal(bulkid, name) {
  $('#bulk-services-modal-info').html(name)
  $('#bulk-services-status').html('')

  weaved.getBulkServicesConfiguration(bulkid)

  $('#Bulk-Services-Modal').modal('show')
}

function doDownloadProvisioning(bulk_id, name, state) {
  spinner.spin(target)
  weaved.getBulkRegistrationProvisioning(bulk_id)
}

function doDownloadProductProvisioning(id, name, state) {
  spinner.spin(target)
  weaved.getProductProvisioning(id)
}

function showProductSettingsModal(id, name, lock) {
  //$("#bulk-delete-device-status").html("");
  //$('#confirmRegistrationDeviceDelete').removeAttr('checked');

  productID = id
  productName = name
  productLock = lock

  $('#lockProduct').removeAttr('checked')

  if (lock == 'on' || lock.toLowerCase() == 'locked') {
    $('#lockProduct').prop('checked', true)

    $('#product-settings-submit-button').attr('disabled', 'true')
    $('#lockProduct').attr('disabled', 'true')
    $('#add-product-project-button').attr('disabled', 'true')

    $('#product-settings-status').text('This Product is locked and limited to adding new services')
  } else {
    $('#lockProduct').prop('checked', false)

    $('#product-settings-submit-button').removeAttr('disabled')
    $('#lockProduct').removeAttr('disabled')
    $('#add-product-project-button').removeAttr('disabled')

    $('#product-settings-status').text('')
  }

  // request project list
  weaved.getProductProjectList(id)

  //$("#open-registration-settings-type-title").text(type);
  $('#product-settings-modal-info').html(name)
  $('#Product-Settings-Modal').modal('show')
}

function showProductPoolModal(id, name) {
  //$("#bulk-delete-device-status").html("");
  //$('#confirmRegistrationDeviceDelete').removeAttr('checked');

  productID = id
  productName = name

  // request pool list
  //weaved.getProductProjectList(id);

  //$("#open-registration-settings-type-title").text(type);
  $('#product-pool-modal-info').html(name)
  $('#Product-Pool-Modal').modal('show')
}

function showOpenRegistrationSettingsModal(bulk_id, name, type, allow, lock) {
  //$("#bulk-delete-device-status").html("");
  //$('#confirmRegistrationDeviceDelete').removeAttr('checked');

  // request project list
  weaved.getBulkProjectList(bulk_id)
  weaved.getPrivateProducts()
  weaved.getPublicProducts()

  bulkRegistrationID = bulk_id
  bulkRegistrationName = name
  bulkRegistrationType = type

  $('#allowAutoRegistration').removeAttr('checked')
  $('#lockOpenRegistration').removeAttr('checked')

  $('#showChangeProductCheckbox').removeAttr('checked')
  $('#showChangeProductCheckbox')
    .siblings('label')
    .html('Check to change your current product definition')
  $('#changeProductDiv').hide()

  $('#showPublicProductsCheckbox2').removeAttr('checked')
  $('#showPublicProductsCheckbox2')
    .siblings('label')
    .html('Check to select from public or unlisted remote.it products')

  $('#showPublicProductsCheckbox').removeAttr('checked')

  //$("#showPrivateProductsDiv2").hide();

  $('#privateProductDiv2').show()
  $('#publicProductDiv2').hide()
  $('#privateProductCodeDiv2').hide()

  if (type.toLowerCase() == 'pooled') {
    $('#autoRegistrationDiv').hide()
  } else {
    $('#autoRegistrationDiv').show()
  }

  if (allow == 'on') $('#allowAutoRegistration').prop('checked', true)

  if (lock == 'on') {
    $('#lockOpenRegistration').prop('checked', true)

    $('#open-registration-settings-submit-button').attr('disabled', 'true')
    $('#allowAutoRegistration').attr('disabled', 'true')
    $('#lockOpenRegistration').attr('disabled', 'true')
    $('#add-open-project-button').attr('disabled', 'true')

    $('#open-registration-settings-status').text(
      'This Open Registration is locked and not able to be changed at this time.'
    )
  } else {
    $('#lockOpenRegistration').prop('checked', false)

    $('#open-registration-settings-submit-button').removeAttr('disabled')
    $('#allowAutoRegistration').removeAttr('disabled')
    $('#lockOpenRegistration').removeAttr('disabled')
    $('#add-open-project-button').removeAttr('disabled')

    $('#open-registration-settings-status').text('')
  }

  $('#open-registration-provisioning-download').hide()

  $('#open-registration-settings-type-title').text(type)
  $('#open-registration-settings-modal-info').html(name)

  $('#Open-Registration-Settings-Modal').modal('show')
}

function showAddOpenRegistrationServiceModal() {
  /*
    $("#projectName").text(type);
    $("#projectContentPort").text(type);
    $("#projectFirmwareVersion").text(type);
    $("#projectEnabled").removeAttr('checked');
     */

  $('#Open-Registration-Settings-Modal').modal('hide')
  $('#Set-Project-Modal').modal('show')
}

function showBulkServiceConfigurationSettingsModal(bulk_id, project_id, content_type, ip, port, enabled) {
  bulkID = bulk_id
  //bulkName = bulk_name;
  projectID = project_id

  $('#setServiceContentIP').val(ip)
  $('#setServiceContentPort').val(port)

  $('#setServiceContentType').html(content_type)

  $('#setServiceEnabled').removeAttr('checked')
  if (enabled == 1) $('#setServiceEnabled').prop('checked', true)

  $('#Bulk-Services-Modal').modal('hide')
  $('#Set-Bulk-Service-Modal').modal('show')
}
function showAddProductServiceModal() {
  $('#projectName').val('')
  //$("#projectContentIP").val("");
  $('#projectContentPort').val('')
  //$("#projectVersion").val("");
  $('#projectEnabled').removeAttr('checked')
  $('#projectEnabled').removeAttr('disabled')

  $('#add-project-button').removeAttr('disabled')

  if (productLock == 'on' || productLock.toLowerCase() == 'locked') {
    $('#projectEnabled').attr('disabled', 'disabled')
    $('#set-project-status').text('This Product is locked and limited to adding disabled services')
  } else {
    $('#projectEnabled').removeAttr('disabled')
    $('#set-project-status').text('')
  }

  $('#Product-Settings-Modal').modal('hide')
  $('#Set-Project-Modal').modal('show')
}

function closeConnectionModals() {
  // Clear the active device from memory as we're closing
  // the modal
  globalActiveDeviceID = ''

  // Close the actual modals
  $('#HTTP-Connection-Modal').modal('hide')
  $('#TCP-Connection-Modal').modal('hide')
  $('#SSH-Connection-Modal').modal('hide')
  $('#VNC-Connection-Modal').modal('hide')
}

function showServicesModal(deviceID) {
  deviceID = deviceID || globalActiveDeviceID
  globalActiveDeviceID = deviceID

  var device = rState.getDevice(deviceID)
  var isShared = device.Shared === 'shared-from'

  window.remot3it.store.dispatch.devices.setSelection(device)
  // Don't show the connection IP restriction checkbox
  // if the user doesn't own the device.
  var msg = $('#connection-ip-restriction')
  isShared ? msg.addClass('hidden') : msg.removeClass('hidden')

  closeConnectionModals()

  $('#disable-ip-restriction').prop('checked', false)
  $('#device-services-confirm').attr('disabled', 'disabled')
  $('#device-services-selection-status').html('')
  $('#device-services-selection-status').hide()
  $('#device-connecting-status').hide()
  $('#Device-Services-Modal').modal('show')
  $('#connection-error').remove()
  $('#device-service-table tbody tr').remove()
  $('#device-services-table tbody tr').remove()
  $('#connection-note').remove()

  var table = $('#device-services-table').DataTable()
  table.destroy(true)

  serviceIDs = findServiceIDsByHWID(device.HWID)

  $('<table>')
    .attr({
      id: 'device-services-table',
      class: 'table table-hover footable',
      'data-sorting': 'true',
      'data-expand-first': 'true',
      'data-filter-dropdown-title': 'Filter in:',
      'data-filter-placeholder': 'Filter',
      'data-filtering': 'true',
      'data-paging': 'true',
      'data-paging-size': '6',
    })
    .appendTo('[id="device-services-div"]')

  $('#device-services-table').append(
    '<thead><tr>' +
      "<th data-type='html'>Status</th>" +
      "<th data-type='html'>Service</th>" +
      "<th data-type='text'>Application</th>" +
      "<th data-type='text'>Port</th>" +
      "<th data-ignore='true' data-hide='all'>Service ID</th>" +
      '</tr></thead><tbody></tbody>'
  )

  for (var i = 0; i < serviceIDs.length; i++) {
    // find the service row
    var svc = findService(serviceIDs[i])

    if (!svc) continue

    if (svc != false && svc.devicetype && svc.devicetype.substring(0, 5) != '00:23') {
      item = rState.getService(serviceIDs[i])

      if (item) {
        var port = window.remot3it.helpers.parseServiceType(svc.devicetype).port
        if (device.license === 'UNLICENSED') {
          var status = item.Status === 'active' || item.Status === 'connected' ? 'Online' : 'Offline'
          $('#device-services-table > tbody:last-child').append(
            "<tr id='dst" +
              svc.deviceaddress.replace(/:/g, '') +
              '\'><td><i class="fal fa-exclamation-triangle fa-lg warning-dark" title=" ' +
              ' Device ' +
              status +
              ' " align="center"></i></td><td class=\'name\'>' +
              item.ServiceName +
              '</td><td>' +
              svc.servicetitle +
              '</td><td class="notranslate">' +
              +(port || '') +
              '</td><td>' +
              svc.deviceaddress +
              '</td></tr>'
          )
        } else if (item.Status === 'active' || item.Status === 'connected') {
          $('#device-services-table > tbody:last-child').append(
            "<tr id='dst" +
              svc.deviceaddress.replace(/:/g, '') +
              "'><td><button class='link' onclick=\"handleShowConnectionModal('" +
              svc.deviceaddress +
              "','" +
              deviceID +
              '\');"><i class="fal fa-check-circle fa-lg" data-toggle="modal" data-placement="top" title="Ready to Connect" align="center"></i> </button></td><td  class=\'name\'><button class="link" onclick="handleShowConnectionModal(\'' +
              svc.deviceaddress +
              "','" +
              deviceID +
              '\');">' +
              item.ServiceName +
              '</button></td><td>' +
              svc.servicetitle +
              '</td><td>' +
              (port || '') +
              '</td><td style="display:none;" class="notranslate">' +
              svc.deviceaddress +
              '</td><td></td></tr>'
          )
        } else {
          $('#device-services-table > tbody:last-child').append(
            "<tr id='dst" +
              svc.deviceaddress.replace(/:/g, '') +
              '\'><td><button class="link"><i class="fal fa-minus-circle fa-lg gray-lighter" data-toggle="modal" data-placement="top" title="Device Offline" data-target="#DeviceOfflineWarning" align="center"></i></button></td><td class=\'name\'>' +
              item.ServiceName +
              '</td><td>' +
              svc.servicetitle +
              '</td><td class="notranslate">' +
              +(port || '') +
              '</td><td>' +
              svc.deviceaddress +
              '</td></tr>'
          )
        }
      } else {
        console.log('items not found:' + serviceIDs[i])
      }
    } else if (svc != false && svc.devicetype.substring(0, 5) == '00:23') {
      var item = rState.getDevice(serviceIDs[i].toUpperCase())
      var setNameLink = ''
      if (item.license === 'UNLICENSED') {
        $('#device-service-table > tbody:last-child').append(
          "<tr id='dt" +
            svc.deviceaddress.replace(/:/g, '') +
            '\'><td><i class="fal fa-exclamation-triangle fa-lg warning-dark" data-toggle="modal" data-placement="top" title="Ready to Connect" align="center"></i> </td><td  class=\'name\'>' +
            item.ServiceName +
            '</td><td>' +
            svc.servicetitle +
            '</td><td style="display:none;">' +
            svc.deviceaddress +
            '</td><td>' +
            setNameLink +
            '</td></tr>'
        )
      } else if (item.Status === 'active') {
        if (!isShared) {
          setNameLink =
            '<i class="fal fa-pencil-alt fa-lg" style="color:#7AB7E6; cursor:pointer" data-toggle="modal" data-placement="top" title="Edit Name" align="center" onclick="showChangeNameModal(\'' +
            svc.deviceaddress +
            "','" +
            item.ServiceName +
            "','device');\"></i>"
        }

        $('#device-service-table > tbody:last-child').append(
          "<tr id='dt" +
            svc.deviceaddress.replace(/:/g, '') +
            '\'><td><i class="fal fa-check-circle fa-lg" data-toggle="modal" data-placement="top" title="Ready to Connect" align="center"></i> </td><td  class=\'name\'>' +
            item.ServiceName +
            '</td><td>' +
            svc.servicetitle +
            '</td><td style="display:none;">' +
            svc.deviceaddress +
            '</td><td>' +
            setNameLink +
            '</td></tr>'
        )
      } else {
        if (!isShared) {
          setNameLink =
            '<i class="fal fa-pencil-alt fa-lg" style="color:#7AB7E6; cursor:pointer" data-toggle="modal" data-placement="top" title="Edit Name" align="center" onclick="showChangeNameModal(\'' +
            svc.deviceaddress +
            "','" +
            item.ServiceName +
            "','device');\"></i>"
        }

        $('#device-service-table > tbody:last-child').append(
          "<tr id='dt" +
            svc.deviceaddress.replace(/:/g, '') +
            '\'><td><i class="fal fa-minus-circle fa-lg gray-lighter" data-toggle="modal" data-placement="top" title="Device Offline" data-target="#DeviceOfflineWarning" align="center"></i></td><td class=\'name\'>' +
            item.ServiceName +
            '</td><td>' +
            svc.servicetitle +
            '</td><td style="display:none;">' +
            svc.deviceaddress +
            '</td><td>' +
            setNameLink +
            '</td></tr>'
        )
      }
    }
  }

  $('#device-services-table').footable()
  $('#device-services-div').after(
    '<div id="connection-note"><p><strong>Note on connections:</strong>  From the remote.it portal, connections will either be IP restricted or reverse proxy (HTTP/Secure Web).</p>' +
      '<p><strong>Reverse proxy:</strong> For security purposes, this will be a randomly generated text string value for each connection, making it highly unlikely that anyone will discover the link. Once the connection closes, the link will no longer be valid, even to you.</p></div>'
  )
  // load the table
  $('#device-services-selected-name').html(device.ServiceName)
}

/**
 * A simple pass-through method so we can track separately
 * the clicking on the service modal connection links and the
 * new Quick Connect button to compare usage.
 */
function handleShowConnectionModal(serviceID, deviceID) {
  var service = rState.getService(serviceID)
  var name = service.ServiceName
  var type = window.remot3it.helpers.parseServiceType(service.ServiceType).id
  var platformCode = window.remot3it.helpers.getPlatformCode(service.ServiceType)
  if (platformCode && platformCode === awsCode) {
    window.location.href = 'remoteit://connect/' + serviceID
    $('#Device-Services-Modal').modal('hide')
    return showAWSModal()
  } else {
    window.remot3it.helpers.track('connectionInitiated', {
      label: 'deviceDetailModal',
      serviceID: serviceID,
      serviceName: name,
      serviceType: type,
      connectionType: 'Proxy',
    })
    return showConnectionModal(serviceID, name, service.ServiceType, deviceID)
      .then(function() {
        window.remot3it.helpers.track('connectionSucceeded', {
          label: 'deviceDetailModal',
          serviceID: serviceID,
          serviceName: name,
          serviceType: type,
          connectionType: 'Proxy',
        })
      })
      .catch(function(error) {
        window.remot3it.helpers.track('connectionFailed', {
          label: 'deviceDetailModal',
          error: error.message,
          serviceID: serviceID,
          serviceName: name,
          serviceType: type,
          connectionType: 'Proxy',
        })
      })
  }
}

function showConnectionModal(serviceID, name, type, deviceID, platform) {
  launchDeviceServiceAddress = serviceID
  launchDeviceServiceType = type
  launchDeviceServiceName = name
  deviceServiceAction = 'launch'
  globalActiveDeviceID = deviceID

  // Cleanup UI state before making the connection
  $('#device-services-confirm').removeAttr('disabled')
  $('#device-services-selection-status').hide()
  $('#device-connecting-status').show()
  $('#device-services-confirm').attr('disabled', 'disabled')
  return deviceServiceLaunch('true')
}

function verifyEmail(email) {
  startSpinner()

  $('#signup-fail').html('')
  $('#verificationCode').val('')
  $('#signup_password').val('')
  $('#signup_password_confirm').val('')

  status = weaved.userCreateVerify(email)
}

function completeSignup(email, code, password, password_confirm) {
  if ($('#signupCheckbox').prop('checked')) {
    if (password == password_confirm) {
      $('#signup-fail').html('')
      startSpinner()
      status = weaved.userCreateVerified(email, code, password)
    } else {
      $('#signup-fail').html('The passwords do not match')
    }
  } else {
    $('#signup-fail').html('You must accept the Terms of Use and Software Agreement')
  }
}

function doJobAction() {
  if ($('#confirmCancelJob').prop('checked')) {
    startSpinner()

    if (jobAction == 'Cancel') {
      status = weaved.bulkJobCancel(jobidForAction)
    } else if (jobAction == 'Delete') {
      status = weaved.bulkJobDelete(jobidForAction)
    }
  } else {
    $('#job-detail-status').html('You must confirm the job action')
  }
}

function showAcceptInvitationModal() {
  //$("#file-confirmation-message").html("");
  //$("#content-modal-title").html(capitalize(which) + " Storage");
  $('#invitation-accept-button').removeAttr('disabled')
  $('#Invitation-Modal').modal('show')
}

function showContentModal(which) {
  whichContent = which

  contentMode = 'display'
  $('#file-confirmation-message').html('')
  $('#content-modal-title').html(capitalize(which) + ' Storage')
  $('#file-confirm').attr('disabled', 'disabled')
  $('#files-modal').modal('show')

  weaved.getContent(whichContent)
}

function doCreateOpenRegistration() {
  // check for one product selection and abort if needed
  //public = $('#publicProductSelectionList option:selected').html();
  mine = $('#myProductSelectionList option:selected').html()
  //code = $("#privateProductCode").val();

  var name = checkEncodedChar($('#openRegistrationName').val())

  $('#upload-error-div').hide()

  //alert(public);
  //alert(mine);

  //pub_selected = (public.indexOf("public product") == -1) ? 1 : 0;
  min_selected = mine.indexOf('private product') == -1 ? 1 : 0
  //code_entered = (code.length > 0) ? 1 : 0;

  total = min_selected //pub_selected + min_selected + code_entered;

  //alert(total);
  if (name.length == 0) {
    $('#upload-error-div').show()
    $('#create-open-registration-status').html('You must enter a name for your new Open Registration')
  } else if (!isValidName(name)) {
    $('#upload-error-div').show()
    $('#create-open-registration-status').html(
      'Please enter a valid registration name. Registration names must be unique and only contain alphanumeric, -, _ and space characters'
    )
  } else if (total != 1) {
    $('#upload-error-div').show()
    $('#create-open-registration-status').html('You must select a single Product to continue with Open Registration')
  } else {
    $('#openRegistrationSubmit').attr('disabled', 'true')

    //product = public.indexOf("public product") == -1 ? $('#publicProductSelectionList').val() : "";
    product = mine.indexOf('private product') == -1 ? $('#myProductSelectionList').val() : product
    //product = code.length > 0 ? code : product;

    //alert(product);

    weaved.createRegistrationType(name, product, 'open')
  }
}

function doCreateProduct() {
  $('#createProductSubmit').attr('disabled', 'true')

  //alert($("#openRegistrationName").val());
  //alert($("#openRegistrationPlatformSelectionList").val());

  var ok = true

  var encodedProductName = checkEncodedChar($('#createProductName').val())
  if (encodedProductName.length == 0) {
    alert('Please enter Product Name.')
    ok = false
  } else if ($('#createProductPlatformSelectionList').val() == 'clear') {
    alert('Please select Product Platform.')
    ok = false
  } else if ($('#createProductScope').val() == '-') {
    alert('Please select the Product Scope.')
    ok = false
  }

  if (!isValidName(encodedProductName)) {
    alert(
      'Please enter a valid product name. Product names must be unique and only contain alphanumeric, -, _ and space characters'
    )
    ok = false
  }

  var ft = FooTable.get('#products-table')
  $.each(ft.rows.all, function(i, row) {
    v = row.val()
    //alert(v.col1.toLowerCase());
    if (
      v.col1.toLowerCase() ==
      $('#createProductName')
        .val()
        .toLowerCase()
    ) {
      alert('Please enter unique Product Name.')
      ok = false
      return
    }
  })

  if (ok) {
    weaved.createProduct(
      encodedProductName,
      $('#createProductPlatformSelectionList').val(),
      $('#createProductScope').val()
    )
  } else {
    $('#createProductSubmit').removeAttr('disabled')
  }
}

function isValidEmailAddress(emailAddress) {
  var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
  return pattern.test(emailAddress)
}

function checkEncodedChar(name) {
  var encodedStr = name.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
    return '&#' + i.charCodeAt(0) + ';'
  })
  return encodedStr.replace(/&/gim, '&amp;')
}

function sanitizeAndDecode(name) {
  var sanitized = checkEncodedChar(name)
  var el = document.createElement('div')
  el.innerHTML = sanitized
  return el.textContent
}

function isValidName(encodedName) {
  var reg = /^[a-zA-Z0-9-_ ]*$/
  return reg.test(encodedName)
}

function isValidGroupName(encodedName) {
  var reg = /^[a-zA-Z0-9-_. ]*$/
  return reg.test(encodedName)
}

function doCreateCustomer() {
  var validated = false

  var compName = checkEncodedChar($('#createCustomerCompanyName').val())
  var firstName = checkEncodedChar($('#createCustomerFirstName').val())
  var lastName = checkEncodedChar($('#createCustomerLastName').val())
  if (!isValidGroupName(compName)) {
    alert('Please enter a valid group name. Group names must only contain alphanumeric, -, _, . and space characters')
    validated = false
  } else if (!isValidName(firstName)) {
    alert('Please enter a valid first name. First names must only contain alphanumeric, -, _ and space characters')
    validated = false
  } else if (!isValidName(lastName)) {
    alert('Please enter a valid last name. Last names must only contain alphanumeric, -, _ and space characters')
    validated = false
  } else if (!isValidEmailAddress($('#createCustomerEmail').val())) {
    alert('Please enter a valid email address')
    validated = false
  } else {
    validated = true
  }

  if (validated) {
    $('#createCustomerSubmit').attr('disabled', true)
    weaved.createCustomer(
      $('#createCustomerCompanyName').val(),
      $('#createCustomerFirstName').val(),
      $('#createCustomerLastName').val(),
      $('#createCustomerEmail')
        .val()
        .trim()
        .toLowerCase(),
      $('#customerLanguage').val()
    )
  } else {
    $('#createCustomerSubmit').attr('disabled', false)
  }
}

function doCreateAccount() {
  if ($('#confirmAccountCreate').prop('checked')) {
    $('#accountCreateButton').attr('disabled', 'true')

    window.remot3it.r3
      .post('/developer/customer/sendinvite', {
        email: customerEmail.toLowerCase().trim(),
        origin: window.location.origin,
      })
      .then(function(response) {
        console.error(response)
        if (response.status == 'true') {
          $('#accountEmail').val('')
          $('#createAccountPassword').val('')
          $('#Account-Create-Modal').modal('hide')
          weaved.getContacts()
        } else {
          alert('Account create completed with some errors.')
        }
      })
  } else {
    $('#account-create-status').html('You must confirm the account creation')
  }
}

function doAddProject() {
  // validate the form
  if ($('#projectName').val().length == 0) {
    alert('Service name is required')
    return
  }
  if ($('#projectContentPort').val().length == 0) {
    alert('Content port is required and must be a number')
    return
  }

  $('#add-project-button').attr('disabled', 'true')

  //alert($("#projectContentType").val());
  //alert($("#projectName").val());
  //alert($("#projectContentIP").val());
  //alert($("#projectContentPort").val());
  //alert($("#projectEnabled").prop('checked'));

  //(bulkid, type, name, localip, localport, version, enabled, sync)

  weaved.addProductProject(
    productID,
    $('#projectContentType').val(),
    $('#projectName').val(),
    $('#projectContentPort').val(),
    $('#projectVersion').val(),
    $('#projectEnabled').prop('checked') ? '1' : '0'
  )
}

function doSetBulkService() {
  $('#set-bulk-service-button').attr('disabled', 'true')

  weaved.setBulkServiceConfiguration(
    bulkID,
    projectID,
    $('#setServiceContentIP').val(),
    $('#setServiceContentPort').val(),
    $('#setServiceEnabled').prop('checked') ? '1' : '0'
  )
}

function showChangeNameModal(deviceaddress, devicealias, changetype) {
  if (changetype === 'device') {
    item = rState.getDevice(deviceaddress.toUpperCase())
    $('#setDeviceName').val(item.service)
  }

  $('#set-name-button').removeAttr('disabled')
  $('#Device-Services-Modal').modal('hide')
  $('#set-name-status').hide()
  $('#set-name-status').html('')
  $('#Set-Name-Modal').modal('show')

  // Ugh, globals...
  nameChange = changetype
  nameChangeAddress = deviceaddress
  globalActiveDeviceID = deviceaddress
}

function showSharingModal(deviceaddress, devicename) {
  window.remot3it.store.dispatch.shares.openDialog({
    id: deviceaddress,
    name: devicename,
  })

  DEVICE_LIST.find('tbody tr').each(function() {
    if ($(this).attr('id') === deviceaddress) {
      $(this).addClass('success')
    }
  })
}

function showRejectSharingModal(deviceaddress, devicename) {
  $('#remove-share-device-button').removeAttr('disabled')
  $('#remove-share-fail').hide()
  $('#remove-share-fail').html('')
  $('#shareEmails').val('')
  $('#remove-share-progress-status').hide()

  $('#confirmRemoveShare').removeAttr('checked')

  $('#Remove-Share-Device-Modal').modal('show')
  $('#Remove-Share-Device-Name').html(devicename)
  if (deviceaddress) {
    shareDeviceAddress = deviceaddress
  }
  shareDeviceName = devicename
  shareAction = 'remove'
}

function showStatusBlobModal(blob, filterList) {
  statusBlob = blob
  statusBlobFilter = filterList

  updateBlob('')
}

function showAdvancedFeatures(flag) {
  if (flag) {
    DEVICE_LIST.jqGrid('showCol', [
      'StatusA',
      'StatusB',
      'StatusC',
      'StatusD',
      'StatusE',
      'CategoryA',
      'CategoryB',
      'CategoryC',
    ])
  } else {
    DEVICE_LIST.jqGrid('hideCol', [
      'StatusA',
      'StatusB',
      'StatusC',
      'StatusD',
      'StatusE',
      'CategoryA',
      'CategoryB',
      'CategoryC',
    ])
  }
}

function updateBlob(formatOverride) {
  blob = statusBlob
  filterList = statusBlobFilter

  var data = Base64.decode(blob).trim()

  if (!data) return alert('Your attachment is empty!')

  jData = JSON.parse(data)

  // filter if requested  --> fileList,fileName(_license.zip),fileType
  if (filterList.length > 0) {
    colFilters = filterList.split(',')
    if (colFilters.length > 0) {
      viewType = colFilters[0]
      if (formatOverride.length > 0) {
        viewType = formatOverride
      }

      //alert(filterList);

      tableName = colFilters[1]

      //alert(JSON.stringify(jData[tableName]));

      rowFilter = colFilters[2]
      if (rowFilter.indexOf('(') > -1) {
        s = rowFilter.indexOf('(', rowFilter)
        e = rowFilter.indexOf(')', s)
        filter = rowFilter.substring(s + 1, e).trim()
        rowFilterColumnName = rowFilter.substring(0, s).trim()
      } else {
        filter = '*'
        rowFilterColumnName = rowFilter
      }

      //alert(" view:"+viewType+" table:"+tableName+" rowFilter:"+rowFilter+" filterColumn:"+rowFilterColumnName+" filterValue:"+filter);

      if (filter != '' && filter != '*') {
        //alert("filtering rows");

        jData = $.grep(jData[tableName], function(item, index) {
          /*
                           status = false;
                           if (filter == "" || filter == "*") {
                                alert("no filter");
                                status = true;
                           } else {
                                status = (item[rowFilterColumnName].indexOf(filter) > -1);
                           }
                           alert(item[rowFilterColumnName]+":"+filter+":"+status+":"+item[rowFilterColumnName].indexOf(filter));
                           return status;
                            */

          //alert(item[rowFilterColumnName]);

          return item[rowFilterColumnName].indexOf(filter) > -1
        })

        //alert("filter 1"+JSON.stringify(jData));
      } else {
        jData = jData[tableName]
      }

      //alert("data:"+JSON.stringify(jData));

      // filter columns
      var fData = []
      for (i = 0; i < jData.length; i++) {
        var rowdata = {}
        for (var name in jData[i]) {
          if (filterList.indexOf(name) > -1) {
            rowdata[name] = jData[i][name]
          }
        }

        //alert(JSON.stringify(rowdata));

        fData.push(rowdata)
      }

      jData = fData

      //alert("filter 2:"+JSON.stringify(jData));

      $.makeTable = function(jData) {
        var table = $('<table class="table table-stripped toggle-square-filled">')
        var tblHeader = '<tr>'
        for (var k in mydata[0]) tblHeader += '<th>' + k + '</th>'
        tblHeader += '</tr>'
        $(tblHeader).appendTo(table)
        $.each(mydata, function(index, value) {
          var TableRow = '<tr>'
          $.each(value, function(key, val) {
            TableRow += '<td>' + val + '</td>'
          })
          TableRow += '</tr>'
          $(table).append(TableRow)
        })
        return $(table)
      }

      if (viewType == 'table') {
        $('#jsonblob').html('')
        var mydata = eval(jData)
        var table = $.makeTable(mydata)
        $(table).appendTo('#jsonblob')
      } else {
        $('#jsonblob').JSONView(jData)
      }
    }
  } else {
    //$("#jsonblob").JSONView(JSON.parse(Base64.decode(blob)));
    $('#jsonblob').JSONView(jData)
  }

  $('#status-blob-modal').modal('show')
}

function launch(status) {
  $('#Launch-Modal').modal('hide')
  //$('.modal-backdrop').hide();

  if (status == 'true') {
    startSpinner()
    weaved.connect(launchAddress, launchType, launchName)
  } else {
    launchAddress = ''
    launchType = ''
    launchName = ''
  }
}

function deviceServiceLaunch(status) {
  $('#Device-Service-Launch-Modal').modal('hide')
  //     $('.modal-backdrop').hide();
  return new Promise(function(resolve) {
    if (status === 'true') {
      //startSpinner();
      weaved
        .connect(launchDeviceServiceAddress, launchDeviceServiceType, launchDeviceServiceName)
        .catch(resolve)
        .then(resolve)
    } else {
      launchDeviceServiceAddress = ''
      launchDeviceServiceType = ''
      launchDeviceServiceName = ''

      showServicesModal(deviceID)
      resolve()
    }
  })
}

function stopWait() {
  $('#Bulk-Wait-Modal').modal('hide')
  //$('.modal-backdrop').hide();
  stopSpinner()

  // if job is done restore checkboxes
  if (jobDone == true) {
    for (i = 0, count = selectedRows.length; i < count; i++) {
      DEVICE_LIST.jqGrid('setSelection', selectedRows[i], false)
    }
  }
}

function confirmDeleteContent(key, name) {
  //alert("confirm");
  deleteContentKey = key
  deleteContentName = name
  $('#file-confirm').removeAttr('disabled')
  $('#file-confirmation-message').html("Press Confirm button to permanently remove file '" + name + "'")
}

function confirmContentAction() {
  window.remot3it.r3.scripts.destroy(deleteContentKey).then(function(result) {
    if (result == 'true') {
      $('#file-confirmation-message').html('')
      deleteContentKey = ''
      deleteContentName = ''

      // refresh the content modal
      showContentModal(whichContent)

      setTimeout(function() {
        weaved.bulkScriptHeaders()
      }, 500)
    } else {
      $('#file-confirmation-message').html('There was an unexpected problem delting the file. Please try again.')
    }
  })
}

function initExecutionModal() {
  executeStep = 0

  //alert("startover");

  $('#scriptSelectionDiv').show()
  $('#noScriptingPermission').hide()
  $('#fileSelectionDiv').hide()
  $('#multifileSelectionDiv').hide()
  $('#itemSelectionDiv').hide()
  $('#itemMultipleSelectionDiv').hide()
  $('#executeParam').hide()
  $('#wizardConfimrationDiv').hide()

  $('#wizardNextBtn')
    .text('Next')
    .data('state', 'next')

  $('#wizardNexthBtn').prop('disabled', false)

  $('#bulk-execute-status').html('')

  $('#wizardSteps').html('Select the script first to determine remaining steps')

  //Determine if you have permissoin on all devices
  var selectedDeviceIds = getSelectedRowsIDs()
  var devicesWithoutScripting = []
  var deviceListString = ''
  var deviceListUnlicensedString = []
  var deviceListUnlicensed = []
  rState.selectedServiceList = []

  selectedDeviceIds.forEach(function(deviceId) {
    var device = rState.getDevice(deviceId)
    if (device.license === 'UNLICENSED') {
      deviceListUnlicensed.push(device)
      deviceListUnlicensedString.push(device.ServiceName)
    } else if (device.Scripting) {
      rState.selectedServiceList.push(deviceId)
    } else {
      if (devicesWithoutScripting.length > 6) {
        // Device List string is getting to long, so we only want to show a partial list
        deviceListString = deviceListString + '...'
        return
      }
      if (devicesWithoutScripting.length > 0) {
        deviceListString = deviceListString + ', ' + device.ServiceName
      } else {
        deviceListString = deviceListString + device.ServiceName
      }
      devicesWithoutScripting.push(device)
    }
  })

  var nextDisabled = false
  var noScriptingText = ''
  if (devicesWithoutScripting.length > 0 || deviceListUnlicensed.length > 0) {
    noScriptingText =
      'Unable to run scripts on the following devices because you do not have scripting permission or the devices are unlicensed: <br/><br/>'
    if (devicesWithoutScripting.length === selectedDeviceIds.length) {
      nextDisabled = true
    } else if (devicesWithoutScripting.length > 1) {
      noScriptingText += deviceListString
    }
    if (deviceListUnlicensed.length > 0) {
      noScriptingText += deviceListUnlicensedString.join().replace(/,\s*$/, '')
    }
    noScriptingText += '<i/>'
    $('#noScriptingText').html(noScriptingText)
    $('#noScriptingPermission').show()
  }

  executeWithFile = false
  executeWithMultipleFiles = false
  executeWithMultipleFileSelect = false
  executeWithParam = false
  executeStep = 0
  scriptSelected = false

  executeHeaderSteps = []
  excuteCommandString = ''

  executeResponses = []

  $('#wizardNextBtn').prop('disabled', nextDisabled)
  $('#Script-Execution-Modal').show()
}

function OLD_setupScriptSelected(script) {
  //alert("script selected:"+script);

  id = Base64.encode(userid + script)

  // default hide extra options
  executeWithFile = false
  executeWithMultipleFiles = false
  executeWithMultipleFileSelect = false
  executeWithParam = false
  excuteCommandString = ''

  // find the script header
  if (scriptHeaders.length > 0) {
    //alert(scriptHeaders);

    found = false
    headers = JSON.parse(scriptHeaders)
    for (i = 0; i < headers.items.length; i++) {
      if (id == headers.items[i].id) {
        hdr = headers.items[i].header

        // detect older version of scripts
        hdr = hdr.replace('_file(', '-f(')
        hdr = hdr.replace('_param(', '-s(')

        //alert("hdr:"+hdr);

        if (hdr.indexOf(',') > -1) {
          executeHeaderSteps = hdr.split(',')
        } else {
          executeHeaderSteps = hdr.split(' ')
        }
        executeHeaderSteps.splice(0, 1)
        found = true
      }
    }

    if (!found) {
      //alert ("There's no r3_header in the script.  It will only execute without files & parameters until upgraded.");
    }

    executeHeaderSteps.splice(0, 0, '_script()')

    //alert("steps:"+JSON.stringify(executeHeaderSteps));
  } else {
    alert('no script headers')
  }
}

function setupScriptSelected(script) {
  var userID = window.remot3it.store.getState().auth.user.id
  id = Base64.encode(userID + script)

  // default hide extra options
  executeWithFile = false
  executeWithMultipleFiles = false
  executeWithMultipleFileSelect = false
  executeWithParam = false
  excuteCommandString = ''

  scriptExpiration = '20160'
  tallyOption = ''

  // find the script header
  if (scriptHeaders.length > 0) {
    //alert(scriptHeaders);

    found = false
    headers = JSON.parse(scriptHeaders)
    for (i = 0; i < headers.items.length; i++) {
      if (id == headers.items[i].id) {
        hdr = headers.items[i].header

        //alert("orig header:"+hdr);

        hdrList = hdr.split(',')

        //alert("hdrs:"+JSON.stringify(hdrList));

        newHdr = ''
        skipCount = 0
        for (j = 0; j < hdrList.length; j++) {
          // check for hidden headers
          if (hdrList[j].indexOf('-t') > -1) {
            //alert("save tally spec");
            s = hdrList[j].indexOf('-t')
            s = hdrList[j].indexOf('(', s)
            e = hdrList[j].indexOf(')', s)
            tallyOption = hdrList[j].substring(s + 1, e).trim()
            skipCount = skipCount + 1
          } else if (hdrList[j].indexOf('-e') > -1) {
            //alert("save expiration");
            s = hdrList[j].indexOf('-e')
            s = hdrList[j].indexOf('(', s)
            e = hdrList[j].indexOf(')', s)
            scriptExpiration = hdrList[j].substring(s + 1, e).trim()
            skipCount = skipCount + 1
          } else {
            newHdr = newHdr + hdrList[j] + ','
          }
        }

        if (newHdr.indexOf(',') > -1) {
          newHdr = newHdr.substring(0, newHdr.lastIndexOf(','))
          //newHdr = newHdr.replace(/,$/, "");
        }

        hdr = newHdr.trim()

        // detect older version of scripts
        hdr = hdr.replace('_file(', '-f(')
        hdr = hdr.replace('_param(', '-s(')

        //alert("basic hdr:"+hdr);

        if (hdr.indexOf(',') > -1) {
          executeHeaderSteps = hdr.split(',')
        } else {
          executeHeaderSteps = hdr.split(' ')
        }

        //alert("steps length:"+executeHeaderSteps.length);
        //alert("pre step:"+JSON.stringify(executeHeaderSteps));

        // look for a new of now headers
        if (executeHeaderSteps.length == 1) {
          executeHeaderSteps = []
          found = false
        } else {
          executeHeaderSteps.splice(0, 1)
          found = true
        }

        //alert("final step:"+JSON.stringify(executeHeaderSteps));
      }
    }

    if (!found) {
      //alert ("There's no r3_header in the script.  It will only execute without files & parameters until upgraded.");
    }

    executeHeaderSteps.splice(0, 0, '_script()')

    //alert("steps:"+JSON.stringify(executeHeaderSteps));
  } else {
    alert('no script headers')
  }
}

function nextExecutionStep() {
  //alert(executeStep + " of " + executeHeaderSteps.length);
  $('#bulk-execute-status').html('')
  if ($('#wizardNextBtn').data('state') === 'finish') {
    validateStep()
    finishExecution()
    return
  } else if ($('#wizardNextBtn').data('state') === 'submit') {
    // alert("headers:"+JSON.stringify(executeHeaderSteps));
    // alert("responses:"+JSON.stringify(executeResponses));

    script = ''
    scriptName = ''
    command = ''
    for (i = 0; i < executeHeaderSteps.length; i++) {
      type = executeHeaderSteps[i]
      if (type.indexOf('_script') > -1) {
        script = executeResponses[i] + ' '
        scriptName = executeResponses[i].split('/').pop()
        if (scriptName.indexOf('?') > -1) {
          scriptName = scriptName.substring(0, scriptName.indexOf('?'))
        }
      } else if (type.indexOf('-f') > -1) {
        name = executeResponses[i].split('/').pop()
        if (name.indexOf('?') > -1) {
          name = name.substring(0, name.indexOf('?'))
        }
        command = command + '-f "' + executeResponses[i] + ' -O ' + name + '" '
      } else if (type.indexOf('-m') > -1) {
        items = executeResponses[i].split(',')
        for (j = 1; j < items.length; j++) {
          name = items[j].split('/').pop()
          if (name.indexOf('?') > -1) {
            name = name.substring(0, name.indexOf('?'))
          }
          command = command + '-m "' + items[j] + ' -O ' + name + '" '
        }
      } else if (type.indexOf('-s') > -1) {
        command = command + '-s "' + Base64.encode(executeResponses[i]) + '" '

        //alert(command);
      } else if (type.indexOf('-l') > -1) {
        command = command + '-l "' + executeResponses[i] + '" '
      }
    }

    // add hidden parameters
    postJobFunction = ''
    postJobOptions = ''
    jobExpiration = ''
    if (scriptExpiration.length > 0) {
      command = command + '-e "' + scriptExpiration + '" '
      jobExpiration = scriptExpiration
    }

    if (tallyOption.length > 0) {
      postJobFunction = 'tally'
      postJobOptions = tallyOption
    }

    submitExecution(
      script,
      scriptName,
      command,
      jobExpiration,
      postJobFunction,
      postJobOptions,
      rState.selectedServiceList
    )

    $('#wizardNextBtn').prop('disabled', false)
    $('#Script-Execution-Modal').modal('hide')

    return
  }

  processExecutionStep()
}

function validateStep() {
  if (executeStep == 0) {
    // handle script selection

    //alert("script selected");
    setupScriptSelected(
      $('#scriptSelectionList')
        .find(':selected')
        .text()
    )
    executeResponses[executeStep] = $('#scriptSelectionList')
      .find(':selected')
      .val()

    //alert(JSON.stringify(executeHeaderSteps));

    if (executeHeaderSteps.length == 1) {
      $('#bulk-execute-status').html('There are no other script parameters required.')
      $('#wizardNextBtn')
        .text('Finish')
        .data('state', 'finish')
    }
  } else {
    //alert("validateStep: step " + executeStep + " of " + executeHeaderSteps.length);
    //alert("add steps to record responses per header type");
    curr = executeHeaderSteps[executeStep]
    if (next.indexOf('-f') > -1) {
      tmp = $('#executeURL').val()
      if (tmp.length > 0) {
        executeResponses[executeStep] = tmp
      } else {
        $('#bulk-execute-status').html('You must select a file or enter a URL to proceed')
        return false
      }

      /*
            tmp = $("#fileSelectionList").find(":selected").val();
            if (tmp.length > 0) {
                executeResponses[executeStep] = tmp;
            } else {
                $("#bulk-execute-status").html('You must select an item to proceed');
                return false;
            }
             */
    } else if (next.indexOf('-m') > -1) {
      list = ''
      $('#multifileSelectionList option:selected').each(function() {
        var $this = $(this)
        if ($this.length) {
          list = list + ',' + $this.val()
        }
      })

      if (list.length > 0) {
        executeResponses[executeStep] = list
      } else {
        $('#bulk-execute-status').html('You must select one or more items to proceed')
        return false
      }

      //alert(list);
    } else if (next.indexOf('-s') > -1) {
      //alert("process string");
      tmp = $('#executeParam').val()
      if (tmp.length > 0) {
        executeResponses[executeStep] = tmp
      } else {
        $('#bulk-execute-status').html('You must enter a value to proceed')
        return false
      }
    } else if (next.indexOf('-l') > -1) {
      //alert("process list");

      if (listSelectMultiple) {
        list = 'itemMultipleSelectionList'
        tmp = $(list)
          .find(':selected')
          .val()
      } else {
        list = 'itemSelectionList'
      }

      tmp = $('#' + list)
        .find(':selected')
        .val()
      if (tmp.length > 0) {
        theList = ''
        $('#' + list + ' option:selected').each(function() {
          theList = theList + $(this).val() + ','
        })

        if (theList.lastIndexOf(',') == theList.length - 1) {
          theList = theList.substring(0, theList.length - 1)
        }

        executeResponses[executeStep] = theList
      } else {
        $('#bulk-execute-status').html('You must select an item to proceed')
        return false
      }
    }
  }

  $('#bulk-execute-status').html('')

  return true
}

function processExecutionStep() {
  //alert("current step:"+executeStep);

  if (!validateStep()) {
    return
  }

  // start the next step
  executeStep++

  //alert(JSON.stringify(executeHeaderSteps));

  if (executeHeaderSteps.length > 1) {
    next = executeHeaderSteps[executeStep]

    title = ''
    stepDescription = ''

    if (next.indexOf('_script') > -1) {
      initExecutionModal()
    } else if (next.indexOf('-f') > -1) {
      stepDescription = 'Select a file'
      title = 'File Select'

      $('#fileSelectionDiv').show()
      $('#scriptSelectionDiv').hide()
      $('#multifileSelectionDiv').hide()
      $('#executeParam').hide()
      $('#itemSelectionDiv').hide()
      $('#itemMultipleSelectionDiv').hide()
      $('#wizardConfimrationDiv').hide()

      $('#executeURL').val('')
      $('#executeParam').val('')

      //alert("next:"+next);

      // parse the prompt
      s = next.indexOf('-f')
      s = next.indexOf('(', s)
      e = next.indexOf(')', s)
      filter = next.substring(s + 1, e).trim()

      items = filter.split('|')

      if (items.length > 1) {
        stepDescription = items[0]
        filter = items[1]
      }

      //alert("filter:"+filter);

      executeWithFile = true

      // alert(savedFileList);

      list = 'fileSelectionList'
      $('option', '#' + list).remove()
      /*
             $('#' + list)
                    .append($("<option></option>")
                            .attr("value", "clear")
                            .text("Choose a file ..."));
             */

      obj = JSON.parse(savedFileList)
      for (i = 0; i < obj.items.length; i++) {
        //alert("file:"+obj.items[i].url);

        if (obj.items[i].url.indexOf(filter) > 0) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.items[i].url)
              .text(obj.items[i].name)
          )
        }
      }
    } else if (next.indexOf('-m') > -1) {
      stepDescription = 'Select one or more files'
      title = 'File(s) Select'

      $('#fileSelectionDiv').hide()
      $('#scriptSelectionDiv').hide()
      $('#multifileSelectionDiv').show()
      $('#executeParam').hide()
      $('#itemSelectionDiv').hide()
      $('#itemMultipleSelectionDiv').hide()
      $('#wizardConfimrationDiv').hide()

      $('#executeURL').val('')
      $('#executeParam').val('')

      // parse the prompt
      s = next.indexOf('-m')
      s = next.indexOf('(', s)
      e = next.indexOf(')', s)
      filter = next.substring(s + 1, e).trim()

      //alert(filter);

      items = filter.split('|')

      if (items.length > 1) {
        stepDescription = items[0]
        filter = items[1]
      }

      list = 'multifileSelectionList'
      $('option', '#' + list).remove()
      /*
             $('#' + list)
                    .append($("<option></option>")
                            .attr("value", "clear")
                            .text("Choose one or more files ..."));
             */

      obj = JSON.parse(savedFileList)

      //alert(JSON.stringify(obj));

      for (i = 0; i < obj.items.length; i++) {
        if (obj.items[i].url.indexOf(filter) > 0) {
          $('#' + list).append(
            $('<option></option>')
              .attr('value', obj.items[i].url)
              .text(obj.items[i].name)
          )
        }
      }
    } else if (next.indexOf('-s') > -1) {
      stepDescription = 'Enter a text parameter'
      title = 'Parameter Entry'

      $('#fileSelectionDiv').hide()
      $('#scriptSelectionDiv').hide()
      $('#multifileSelectionDiv').hide()
      $('#executeParam').show()
      $('#itemSelectionDiv').hide()
      $('#itemMultipleSelectionDiv').hide()
      $('#wizardConfimrationDiv').hide()

      $('#executeURL').val('')
      $('#executeParam').val('')

      // parse the prompt
      s = next.indexOf('-s')
      s = next.indexOf('(', s)
      e = next.indexOf(')', s)
      prompt = next.substring(s + 1, e).trim()

      items = prompt.split('|')

      if (items.length > 1) {
        stepDescription = items[0]
        prompt = items[1]
      }

      executeWithParam = true

      $('#executeParam').attr('placeholder', prompt)
      $('#executeParam').show()
    } else if (next.indexOf('-l') > -1) {
      stepDescription = 'Select one item from the list'
      title = 'List Select'

      $('#fileSelectionDiv').hide()
      $('#scriptSelectionDiv').hide()
      $('#multifileSelectionDiv').hide()
      $('#executeParam').hide()
      $('#wizardConfimrationDiv').hide()

      s = next.indexOf('-l')
      s = next.indexOf('(', s)
      e = next.indexOf(')', s)
      str = next.substring(s + 1, e).trim()

      // split into a list
      items = str.split('|')

      if (items.length > 1) {
        if (items[0].toLowerCase() == 'multiple') {
          $('#itemMultipleSelectionDiv').show()
          listSelectMultiple = true
          list = 'itemMultipleSelectionList'
          stepDescription = 'Select one or more items from the list'
        } else {
          $('#itemSelectionDiv').show()
          listSelectMultiple = false
          list = 'itemSelectionList'
        }

        $('option', '#' + list).remove()

        stepDescription = items[1]

        // check for script report reference or just a plain list
        if (items[2].indexOf('@') > -1) {
          script = items[2].substring(items[2].indexOf('@') + 1, items[2].length)
          items = weaved.getScriptReport(script, true)
        } else {
          for (i = 2; i < items.length; i++) {
            $('#' + list).append(
              $('<option></option>')
                .attr('value', items[i])
                .text(items[i])
            )
          }
        }
      } else {
        $('#' + list).append(
          $('<option></option>')
            .attr('value', items[0])
            .text(items[0])
        )
      }
    }

    $('#wizardSteps').html(
      'Step ' + executeStep + ' of ' + (executeHeaderSteps.length - 1) + ': ' + title + ' - ' + stepDescription
    )

    if (executeStep >= executeHeaderSteps.length - 1) {
      $('#wizardNextBtn')
        .text('Finish')
        .data('state', 'finish')
    }
  } else {
    finishExecution()
  }
}

function validateName(str) {
  var regex = /^[a-zA-Z0-9-\.\:_ ]+$/

  //var regex = /^[a-z\d\-_\s]{2,30}$/;
  //var ctrl =  document.getElemetnById(id);

  if (regex.test(str)) {
    return true
  } else {
    return false
  }
}

function bulkSelectionDone() {
  scriptSelected = $('#scriptSelectionList').val()
  fileSelected = $('#fileSelectionList').val()
  scriptSelectedName = $('#scriptSelectionList').val()
  fileSelectedName = $('#fileSelectionList').val()
  scriptSelectedPath = $('#scriptSelectionList').val()
  fileSelectedPath = $('#fileSelectionList').val()
  optionalParam = $('#scriptParameter').val()
  executeParam = $('#executeParam').val()

  try {
    scriptSelectedName = scriptSelected.substring(scriptSelected.lastIndexOf('/') + 1)
    scriptSelectedPath = scriptSelected.substring(0, scriptSelected.lastIndexOf('/'))
  } catch (err) {}

  try {
    fileSelectedName = fileSelected.substring(fileSelected.lastIndexOf('/') + 1)
    fileSelectedPath = fileSelected.substring(0, fileSelected.lastIndexOf('/'))
  } catch (err) {}

  if (!fileSelected) {
    fileSelected = ''
    fileSelectedName = ''
  }

  startSpinner()
  $('#Script-Execution-Modal').modal('hide')
  //$('.modal-backdrop').hide();

  selectedRows = getSelectedRowsIDs()
  //DEVICE_LIST.jqGrid('showCol', 'cb');
  //DEVICE_LIST.jqGrid('setGridParam', {multiselect: false});

  // check if the user is waiting
  if ($('#waitForScript').prop('checked')) {
    $('#Bulk-Wait-Modal').modal('show')
  }

  // use wget for script delivery
  //xferCommand = Base64.decode('d2dldA==')

  // use curl for script delivery
  xferCommand = Base64.decode('Y3VybA==')
  console.error('SUBMIT EXECUTION')
  console.error('script url')
  console.error(scriptURL)
  window.remot3it.r3.utilities.tinyUrl(scriptURL).then(function(tinyURL) {
    console.error('tiny url')
    console.error(tinyURL)
    if (tinyURL != false) {
      scriptSelected = tinyURL
      scriptSelected = tinyURL.substring(tinyURL.lastIndexOf('/'))
    }

    /*
    // load the script first then wait (wget version)
    command =
      'cd /tmp;' +
      xferCommand +
      ' http://$3/tiny' +
      scriptSelected +
      ' <noc> -O ' +
      scriptName +
      ';chmod +x /tmp/' +
      scriptName +
      ';'
  */

    // load the script first then wait (curl version)
    command =
      'cd /tmp;' +
      xferCommand +
      ' -Lks https://$3/t' +
      scriptSelected +
      ' -o ' +
      scriptName +
      ';chmod +x /tmp/' +
      scriptName +
      ';'

    // execute the script
    commandURL = ''
    if (parameters.length > 0) {
      //alert("p:"+parameters);

      parameters = Base64.encode(parameters)

      window.remot3it.r3.utilities.shortCode(parameters).then(function(tinyURL) {
        if (tinyURL != false) {
          commandURL = tinyURL
        }

        command = command + '/tmp/' + scriptName + ' $2 $3 ' + commandURL + '&'

        //alert("cmd:"+command);
        //alert(scriptName+":"+scriptSelected);

        // check for allowing status updates
        allowUpdates = 'allow'

        sendBulkCommand(command, scriptName, 'See Command', expiration, post_func, options, allowUpdates, servicesList)
      })
    } else {
      command = command + '/tmp/' + scriptName + ' $2 $3&'

      //alert("cmd:"+command);
      //alert(scriptName+":"+scriptSelected);

      // check for allowing status updates
      allowUpdates = 'allow'

      sendBulkCommand(command, scriptName, 'See Command', expiration, post_func, options, allowUpdates, servicesList)
    }
  })
}

function submitExecution(scriptURL, scriptName, parameters, expiration, post_func, options, servicesList) {
  //alert("send URL:" + scriptURL + " NAME:" + scriptName + " CMD:" + parameters);

  // use curl for script delivery
  xferCommand = Base64.decode('Y3VybA==')

  window.remot3it.r3.utilities.tinyUrl(scriptURL).then(function(tinyURL) {
    // console.error('tiny url')
    // console.error(tinyURL)
    if (tinyURL != false) {
      scriptSelected = tinyURL
      scriptSelected = tinyURL.substring(tinyURL.lastIndexOf('/'))
    }

    // load the script first then wait (curl version)
    command =
      'cd /tmp;' +
      xferCommand +
      ' -Lks https://$3/t' +
      scriptSelected +
      ' -o ' +
      scriptName +
      ';chmod +x /tmp/' +
      scriptName +
      ';'

    // execute the script
    commandURL = ''
    if (parameters.length > 0) {
      //alert("p:"+parameters);

      parameters = Base64.encode(parameters)

      window.remot3it.r3.utilities.shortCode(parameters).then(function(tinyURL) {
        if (tinyURL != false) {
          commandURL = tinyURL
        }

        command = command + '/tmp/' + scriptName + ' $2 $3 ' + commandURL + '&'

        //alert("cmd:"+command);
        //alert(scriptName+":"+scriptSelected);

        // check for allowing status updates
        allowUpdates = 'allow'

        sendBulkCommand(command, scriptName, 'See Command', expiration, post_func, options, allowUpdates, servicesList)
      })
    } else {
      command = command + '/tmp/' + scriptName + ' $2 $3&'

      //alert("cmd:"+command);
      //alert(scriptName+":"+scriptSelected);

      // check for allowing status updates
      allowUpdates = 'allow'

      sendBulkCommand(command, scriptName, 'See Command', expiration, post_func, options, allowUpdates, servicesList)
    }
  })
}

function cancelExecution() {
  // $('#Script-Execution-Modal').modal('hide')
  $('#Script-Execution-Modal').hide()
}

function cancelCategoryModal() {
  $('#Set-Category-Modal').hide()
}

function cancelStatusModal() {
  $('#Clear-Status-Modal').hide()
}

function cancelBulkTransferModal() {
  $('#Bulk-Transfer-Device-Modal').hide()
}

function finishExecution() {
  $('#wizardSteps').html('Final Step:  Confirm Bulk Job submission')

  $('#fileSelectionDiv').hide()
  $('#scriptSelectionDiv').hide()
  $('#multifileSelectionDiv').hide()
  $('#executeParam').hide()
  $('#itemSelectionDiv').hide()
  $('#itemMultipleSelectionDiv').hide()

  $('#wizardNextBtn')
    .text('Submit')
    .data('state', 'submit')

  $('#wizardConfimrationDiv').show()
}

function showScriptExecutionStep() {
  /*
    $("#scriptSelectionDiv").show();
    $("#fileSelectionDiv").hide();
    $("#multifileSelectionDiv").hide();
    $("#itemSelectionDiv").hide();
    $("#executeParam").hide();
    $("#Script-Execution-Modal").show();
     */

  // get the next step from the header or start the command
  next = executeHeaderSteps[0]
  if (next.indexOf('_file') > 0) {
    // parse the prompt
    s = hdr.indexOf('_file')
    s = hdr.indexOf('(', s)
    e = hdr.indexOf(')', s)
    filter = hdr.substring(s + 1, e).trim()

    executeWithFile = true

    list = 'fileSelectionList'
    $('option', '#' + list).remove()
    $('#' + list).append(
      $('<option></option>')
        .attr('value', 'clear')
        .text('Choose a file ...')
    )

    obj = JSON.parse(savedFileList)
    for (i = 0; i < obj.items.length; i++) {
      if (obj.items[i].url.indexOf(filter) > 0) {
        $('#' + list).append(
          $('<option></option>')
            .attr('value', obj.items[i].url)
            .text(obj.items[i].name)
        )
      }
    }

    //$("#fileSelectionList").find('option[value=clear]').text(prompt);
    $('#fileSelectionDiv').show()

    // control the visible tab
  }

  if (next.indexOf('_param') > 0) {
    // parse the prompt
    s = hdr.indexOf('_param')
    s = hdr.indexOf('(', s)
    e = hdr.indexOf(')', s)
    prompt = hdr.substring(s + 1, e).trim()

    executeWithParam = true

    $('#executeParam').attr('placeholder', prompt)
    $('#executeParam').show()
  }
}

function selectFileAndCommand(vl, deviceId) {
  contentMode = 'select'
  bulkSelection = vl

  window.remot3it.store.dispatch.devices.setCurrentDeviceId(deviceId)

  if (typeof deviceId !== 'undefined') {
    DEVICE_LIST.find('tbody tr').each(function() {
      if ($(this).attr('id') === deviceId) {
        $(this).addClass('success')
      }
    })
  }

  //alert("count:"+countSelectedDevices());

  // set the allow update selection based on max allowed
  if (countSelectedDevices() < MAX_ALLOW_UPDATES - 1) {
    $('#allow-update-selection-less').show()
    $('#allow-clear-update-selection').show()
    $('#allow-update-selection-more').hide()
  } else {
    $('#allow-update-selection-less').hide()
    $('#allow-clear-update-selection').hide()
    $('#allow-update-selection-more').show()
  }

  if (vl == 'transferfile') {
    if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) {
      weaved.getContent('file')

      $('#scriptSelectionDiv').hide()
      $('#fileSelectionDiv').show()
      $('#scriptParameter').attr('placeholder', 'Enter optional destination path')

      $('#scriptSelectionDiv').show()
      $('#fileSelectionDiv').show()
      $('#executeParam').show()

      $('#wizardNextBtn').prop('disabled', false)
      $('#Script-Execution-Modal').show()
    } else {
      alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    }
  } else if (vl == 'transferscript') {
    if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) {
      weaved.getContent('script')

      $('#scriptSelectionDiv').show()
      $('#fileSelectionDiv').hide()
      $('#scriptParameter').attr('placeholder', 'Enter optional destination path')

      $('#wizardNextBtn').prop('disabled', false)
      $('#Script-Execution-Modal').show()
    } else {
      alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    }
  } else if (vl == 'executescript') {
    if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) {
      weaved.getContent('script')
      weaved.getContent('file')
      initExecutionModal()
    } else {
      alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    }
  } else if (vl == 'sendcommand') {
    $('#Send-Command-Modal').show()
  } else if (vl == 'setcategory') {
    if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) {
      $('#useCategoryA').prop('checked', false)
      $('#useCategoryB').prop('checked', false)
      $('#useCategoryC').prop('checked', false)
      $('#set-category-progress-div').hide()
      $('#Set-Category-Modal').show()
    } else {
      alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    }
  } else if (vl == 'clearstatus') {
    if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) {
      /*
            $('#useA').prop('checked', false);
            $('#useB').prop('checked', false);
            $('#useC').prop('checked', false);
            $('#useD').prop('checked', false);
            $('#useE').prop('checked', false);
             */
      $('#clear-status-progress-div').hide()
      $('#Clear-Status-Modal').show()
    } else {
      alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    }
  } else if (vl == 'deletedevice') {
    if ((countSelectedDevices() > 0 && countSelectedDevices() < MAX_DELETE + 1) || typeof deviceId !== 'undefined') {
      // check for selected devices being online
      devid = ''
      hwid = ''
      onlineError = false
      rows = typeof deviceId === 'undefined' ? getSelectedRows() : [DEVICE_LIST.getRowData(deviceId)]
      for (var row in rows) {
        tmp = JSON.stringify(rows[row])
        var statusDev = rows[row]['Status']
        if (statusDev.indexOf('Offline') === -1) {
          onlineError = true
        }

        // Save the HWID's of the deleting devices to find others
        hwid = hwid + rows[row]['HW_macid']
        devid = hwid + rows[row]['Mac']
      }

      if (onlineError) {
        $('#device-delete-status').html('')
        $('#delete-device-progress').hide()
        $('#confirmDeviceDelete').removeAttr('checked')
        $('#Delete-Online-Device-Modal').modal('show')
        //alert("You can't delete devices that are currently online and active, like " + rows[row].ServiceName );
        return
      }

      // check for device having duplicate hwid
      rowIDs = getRowIDs()
      for (var rowid in rowIDs) {
        var row = DEVICE_LIST.getRowData(rowIDs[rowid])
        tmp = JSON.stringify(row)

        if (
          row['Type'] == 'Bulk Service' &&
          hwid.toLowerCase().indexOf(row['HW_macid'].toLowerCase()) > -1 &&
          devid.toLowerCase().indexOf(row['Mac'].toLowerCase()) == -1
        ) {
          //alert("You can't delete devices that may have been cloned, like " + row.ServiceName + ". Please contact us for more details." );
          //return;
        }
      }

      $('#device-delete-status').html('')
      $('#delete-device-progress').hide()
      $('#confirmDeviceDelete').removeAttr('checked')
      $('#Delete-Device-Modal').modal('show')
    } else {
      alert('Select 1 to ' + MAX_DELETE + ' devices to continue.')
    }
  } else if (vl == 'transferdevice') {
    if (countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) {
      // check for shared devices and reject
      var nextDisabled = buildDeviceListsForBulk('transfer-device')
      $('#confirmDeviceTransfer').prop('checked', false)
      $('#bulk-transfer-device-status').html('')
      $('#bulk-transfer-device-button').prop('disabled', nextDisabled)
      $('#Bulk-Transfer-Device-Modal').show()
    } else {
      alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    }
  } else if (vl == 'bulkshare') {
    if ((countSelectedDevices() > 0 && countSelectedDevices() < MAX_SELECTED + 1) || typeof deviceId !== 'undefined') {
      bulkShareAction = 'on'
      var nextDisabled = buildDeviceListsForBulk('add-share', deviceId)
      $('#share_on_invitation').show()
      $('.bulk-share-fail').html('')
      $('#bulk-share-device-button').prop('disabled', nextDisabled)
      $('#Bulk-Share-Device-Modal').modal('show')
    } else {
      alert('Select 1 to ' + MAX_SELECTED + ' devices to continue.')
    }
  }
}

function buildDeviceListsForBulk(action, deviceId) {
  //Determine if you have permission on all devices
  var selectedDeviceIds = typeof deviceId === 'undefined' ? getSelectedRowsIDs() : [deviceId]
  var acceptedDeviceList = []
  var rejectedDeviceList = []
  rState.selectedServiceList = []
  var nextState = true
  window.remot3it.store.dispatch.contacts.select([])
  window.remot3it.store.dispatch.contacts.clearError()
  selectedDeviceIds.forEach(function(deviceId) {
    var device = rState.getDevice(deviceId)
    if (device.Shared !== 'shared-from') {
      rState.selectedServiceList.push(deviceId)
      acceptedDeviceList.push(device.ServiceName)
    } else {
      rejectedDeviceList.push(device.ServiceName)
    }
  })
  if (acceptedDeviceList.length > 0) {
    nextState = false
    $('.bulk-sharing-notice').css('display', 'block')
  } else {
    nextState = true
    $('.bulk-sharing-notice').css('display', 'none')
  }
  window.remot3it.store.dispatch.devices.setAcceptableDevices(acceptedDeviceList)
  window.remot3it.store.dispatch.devices.setRejectedDevices(rejectedDeviceList)
  if (action === 'remove-share') {
    window.remot3it.store.dispatch.contacts.setContactsForDevices(selectedDeviceIds)
  } else {
    window.remot3it.store.dispatch.contacts.setContactOptions(window.remot3it.store.getState().contacts.all)
  }
  return nextState
}
function timeStamp() {
  // Create a date object with the current time
  var now = new Date()

  // Create an array with the current month, day and time
  var date = [now.getMonth() + 1, now.getDate(), now.getFullYear()]

  // Create an array with the current hour, minute and second
  var time = [now.getHours(), now.getMinutes(), now.getSeconds()]

  // Determine AM or PM suffix based on the hour
  var suffix = time[0] < 12 ? 'AM' : 'PM'

  // Convert hour from military time
  time[0] = time[0] < 12 ? time[0] : time[0] - 12

  // If hour is 0, set it to 12
  time[0] = time[0] || 12

  // If seconds and minutes are less than 10, add a zero
  for (var i = 1; i < 3; i++) {
    if (time[i] < 10) {
      time[i] = '0' + time[i]
    }
  }

  // Return the formatted string
  return date.join('/') + ' ' + time.join(':') + ' ' + suffix
}

var _submit = document.getElementById('_submit'),
  _file = document.getElementById('uploads')

function upload() {
  if (_file.files.length === 0) {
    $('#upload-status').html('No file selected ')
    return
  }

  $content = weaved.SCRIPT_CONTENT
  if ($('#fileUploadRadio').prop('checked')) {
    if (_file.files[0].size > MAX_UPLOAD) {
      $('#upload-status').html('File size limit(' + MAX_UPLOAD / 1024000 + ' MB) exceeded')
      return
    }

    if (_file.files[0].name.length > 40) {
      $('#upload-status').html('File names are limited to 40 characters ')
      return
    }

    $content = weaved.FILE_CONTENT
  } else {
    if (_file.files[0].name.length > MAX_SCRIPTNAME_LENGTH) {
      $('#upload-status').html('Script names are limited to ' + MAX_SCRIPTNAME_LENGTH + ' characters ')
      return
    }
  }

  //startSpinner();
  $('#upload-progress-status').show()

  $('#upload-status').html('Do not close this dialog while upload is in progress.')

  $('#_submit').prop('disabled', true)

  $(window).bind('beforeunload', function() {
    return 'Your Remot3.it file upload will be abort if your close this window.'
  })

  var data = new FormData()
  data.append('uploads', _file.files[0])
  weaved.uploadContent($content, data, upload_server_url)
}

_submit.addEventListener('click', upload)

// Bulk upload section

var _bulksubmit = document.getElementById('_bulksubmit')
var _bulkfile = document.getElementById('bulkuploads')
var _bulkfile_secondary = document.getElementById('bulkuploads_secondary')

var bulkupload = function() {
  $('#newBulkRegistration').val($('#newBulkRegistration option:first').val())

  if (newBulkRegistrationMode == 'open') {
    doCreateOpenRegistration()
  } else if (newBulkRegistrationMode == 'bulk' || newBulkRegistrationMode == 'pooled') {
    if (_bulkfile.files.length === 0) {
      return
    }

    $(window).bind('beforeunload', function() {
      return 'Your Remot3.it registration upload will be abort if your close this window.'
    })

    var name = checkEncodedChar($('#openRegistrationName').val())

    $('#upload-error-div').hide()

    // determine the product
    public = $('#publicProductSelectionList option:selected').html()
    mine = $('#myProductSelectionList option:selected').html()
    code = $('#privateProductCode').val()

    product =
      public.indexOf('public product') == -1 && public.indexOf('products available') == -1
        ? $('#publicProductSelectionList').val()
        : ''
    product =
      mine.indexOf('private product') == -1 && mine.indexOf('products available') == -1
        ? $('#myProductSelectionList').val()
        : product
    product = code.length > 0 ? code : product

    pub_selected = public.indexOf('public product') == -1 && public.indexOf('products available') == -1 ? 1 : 0
    min_selected = mine.indexOf('private product') == -1 && mine.indexOf('products available') == -1 ? 1 : 0
    code_entered = code.length > 0 ? 1 : 0

    total = pub_selected + min_selected + code_entered

    //alert("pub:"+public + " mine:"+mine+ " total picked:"+total);

    if (name.length == 0) {
      $('#upload-error-div').show()
      $('#create-open-registration-status').html('Please enter a name for your new Bulk Registration')
    } else if (!isValidName(name)) {
      $('#upload-error-div').show()
      $('#create-open-registration-status').html(
        'Please enter a valid registration name. Registration names must be unique and only contain alphanumeric, -, _ and space characters'
      )
    } else if (total != 1) {
      $('#upload-error-div').show()
      $('#create-open-registration-status').html('You must select a single Product to continue with Bulk Registration')
    } else if (_bulkfile.files[0].size > MAX_CSV_UPLOAD_FILE_SIZE) {
      $('#upload-error-div').show()
      $('#create-open-registration-status').html('CSV file size is limited to ' + MAX_CSV_UPLOAD_FILE_SIZE + ' bytes')
    } else {
      //alert("product:"+product);
      //return;

      //startSpinner();
      $('#bulkUploadStatusDiv').show()
      $('#bulkupload-progress-status').show()

      $('#bulk-upload-status').html('Do not close this dialog while upload is in progress.')

      var data = new FormData()
      data.append('bulkuploads', _bulkfile.files[0])

      //alert("file:"+_bulkfile.files[0].size);

      if (newBulkRegistrationMode == 'pooled') {
        data.append('bulkuploads_secondary', _bulkfile_secondary.files[0])
      }

      data.append('name', name)
      data.append('productid', product)

      $('#_bulksubmit').attr('disabled', 'disabled')

      weaved.uploadBulkRegistration(data)
    }
  }
}

_bulksubmit.addEventListener('click', bulkupload)

$(window).on('click', function() {
  $('.javavoid').trigger('click')
})

$(document).ready(function() {
  subdomain = domainHandler.getSubdomain()
  subdomainData = domainHandler.getSubdomainData()

  // set the co-branding if enabled
  if (fqhn_parts.length > 2 && fqhn_parts[0] != 'www') {
    $('#cust_corp_logo1').attr(
      'src',
      'https://s3.eu-central-1.amazonaws.com/remot3.it-logos/' + fqhn_parts[0].trim() + '.png'
    )
    $('#cust_corp_logo2').attr(
      'src',
      'https://s3.eu-central-1.amazonaws.com/remot3.it-logos/' + fqhn_parts[0].trim() + '.png'
    )
  }

  // set upload limits
  $('#max_CSV_devices').html(MAX_UPLOAD_DEVICES)
  $('#max_CSV_filesize1').html((MAX_CSV_UPLOAD_FILE_SIZE / 1024).toFixed(0))

  $('#check-selection').click(function() {
    findSelectedRows()
  })

  $('#categoryA').keypress(function() {
    $('#useCategoryA').prop('checked', true)
  })
  $('#categoryB').keypress(function() {
    $('#useCategoryB').prop('checked', true)
  })
  $('#categoryC').keypress(function() {
    $('#useCategoryC').prop('checked', true)
  })

  $('#toggle-btn').on('click', function() {
    $('#jsonblob').JSONView('toggle')
  })

  $('#collapse-btn').on('click', function() {
    $('#jsonblob').JSONView('collapse')
  })

  $('#expand-btn').on('click', function() {
    $('#jsonblob').JSONView('expand')
  })

  $('#fileSelectionList').change(function() {
    // put the selected value in the URL box
    $('#executeURL').val(
      $('#fileSelectionList')
        .find(':selected')
        .val()
    )
  })

  mydata = []

  // Set the icon for Status online or offline -->
  function StatusFormatter(status, options, row) {
    // NOTE: This div has the QuickConnectButton React component rendered
    // into it.
    return (
      '<div class="quick-launch-button-placeholder px-sm" data-device-id="' +
      row.uid +
      '" data-device-status="' +
      status +
      '"></div>'
    )
  }

  // Display the icon for settings column -->
  function SettingFormatter(cellvalue, options, rowObject) {
    return "<a href='#'><i class='fal fa-sliders-v' style='color:blue' data-toggle='modal' data-target='#TableSettings' align='center'></i> </a>"
  }

  // Display the icon for settings column
  function ShareFormatter(cellvalue, options, device) {
    var sharedContent = device.Shared === 'not-shared' ? 'No' : 'Yes'

    if (device.Owner.trim().toLowerCase() !== getCookie('remoteit.email').toLowerCase()) {
      sharedContent = '-'
    }

    return '<div class="share-icon-container">' + sharedContent + '</div>'
  }

  // Display the icon for settings column
  function OwnerFormatter(cellvalue, options, device) {
    return device.Shared !== 'shared-from' ? '<span>me</span>' : '<span>' + device.Owner + '</span>'
  }

  // Service & Connected IP Address for service column -->
  function ServiceFormatter(cellvalue, options, rowObject) {
    var shared = ''
    var status = rowObject.Status

    var actions = ''
    var deleteIcon = window.remot3it.helpers.renderDeleteIcon(
      rowObject.uid,
      'deletedevice',
      rowObject.Owner.trim().toLowerCase()
    )
    var awsIcon = '<span></span>'
    var platformCode = window.remot3it.helpers.getPlatformCode(rowObject.ServiceType)
    if (platformCode && platformCode === awsCode) {
      awsIcon = '<sup alt="AWS" class="fab fa-aws fa-xs ml-xs"></sup>'
    }

    // Show the share icon if shared to the current user.
    if (getCookie('remoteit.email').toLowerCase() !== rowObject.Owner.trim().toLowerCase()) {
      shared =
        '<sup alt="Shared" class="fa fa-user-friends fa-xs ml-xs" title="Shared by ' + rowObject.Owner + '"></sup>'

      actions =
        '<div class="pull-right w-10"></div>' + '<div class="pull-right w-10 icon-action">' + deleteIcon + '</div>'
    } else {
      var shareIcon = window.remot3it.helpers.renderShareIcon(
        rowObject.uid,
        rowObject.ServiceName,
        rowObject.Shared,
        rowObject.Owner.trim().toLowerCase()
      )

      actions =
        '<div class="pull-right w-10 icon-action">' +
        deleteIcon +
        '</div>' +
        '<div class="pull-right w-10 icon-action">' +
        shareIcon +
        '</div>'
    }

    if (status === 'active') {
      return (
        "<a href='#' class='fw-normal' onclick='showServicesModal(\"" +
        rowObject.uid +
        '");\'>' +
        "<span class='nav-label notranslate' data-toggle='modal'> " +
        rowObject.ServiceName +
        '</span>' +
        awsIcon +
        shared +
        '</a>' +
        actions
      )
    }

    InactiveStatus = ' Last online at'
    if (rowObject.CreateDate == rowObject.LastOnlineDate && rowObject.IPext == '') {
      InactiveStatus = 'Pre-registered at'
    }

    new_date = new Date(Date.parse(rowObject.LastOnlineDate + 'T' + rowObject.LastOnlineTime))

    // Render device title and activity status.
    return (
      "<a href='#' class='fw-normal' onclick='showServicesModal(\"" +
      rowObject.uid +
      '");\'>' +
      "<span class='nav-label notranslate' data-toggle='modal'> " +
      rowObject.ServiceName +
      '</span>' +
      awsIcon +
      shared +
      '</a>' +
      actions +
      ' <br><small> ' +
      InactiveStatus +
      ' ' +
      '<span title="' +
      new_date.toUTCString() +
      '">' +
      new_date.toLocaleString() +
      '</span>' +
      '</small>'
    )
  }

  // Display the icon for status column -->
  function StatusColumnFormatter(cellvalue, options, rowObject) {
    if (!cellvalue) return ''

    label = cellvalue
    link = ''
    if (cellvalue && cellvalue.indexOf('bx:') > -1) {
      items = cellvalue.split(':')
      if (items.length == 3) {
        label = items[1]
        link =
          '<button class="link" onclick="showStatusBlobModal(\'' +
          items[2] +
          '\',\'\');"><i class="fal fa-paperclip primary mr-sm"></i></button>'
      } else if (items.length == 4) {
        label = items[1]
        link =
          '<button class="link" onclick="showStatusBlobModal(\'' +
          items[2] +
          "','" +
          items[3] +
          '\');"><i class="fal fa-paperclip primary mr-sm"></i></button>'
      }
    }
    return link + label
  }

  var rowid = 0

  // BULK TABLE  ------------------------------------------------------

  // Select records by group
  $('table tbody').on('change', 'input[type=checkbox]', function(e) {
    var currentCB = $(this)
    var grid = DEVICE_LIST
    var isChecked = this.checked
    if (currentCB.is('.groupHeader')) {
      //if group header is checked, to check all child checkboxes
      var checkboxes = currentCB
        .closest('tr')
        .nextUntil('tr.gridghead_0')
        .find('.cbox[type="checkbox"]')
      checkboxes.each(function() {
        if (!this.checked || !isChecked)
          grid.setSelection(
            $(this)
              .closest('tr')
              .attr('id'),
            true
          )
      })
    } else {
      //when child checkbox is checked
      var allCbs = currentCB
        .closest('tr')
        .prevAll('tr.gridghead_0:first')
        .nextUntil('tr.gridghead_0')
        .andSelf()
        .find('[type="checkbox"]')
      var allSlaves = allCbs.filter('.cbox')
      var master = allCbs.filter('.groupHeader')

      var allChecked = !isChecked ? false : allSlaves.filter(':checked').length === allSlaves.length
      master.prop('checked', allChecked)
    }
  })

  // Configuration for jqGrid
  var lastsel

  // DEO Init Grid was here

  var spinner = document.getElementById('loading_spinner')
  if (spinner) spinner.style.display = 'block'

  DEVICE_LIST.jqGrid({
    data: mydata,
    datatype: 'local',
    height: 'auto',
    emptyrecords: '', // Don't show a record missing message
    shrinkToFit: false,
    rowNum: 100,
    rowList: [50, 100, 200, 400],
    pager: '#tblGridPager',
    loadonce: false,
    multiselect: true,
    beforeSelectRow: function(rowid, e) {
      var $this = $(this),
        rows = this.rows,
        // get id of the previous selected row
        startId = $this.jqGrid('getGridParam', 'selrow'),
        startRow,
        endRow,
        iStart,
        iEnd,
        i,
        rowidIndex

      if (!$(e.target).is('input[type=checkbox]')) return false

      if (e.altKey) {
        $this.jqGrid('resetSelection')
      }

      if (!e.altKey && !e.shiftKey) {
        //$this.jqGrid('resetSelection');
      } else if (startId && e.shiftKey) {
        $this.jqGrid('resetSelection')

        // get DOM elements of the previous selected and the currect selected rows
        startRow = rows.namedItem(startId)
        endRow = rows.namedItem(rowid)

        if (startRow && endRow) {
          // get min and max from the indexes of the previous selected
          // and the currect selected rows
          iStart = Math.min(startRow.rowIndex, endRow.rowIndex)
          rowidIndex = endRow.rowIndex
          iEnd = Math.max(startRow.rowIndex, rowidIndex)
          for (i = iStart; i <= iEnd; i++) {
            // the row with rowid will be selected by jqGrid, so:
            if (i != rowidIndex) {
              $this.jqGrid('setSelection', rows[i].id, false)
            }
          }
        }

        // clear text selection
        if (document.selection && document.selection.empty) {
          document.selection.empty()
        } else if (window.getSelection) {
          window.getSelection().removeAllRanges()
        }
      }

      return true
    },
    colNames: [
      'Status',
      'Name ',
      'Device Name',
      'Owner',
      'Shared',
      'Name',
      'HWID',
      'Ver',
      'ConnectIP',
      'Portid',
      'ServiceType',
      'Mac',
      'Type',
      'Internal IP',
      'External IP',
      'StatusA',
      'StatusB',
      'StatusC',
      'StatusD',
      'StatusE',
      'CategoryA',
      'CategoryB',
      'CategoryC',
      'Settings',
      'LastContact',
      'CreateDate',
      'CreateTime',
    ],
    colModel: [
      {
        name: 'Status',
        index: 'Status',
        height: 5,
        width: 160,
        search: true,
        align: 'left',
        sorttype: 'text',
        editable: false,
        frozen: true,
        formatter: StatusFormatter,
      },
      {
        name: 'HW_Name',
        index: 'HW_Name',
        height: 5,
        width: 0,
        search: true,
        align: 'left',
        sorttype: 'text',
        hidden: true,
      },
      {
        label: 'Device Name',
        name: 'service',
        index: 'service',
        width: 300,
        align: 'left',
        sorttype: 'text',
        formatter: ServiceFormatter,
        colmenu: true,
        frozen: true,
        searchoptions: {
          searchOperMenu: true,
          sopt: ['eq', 'gt', 'lt', 'ge', 'le'],
        },
      },
      {
        name: 'Owner',
        index: 'Owner',
        height: 5,
        width: 180,
        search: true,
        align: 'left',
        sorttype: 'text',
        formatter: OwnerFormatter,
      },
      {
        name: 'Shared',
        index: 'Shared',
        height: 5,
        width: 90,
        search: true,
        align: 'left',
        sorttype: 'text',
        editable: false,
        frozen: true,
        formatter: ShareFormatter,
      },
      {
        name: 'ServiceName',
        sorttype: 'text',
        hidden: true,
      },
      {
        name: 'HW_macid',
        index: 'HW_macid',
        height: 5,
        width: 300,
        search: true,
        align: 'left',
        sorttype: 'text',
        formatter: function HWIDFormatter(cellvalue, options, rowObject) {
          var hwid = cellvalue || ''
          return '<span class="notranslate">' + hwid + '</span>'
        },
      },
      {
        name: 'Version',
        index: 'Version',
        width: 55,
        align: 'left',
        sorttype: 'text',
        hidden: true,
      },
      {
        name: 'ConnectIP',
        hidden: true,
      },
      {
        name: 'Portid',
        hidden: true,
      },
      {
        name: 'ServiceType',
        hidden: true,
      },
      {
        name: 'Mac',
        key: true,
        hidden: true,
      },
      {
        name: 'Type',
        index: 'Type',
        editable: false,
        //width: 30,
        align: 'left',
        sorttype: 'text',
        formatter: 'text',
        hidden: true,
      },
      {
        name: 'IP',
        index: 'IP',
        editable: false,
        width: 110,
        align: 'left',
        sorttype: 'text',
        formatter: 'text',
      },
      {
        name: 'IPext',
        index: 'IPext',
        editable: false,
        width: 110,
        align: 'left',
        sorttype: 'text',
        formatter: 'text',
      },
      {
        name: 'StatusA',
        index: 'StatusA',
        width: 120,
        formatter: StatusColumnFormatter,
        align: 'left',
        sorttype: 'text',
      },
      {
        name: 'StatusB',
        index: 'StatusB',
        width: 120,
        formatter: StatusColumnFormatter,
        align: 'left',
        sorttype: 'text',
      },
      {
        name: 'StatusC',
        index: 'StatusC',
        width: 120,
        formatter: StatusColumnFormatter,
        align: 'left',
        sorttype: 'text',
      },
      {
        name: 'StatusD',
        index: 'StatusD',
        width: 120,
        formatter: StatusColumnFormatter,
        align: 'left',
        sorttype: 'text',
      },
      {
        name: 'StatusE',
        index: 'StatusE',
        width: 120,
        formatter: StatusColumnFormatter,
        align: 'left',
        sorttype: 'text',
      },
      {
        name: 'CategoryA',
        index: 'CategoryA',
        width: 150,
        align: 'left',
        sorttype: 'text',
      },
      {
        name: 'CategoryB',
        index: 'CategoryB',
        width: 150,
        align: 'left',
        sorttype: 'text',
      },
      {
        name: 'CategoryC',
        index: 'CategoryC',
        width: 150,
        align: 'left',
        sorttype: 'text',
      },
      {
        name: 'settings',
        index: 'settings',
        editable: false,
        //width: 20,
        align: 'center',
        search: false,
        sortable: false,
        formatter: SettingFormatter,
        hidden: true,
      },
      {
        name: 'LastContact',
        hidden: true,
      },
      {
        name: 'CreateDate',
        hidden: true,
      },
      {
        name: 'CreateTime',
        hidden: true,
      },
    ],
    //editurl: "/Save_HW_name_here.php",  ////  <-------------------  DOUG: edit HW Name, run script here
    pager: '#pager_bulk',
    sortable: true,
    //         colMenu : true,
    //    viewrecords: true,
    //    caption: "Your Devices",
    iconsOverText: true,
    //         add: true,
    edit: true,
    //         addtext: 'Add',
    edittext: 'Edit',
    viewtext: 'View',
    sortAsc: 'sort',
    ignoreCase: true,
    hidegrid: false,
    sortname: 'HW_Name',
    onSelectRow: function(rowid, status) {
      var elem = document.activeElement
      shareDeviceAddress = rowid

      console.log('row select:' + rowid + ' status:' + status)

      if (elem.id) {
        // the checkbox has an id, so check for it
        if (!status) {
          // unselect the select-all if any row is deselected
          $('#cb_table_bulk').attr('checked', false)
        }
      } else {
        // ensure that the row is not checked.
        DEVICE_LIST.setSelection(rowid, false)
      }
    },
    gridComplete: function() {
      window.remot3it.helpers.renderQuickConnectButtons()
    },
  })

  DEVICE_LIST.jqGrid('navGrid', '#tblGridPager', {
    edit: false,
    add: false,
    del: false,
    paging: true,
    search: false,
  })
  $('#pager_bulk').hide()

  $(window).trigger('resize')

  DEVICE_LIST.jqGrid('navGrid', '#pager_bulk', {
    search: false, // show search button on the toolbar
    add: false,
    edit: false,
    del: false,
    refresh: false,
  })
  $('#save').click(function() {
    filter = DEVICE_LIST.jqGrid('getGridParam', 'postData').filters
    var perm = [1, 0, 2, 4, 3]
    DEVICE_LIST.jqGrid('remapColumns', perm, true, false)
    console.log(DEVICE_LIST.jqGrid('getGridParam', 'colModel'))
  })
  $('#load').click(function() {
    console.log(filter)
    DEVICE_LIST.jqGrid('refreshFilterToolbar', {
      filters: filter,
      onClearVal: function(elem, name) {
        if (name === 'CustomerID') {
          console.log(elem)
          elem.multiselect('refresh')
        }
      },
      onSetVal: function(elem, name) {
        if (name === 'CustomerID') {
          console.log(elem)
          elem.multiselect('refresh')
        }
      },
    })
  })
  var timer
  $('#search_cells').on('keyup', function() {
    var self = this
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(function() {
      //timer = null;
      DEVICE_LIST.jqGrid('filterInput', self.value)
    }, 0)
  })

  // Save the State
  $('#savestate').click(function() {
    //$.jgrid.saveState("table_bulk");

    var colModel = DEVICE_LIST.jqGrid('getGridParam', 'colModel')

    //alert("set grid:"+colModel);

    weaved.setUserPreference('colmodel', Base64.encode(JSON.stringify(colModel)))
  })

  $('#loadstate').click(function() {
    //$.jgrid.loadState("table_bulk");

    if (savedColModel.length > 0) {
      //alert(JSON.stringify({colModel: Base64.decode(savedColModel) }));

      //DEVICE_LIST.jqGrid('GridUnload');
      $.jgrid.gridUnload('#table_bulk')
      DEVICE_LIST.jqGrid('setGridParam', 'colModel', {
        colModel: Base64.decode(savedColModel),
      })
      DEVICE_LIST.trigger('reloadGrid')
      //DEVICE_LIST.jqGrid('setGridParam','colModel', Base64.decode(savedColModel) );
      //alert("set grid:"+Base64.decode(savedColModel));
    }

    //JSON.parse(Base64.decode(blob));
  })

  // Grid Filter
  var timer
  $('#HWName').on('keyup', function() {
    var self = this
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(function() {
      //timer = null;
      DEVICE_LIST.jqGrid('filterInput', self.value)
    }, 0)
  })

  $('#searchText').keyup(function(e) {
    var code = e.which // recommended to use e.which, it's normalized across browsers
    if (code == 13) e.preventDefault()
    if (code == 13 || code == 188 || code == 186) {
      window.fetchDevices()
    }
  })
})

function showRemoveSharingDialog(deviceId) {
  window.selectFileAndCommand('deletedevice', deviceId)
}

function showAWSModal() {
  $('#aws-modal').show()
  if (isMac()) {
    $('#download_btn_win').hide()
    $('#download_btn_mac').show()
  } else {
    $('#download_btn_win').show()
    $('#download_btn_mac').hide()
  }
}

function hideAWSModal() {
  $('#aws-modal').hide()
}

function isMac() {
  var platform = navigator.platform.toLowerCase()
  return platform.includes('mac')
}
function isWindows() {
  var platform = navigator.platform.toLowerCase()
  return platform.includes('win')
}

function download_win() {
  window.location.href = 'https://downloads.remote.it/desktop/latest/remoteit-installer.exe'
}

function download_mac() {
  window.location.href = 'https://downloads.remote.it/desktop/latest/remoteit-installer.dmg'
}
